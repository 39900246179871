import { useEffect, useState } from 'react';
import type { UseEnsNameParameters, UseEnsNameReturnType } from 'wagmi';
import { useEnsName } from 'wagmi';

import { defaults } from '@endaoment-frontend/config';
import { TIME_ONE_HOUR_IN_SECONDS } from '@endaoment-frontend/constants';

export const useEnsNameWithFallback = (args: UseEnsNameParameters): UseEnsNameReturnType => {
  const [usedChainId, setUsedChainId] = useState<number | undefined>(args?.chainId);
  const ensNameResponse = useEnsName({
    ...args,
    chainId: usedChainId,
    query: {
      staleTime: TIME_ONE_HOUR_IN_SECONDS * 1000,
      ...args?.query,
    },
  });
  const { error } = ensNameResponse;
  useEffect(() => {
    if (!error) return;

    setUsedChainId(defaults.network.defaultChainId);
  }, [error]);

  return ensNameResponse;
};
