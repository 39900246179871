import { getWalletClient } from '@wagmi/core';

import { entityAbi } from '@endaoment-frontend/abis';
import { ensureUserChain } from '@endaoment-frontend/multichain';
import type { AssembledTradeResponse, UUID } from '@endaoment-frontend/types';

import type { ReactionHookOptions } from './generateReactionHook';
import { generateReactionHook } from './generateReactionHook';
import { writeContractWithIncreasedGas } from './writeContractWithIncreasedGas';

type EditPositionArgs = Pick<AssembledTradeResponse, 'entityAddress' | 'tradeData'> & {
  recommendationId: UUID | undefined;
};

const createTransaction: ReactionHookOptions<EditPositionArgs>['createTransaction'] = async ({ args, wagmiConfig }) => {
  const walletClient = await getWalletClient(wagmiConfig);
  const { tradeData, entityAddress } = args;
  const { portfolioAddress, tradeType, amount, callData, chainId } = tradeData;

  // Ensure that the user is on the same chain as the portfolio
  await ensureUserChain(wagmiConfig, chainId);

  if (tradeType === 'Buy') {
    return writeContractWithIncreasedGas(wagmiConfig, {
      abi: entityAbi,
      address: entityAddress,
      functionName: 'portfolioDeposit',
      args: [portfolioAddress, amount, callData],
      account: walletClient.account,
      chainId,
    });
  }

  // On a sell, amount is the number of shares to sell
  return writeContractWithIncreasedGas(wagmiConfig, {
    abi: entityAbi,
    address: entityAddress,
    functionName: 'portfolioRedeem',
    args: [portfolioAddress, amount, callData],
    account: walletClient.account,
    chainId,
  });
};

export const useEditPosition = () =>
  generateReactionHook({
    actionName: 'EDIT_POSITION',
    createTransaction,
    createDescription: ({ args }) => `Portfolio ${args.tradeData.tradeType === 'Buy' ? 'Depositing' : 'Redeeming'}`,
    createExtra: ({ args }) => ({
      recommendationId: args.recommendationId,
    }),
  });
