import { Link } from '@chakra-ui/next-js';
import type { ReactNode } from 'react';
import { P, match } from 'ts-pattern';

import { getAddressLink, useEnsNameWithFallback } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import { addressSchema, type Address, type EntityLabel, type UUID } from '@endaoment-frontend/types';
import { AllocationIcon, CircleIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { Pill } from '@endaoment-frontend/ui/shared';
import { formatShortAddress } from '@endaoment-frontend/utils';

export const OrgPill = ({ org }: { org: EntityLabel }) => {
  return (
    <Pill
      as={Link}
      href={routes.app.org({ einOrId: org.id })}
      size='tiny'
      variation='blue'
      outline
      rectangle
      data-testid='activity-org-pill'>
      <CircleIcon />
      {org.name}
    </Pill>
  );
};

export const SubprojectPill = ({ subproject }: { subproject: EntityLabel }) => {
  return (
    <Pill size='tiny' variation='blue' outline rectangle data-testid='activity-subproject-pill'>
      <CircleIcon />
      {subproject.name}
    </Pill>
  );
};

export const PortfolioPill = ({ portfolio }: { portfolio: { name: string; id: UUID; ticker?: string | null } }) => {
  return (
    <Pill
      as={Link}
      href={routes.app.portfolio({ id: portfolio.id })}
      size='tiny'
      variation='violet'
      outline
      rectangle
      data-testid='activity-portfolio-pill'>
      <AllocationIcon />
      {portfolio.ticker ?? portfolio.name}
    </Pill>
  );
};

export const FundPill = ({ id, name }: { id?: UUID; name: string }) => {
  const pill = (
    <Pill size='tiny' variation='orange' outline rectangle data-testid='activity-fund-pill'>
      <StarIcon />
      {name}
    </Pill>
  );

  if (!id) {
    return pill;
  }

  return <Link href={routes.app.fund({ id: id })}>{pill}</Link>;
};

export const AddressPill = ({ transactor, chainId }: { transactor: Address; chainId: number }) => {
  const { data: transactorEnsName } = useEnsNameWithFallback({ address: transactor });

  return (
    <Pill
      as={Link}
      href={getAddressLink(transactor, chainId)}
      target='_blank'
      size='tiny'
      variation='purple'
      outline
      data-testid='activity-address-pill'>
      {transactorEnsName ?? formatShortAddress(transactor, 4)}
    </Pill>
  );
};

export const PersonPill = ({ children }: { children?: ReactNode }) => {
  return (
    <Pill size='tiny' variation='purple' outline data-testid='activity-person-pill'>
      {children}
    </Pill>
  );
};

export const EntityLabelPill = ({ entityLabel }: { entityLabel: EntityLabel }) =>
  match(entityLabel)
    .with({ type: 'org' }, org => <OrgPill org={org} />)
    .with({ type: 'subproject' }, subproject => <SubprojectPill subproject={subproject} />)
    .with({ type: 'fund', name: P.when(v => addressSchema.safeParse(v).success) }, () => (
      <FundPill name='Private Fund' />
    ))
    .with({ type: 'fund' }, fund => <FundPill id={fund.id} name={fund.name} />)
    .exhaustive();
