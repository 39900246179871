import { Text } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import { useQueryClient } from '@tanstack/react-query';
import { useReducer } from 'react';
import { match, P } from 'ts-pattern';

import { GetUserFunds, GetUserIdentity, WhoAmI } from '@endaoment-frontend/api';
import { useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import { useDeployAndCreateFund } from '@endaoment-frontend/blockchain-interactions';
import { StepModal } from '@endaoment-frontend/ui/shared';

import { CreateFundAdvisor } from '../common/CreateFundAdvisor';
import { CreateFundDetails } from '../common/CreateFundDetails';
import { useFundWizardSetters, type FundCreateState, type FundMigrateState } from '../useFundWizardState';

import { CreateFundComplete } from './CreateFundComplete';

type FundCreateStep = 'advisor' | 'complete' | 'details';

const useCreateFundFlowState = (state: FundCreateState | FundMigrateState) => {
  const [step, setStep] = useReducer((_prev: FundCreateStep, next: FundCreateStep) => {
    trackEvent({ event: 'fw_wizard_progress', data: { fw_wizard_step: next } });
    return next;
  }, 'details');
  const wizardSetters = useFundWizardSetters();

  return {
    wizard: {
      step,
      fundDetails: state.fundDetails,
    } as const,
    ...wizardSetters,
    setStep,
  } as const;
};

export const CreateFundFlow = ({
  onClose,
  state,
}: {
  onClose: () => void;
  state: FundCreateState | FundMigrateState;
}) => {
  const queryClient = useQueryClient();
  const flowState = useCreateFundFlowState(state);

  const { authAddress } = useAuth();
  const { showWallet } = useWalletModal();

  const isForMigration = state.mode === 'migrate';

  const { createFund, createdFundId } = useDeployAndCreateFund({
    onSuccess: () => {
      WhoAmI.invalidateQuery(queryClient);
      GetUserIdentity.executeAndSave([]);
      GetUserFunds.invalidateQuery(queryClient, []);
    },
  });

  const steps = match(flowState.wizard)
    .with({ step: 'details' }, ({ fundDetails }) => (
      <StepModal.Step key='details' header='New Fund Details' onClose={onClose} hideBottomSpace>
        <CreateFundDetails
          initialValues={fundDetails}
          onSubmit={details => {
            flowState.setNewFundName(details.name);
            flowState.setNewFundChainId(details.chainId);
            flowState.setReferralSource(details.referralSource);
            flowState.setStep('advisor');
          }}
          onFundChainIdChange={chainId => flowState.setNewFundChainId(chainId)}
          onFundNameChange={flowState.setNewFundName}
        />
      </StepModal.Step>
    ))
    .with({ step: 'advisor', fundDetails: P.nonNullable }, ({ fundDetails }) => (
      <StepModal.Step
        key='advisor'
        header='Fund Advisor Details'
        onBack={() => flowState.setStep('details')}
        onClose={onClose}>
        <CreateFundAdvisor
          createButtonText='Create Fund'
          onSubmit={advisor => {
            if (!authAddress) {
              showWallet();
              return;
            }

            createFund({ fundDetails, advisor }).then(v => v.id);

            trackEvent({
              event: 'fw_create_fund',
              data: {
                is_migration: isForMigration,
                fund_name: fundDetails.name,
                fund_advisor: advisor.firstName + ' ' + advisor.lastName,
                fund_advisor_email: advisor.email,
                user_wallet: authAddress,
              },
            });

            flowState.setStep('complete');
          }}
        />
      </StepModal.Step>
    ))
    .with({ step: 'complete' }, () => (
      <StepModal.Step key='complete' header='Fund Creation' onClose={onClose} hideBottomSpace>
        <CreateFundComplete createdFundId={createdFundId} />
      </StepModal.Step>
    ))
    .otherwise(wizard => {
      console.error('Unhandled Create Fund Wizard state', wizard);
      return (
        <StepModal.Step key='error' onClose={onClose} header='Error'>
          <Text alignSelf='center'>Something went wrong</Text>
        </StepModal.Step>
      );
    });

  return <StepModal.StepsWrapper>{steps}</StepModal.StepsWrapper>;
};
