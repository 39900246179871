import { Flex, List, ListItem } from '@chakra-ui/react';
import { skipToken } from '@tanstack/react-query';
import clsx from 'clsx';
import Image from 'next/image';
import { useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { formatUnits } from 'viem';

import { GetAllTokens, GetEvmTokens } from '@endaoment-frontend/api';
import { useTokenBalances } from '@endaoment-frontend/blockchain-interactions';
import { getChainNameForChainId, isSupportedChain } from '@endaoment-frontend/multichain';
import { evmTokenSchema, type Address, type EVMToken, type OTCToken } from '@endaoment-frontend/types';
import { Input } from '@endaoment-frontend/ui/forms';
import { ChainIcon, FavoriteIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader, Modal, Tooltip } from '@endaoment-frontend/ui/shared';
import { formatNumber } from '@endaoment-frontend/utils';

import styles from './TokenList.module.scss';
import { sortTokens, sortTokensByPopularity } from './helper';

type TokenListProps = { isOpen: boolean; hideBalances?: boolean; onClose: () => void } & (
  | { type: 'All'; onSelect: (token: EVMToken | OTCToken) => void }
  | { type: 'EvmToken'; chainId: number; walletAddress: Address; onSelect: (token: EVMToken, balance: bigint) => void }
);

const KEY = 'pinned-token-list';

export const TokenList = ({ hideBalances = false, ...rest }: TokenListProps) => {
  const chainId = rest.type === 'EvmToken' ? rest.chainId : undefined;
  const walletAddress = rest.type === 'EvmToken' ? rest.walletAddress : undefined;
  const { type } = rest;

  const [pinnedTokenIds = [], setPinnedTokenIds] = useLocalStorage<Array<number>>(KEY, []);
  const [filter, setFilter] = useState('');

  // Fetch tokens based on type
  const { data: allTokens } = GetAllTokens.useQuery([], {
    enabled: type === 'All',
  });
  const { data: evmTokens } = GetEvmTokens.useQuery(chainId ? [chainId] : skipToken, {
    enabled: !!chainId && type === 'EvmToken',
  });
  const tokens = type === 'All' ? allTokens : evmTokens;

  // Get balances for EVM tokens
  const { data: balances, isPending: isPendingBalances } = useTokenBalances(
    type === 'All' || !evmTokens ? [] : evmTokens,
    walletAddress,
    {
      enabled: type === 'EvmToken' && !hideBalances && !!tokens && tokens.length > 0,
    },
  );

  // If EVM tokens, show balance along token
  const tokenBalances = balances?.filter(b => b.balance > 0n);

  // List of featured tokens
  const featuredTokens = useMemo(() => {
    if (!tokens) return [];
    return tokens.filter(token => token.featured).toSorted(sortTokensByPopularity);
  }, [tokens]);

  // See sorter function for sorting order logic
  const sortedTokens = useMemo(() => {
    if (!tokens) return [];
    return tokens.toSorted((t1, t2) => sortTokens(t1, t2, pinnedTokenIds, tokenBalances));
  }, [tokens, pinnedTokenIds, tokenBalances]);

  // Filter by name, symbol or contract address (if possible)
  const filteredTokens = sortedTokens
    .filter(
      token =>
        token.name.toLowerCase().includes(filter.trim().toLowerCase()) ||
        token.symbol.toLowerCase().includes(filter.trim().toLowerCase()) ||
        ('contractAddress' in token && token.contractAddress.toLowerCase().includes(filter.toLowerCase())),
    )
    .slice(0, 20);

  const handleTokenSelect = (token: EVMToken | OTCToken) => {
    if (rest.type === 'EvmToken')
      rest.onSelect(evmTokenSchema.parse(token), tokenBalances?.find(tb => tb.token.id === token.id)?.balance || 0n);
    else rest.onSelect(token);
  };

  const handlePinToken = (tokenId: number, remove = false) => {
    if (remove) setPinnedTokenIds(pinnedTokenIds.filter(id => id !== tokenId));
    else setPinnedTokenIds([...pinnedTokenIds, tokenId]);
  };

  return (
    <Modal title='Tokens' className={styles['token-list']} {...rest}>
      {!tokens ? (
        <Loader />
      ) : (
        <>
          {/* Filter input */}
          <Input
            value={filter}
            onChange={e => setFilter(e.target.value)}
            placeholder={`Search ${formatNumber(tokens.length, {
              digits: 2,
              stripZeros: true,
            })}+ ${chainId ? getChainNameForChainId(chainId) : ''} tokens by name, symbol or address`}
            type='search'
            className={styles.input}
          />

          {/* Featured tokens, if any */}
          {featuredTokens.length > 0 && filter.trim() === '' && (
            <div className={styles.featured}>
              {featuredTokens.map(token => (
                <strong key={token.id} onClick={() => handleTokenSelect(token)}>
                  {!!token.logoUrl && <Image src={token.logoUrl} alt={`${token.symbol} icon`} height={20} width={20} />}
                  {token.symbol}
                  {token.type === 'EvmToken' && (
                    <Tooltip content={`${token.name} on ${getChainNameForChainId(token.chainId)}`}>
                      <ChainIcon chainId={token.chainId} filled light tiny />
                    </Tooltip>
                  )}
                </strong>
              ))}
            </div>
          )}

          <hr />

          {/* List of tokens */}
          <List className={styles.tokens}>
            {filteredTokens.map(token => {
              const balance = tokenBalances?.find(tb => tb.token.id === token.id)?.balance || 0n;
              const isPinned = pinnedTokenIds.includes(token.id);

              return (
                <ListItem key={token.id} className={styles.token}>
                  <div onClick={() => handleTokenSelect(token)} className={styles.info}>
                    {!!token.logoUrl && (
                      <Image src={token.logoUrl} alt={`${token.symbol} icon`} height={24} width={24} />
                    )}
                    <p>
                      <Flex as='strong' alignItems='center'>
                        {token.name}{' '}
                        {type === 'All' && 'chainId' in token && isSupportedChain(token.chainId) && (
                          <Tooltip content={`${token.name} on ${getChainNameForChainId(token.chainId)}`}>
                            <ChainIcon chainId={token.chainId} filled light tiny />
                          </Tooltip>
                        )}
                      </Flex>
                      <Flex as='small' className={clsx(balance > 0 && styles.balance)} gap={0} alignItems='baseline'>
                        {type === 'EvmToken' ? (isPendingBalances ? '...' : formatUnits(balance, token.decimals)) : ''}{' '}
                        {token.symbol}
                        {type === 'All' && 'chainId' in token && isSupportedChain(token.chainId) && (
                          <span> on {getChainNameForChainId(token.chainId)}</span>
                        )}
                      </Flex>
                    </p>
                  </div>

                  <Button
                    onClick={() => handlePinToken(token.id, isPinned)}
                    size='small'
                    minimal
                    float={false}
                    className={clsx(styles.pin, isPinned && styles['pin--pinned'])}
                    title='Pin Token'>
                    <FavoriteIcon color='currentColor' width={26} />
                  </Button>
                </ListItem>
              );
            })}
            <li>
              {filteredTokens.length === 0 ? (
                <p className={styles['no-results']}>
                  No tokens found for <strong>{filter}</strong>
                </p>
              ) : (
                <p className={styles['end-of-list']}>
                  Showing {filteredTokens.length} token{filteredTokens.length === 1 ? '' : 's'} of {tokens.length}.
                  <strong>Try searching for more tokens by name, symbol or address.</strong>
                </p>
              )}
            </li>
          </List>
        </>
      )}
    </Modal>
  );
};
