import { addBreadcrumb } from '@sentry/nextjs';
import { getWalletClient } from '@wagmi/core';

import { entityMulticallAbi } from '@endaoment-frontend/abis';
import { GetFund } from '@endaoment-frontend/api';
import type { ReactionHookOptions } from '@endaoment-frontend/blockchain-interactions';
import { generateReactionHook, writeContractWithIncreasedGas } from '@endaoment-frontend/blockchain-interactions';
import { defaults } from '@endaoment-frontend/config';
import { ensureUserChain, getContractAddressForChain } from '@endaoment-frontend/multichain';
import type { UUID } from '@endaoment-frontend/types';

import { CalculateEntityRebalance } from './requests';

type RebalanceFundTransactionArgs = {
  fundId: UUID;
};

const createRebalanceFundTransaction: ReactionHookOptions<RebalanceFundTransactionArgs>['createTransaction'] = async ({
  args,
  wagmiConfig,
}) => {
  const { fundId } = args;
  const fund = await GetFund.fetchFromDefaultClient([fundId]);
  if (typeof fund.v2ContractAddress !== 'string')
    throw new Error('Attempting to rebalance a fund that has not been deployed');

  // Ensure the user is on the same chain as the fund
  await ensureUserChain(wagmiConfig, fund.chainId);
  const walletClient = await getWalletClient(wagmiConfig);

  // Force the network call in order to get the latest data
  const rebalanceCalculation = await CalculateEntityRebalance.executeAndSave(['fund', fund.id, true]);
  const validOperations = rebalanceCalculation.filter(v => !!v.readyForExecution);

  if (validOperations.length === 0) {
    throw new Error('No valid rebalance operations to execute');
  }

  addBreadcrumb({
    level: 'info',
    message: 'Creating rebalance transaction',
    data: {
      fundId,
      fundContractAddress: fund.v2ContractAddress,
      chainId: fund.chainId,
      operations: validOperations,
    },
  });

  return writeContractWithIncreasedGas(
    wagmiConfig,
    {
      abi: entityMulticallAbi,
      address: getContractAddressForChain(fund.chainId, 'entityMulticall'),
      functionName: 'multicall',
      args: [
        fund.v2ContractAddress,
        validOperations.map(v => {
          if (!v.calldata) throw new Error('No calldata for rebalance operation');
          return { value: 0n, data: v.calldata };
        }),
      ],
      account: walletClient.account,
    },
    undefined,
    {
      chainId: fund.chainId,
    },
  );
};

export const useRebalanceFund = () =>
  generateReactionHook({
    actionName: 'REBALANCE_FUND',
    createTransaction: createRebalanceFundTransaction,
    createDescription: () => 'Rebalance Fund',
    confirmations: defaults.confirmations.portfolioTrade,
  });
