import { trackEvent } from '@phntms/next-gtm';
import { useQueryStates } from 'nuqs';

import { useIsMounted } from '@endaoment-frontend/hooks';
import { portfolioWizardParsers } from '@endaoment-frontend/routes';
import type { UUID } from '@endaoment-frontend/types';

type OpenWizardOptions = {
  initialFundId?: UUID;
  initialPortfolioId?: UUID;
};

export const usePortfolioWizardState = () => {
  const isMounted = useIsMounted();
  const [queryState, setQueryState] = useQueryStates(portfolioWizardParsers);
  const {
    isPortfolioWizardOpen,
    portfolioWizardFundId,
    portfolioWizardPortfolioId,
    portfolioWizardAmount,
    portfolioWizardIsDeposit,
  } = queryState;

  const handleFundIdChange = (newVal?: NonNullable<typeof portfolioWizardFundId>) => {
    setQueryState({ portfolioWizardFundId: !newVal ? null : newVal });
  };
  const handlePortfolioIdChange = (newVal?: NonNullable<typeof portfolioWizardPortfolioId>) => {
    setQueryState({ portfolioWizardPortfolioId: !newVal ? null : newVal });
  };
  const handleAmountChange = (newVal?: NonNullable<typeof portfolioWizardAmount>) => {
    setQueryState({ portfolioWizardAmount: !newVal ? null : newVal });
  };
  const handleIsDepositChange = (newVal?: NonNullable<typeof portfolioWizardIsDeposit>) => {
    setQueryState({ portfolioWizardIsDeposit: typeof newVal === 'boolean' ? newVal : null });
  };

  const openPortfolioWizard = (options?: OpenWizardOptions) => {
    setQueryState({
      isPortfolioWizardOpen: true,
      portfolioWizardFundId: options?.initialFundId ?? null,
      portfolioWizardPortfolioId: options?.initialPortfolioId ?? null,
      portfolioWizardAmount: null,
      portfolioWizardIsDeposit: null,
    });

    trackEvent({
      event: 'pw_open_wizard',
    });
  };
  const resetPortfolioWizard = () => {
    setQueryState({
      isPortfolioWizardOpen: null,
      portfolioWizardFundId: null,
      portfolioWizardPortfolioId: null,
      portfolioWizardAmount: null,
      portfolioWizardIsDeposit: null,
    });
  };

  return {
    isPortfolioWizardOpen: isPortfolioWizardOpen && isMounted,
    openPortfolioWizard,
    resetPortfolioWizard,
    state: {
      fundId: portfolioWizardFundId ?? undefined,
      portfolioId: portfolioWizardPortfolioId ?? undefined,
      amount: portfolioWizardAmount ?? undefined,
      isDeposit: portfolioWizardIsDeposit,
    },
    /** This is for internal use, do not access outside the portfolio-wizard lib */
    __querySetters: {
      setFundId: handleFundIdChange,
      setPortfolioId: handlePortfolioIdChange,
      setAmount: handleAmountChange,
      setIsDeposit: handleIsDepositChange,
    },
  } as const;
};

export const useOpenPortfolioWizard = () => usePortfolioWizardState().openPortfolioWizard;
