import { Text } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { Ref } from 'react';
import { forwardRef, useReducer, useState } from 'react';
import { P, match } from 'ts-pattern';

import { GetFundActivity, GetOrg, GetOrgActivity, GetUserActivity, StartCashDonation } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import type { DonationRecipient, UUID } from '@endaoment-frontend/types';
import { Loader, StepModal } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';

import { useInitializeTaxReceipt } from '../common/TaxReceiptButton';
import type { DonationTaxInfoFormData, SubformProps } from '../DonationWizard.types';
import { DonationTaxInfo } from '../erc-donation-flow/DonationTaxInfo';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

import { CreditAmountStep } from './CreditAmountStep';
import { CreditDonationStripeElement } from './CreditDonationStripeElement';
import { ViewCreditDonation } from './ViewCreditDonation';

// Imports for additional Payment Buttons to be enabled when Stripe supports them
// import Image from 'next/image';
// import creditStyles from './CreditDonation.module.scss';
// import applePayLogo from './payment-logos/apple-pay.svg';
// import cashAppLogo from './payment-logos/cash-app.svg';
// import googlePayLogo from './payment-logos/google-pay.svg';
// import paypalLogo from './payment-logos/paypal.svg';

const creditDonationSteps = ['amount', 'tax-receipt', 'confirmation'] as const;
const creditDonationPages = creditDonationSteps.length + 2;
type CreditDonationStep = (typeof creditDonationSteps)[number];

const stripePromise = loadStripe(defaults.stripePublishableKey);

const useCreditDonationFlowState = ({
  rawState,
  rawStateSetters,
}: Pick<SubformProps, 'rawState' | 'rawStateSetters'>) => {
  const [step, setStep] = useReducer((_prev: CreditDonationStep, next: CreditDonationStep) => {
    trackEvent({ event: 'dw_wizard_progress', data: { dw_wizard_step: next, dw_wizard_mode: rawState.mode } });
    return next;
  }, 'amount');
  const [taxInfo, setTaxInfo] = useState<DonationTaxInfoFormData>();

  return {
    wizard: {
      step,
      recipient: rawState.recipient,
      pledgedAmountCents: rawState.pledgeAmount,
      includeTaxReceipt: rawState.includeTaxReceipt,
      taxInfo,
      isRebalanceRequested: rawState.isRebalanceRequested,
      recommendationId: rawState.recommendationId,
    },
    setStep,
    setRecipient: rawStateSetters.setRecipient,
    setPledgedAmountInCents: rawStateSetters.setCreditPledgeAmount,
    setIncludeTaxReceipt: rawStateSetters.setIncludeTaxReceipt,
    setTaxInfo,
    setIsRebalanceRequested: rawStateSetters.setIsRebalanceRequested,
  } as const;
};

const CreditDonationFlowWithRef = (
  { onClose, onReset, rawState, rawStateSetters }: SubformProps,
  ref?: Ref<HTMLDivElement>,
) => {
  const queryClient = useQueryClient();
  const { data: secrets, mutateAsync: fetchSecrets } = useMutation({
    mutationKey: StartCashDonation.prefixKeys,
    mutationFn: ({
      pledgedAmountCents,
      recipient,
      taxInfo,
      isRebalanceRequested,
      recommendationId,
    }: {
      pledgedAmountCents: number;
      recipient: DonationRecipient;
      taxInfo?: DonationTaxInfoFormData;
      isRebalanceRequested: boolean;
      recommendationId?: UUID;
    }) =>
      StartCashDonation.execute({
        pledgedAmountCents,
        recipient,
        donorIdentity: taxInfo,
        shareMyEmail: taxInfo?.shareMyEmail,
        updateIdentity: taxInfo?.updateProfile,
        isRebalanceRequested,
        recommendationId,
      }),
    onSuccess: async (_data, input) => {
      GetUserActivity.invalidateQuery(queryClient, []);

      if (input.recipient.type === 'org') {
        const org = await GetOrg.fetchFromDefaultClient([input.recipient.einOrId]);
        GetOrgActivity.invalidateQuery(queryClient, [org.id]);
      }

      if (input.recipient.type === 'fund') {
        GetFundActivity.invalidateQuery(queryClient, [input.recipient.id]);
      }
    },
    retry: false,
  });

  const flowState = useCreditDonationFlowState({
    rawState,
    rawStateSetters,
  });

  const isFundCollaborator = useIsFundCollaborator(
    flowState.wizard.recipient?.type === 'fund' ? flowState.wizard.recipient.id : undefined,
  );

  useInitializeTaxReceipt(
    v => {
      if (!v) {
        flowState.setIncludeTaxReceipt(false);
        flowState.setTaxInfo(undefined);
        return;
      }
      flowState.setIncludeTaxReceipt(true);
      flowState.setTaxInfo({
        ...v,
        shareMyEmail: false,
        updateProfile: false,
      });
    },
    {
      isCollaborator: !!isFundCollaborator,
    },
  );

  const steps = match(flowState.wizard)
    .with(
      { step: 'amount', recipient: P.nonNullable },
      ({ recipient, pledgedAmountCents, includeTaxReceipt, isRebalanceRequested, taxInfo }) => {
        return (
          <StepModal.Step
            key='amount'
            ref={ref}
            onClose={onClose}
            header='Cash Donation'
            progress={{ current: 3, pages: creditDonationPages }}
            onBack={onReset}>
            <EntityCardWithLabel label='Donating to' entity={recipient} onRemove={onReset} />
            <CreditAmountStep
              onSubmit={async v => {
                flowState.setPledgedAmountInCents(v.pledgedAmountCents);
                await fetchSecrets({
                  pledgedAmountCents: v.pledgedAmountCents,
                  recipient,
                  isRebalanceRequested,
                  taxInfo: includeTaxReceipt ? taxInfo : undefined,
                });
                flowState.setStep('confirmation');
              }}
              initialValues={{ pledgedAmountCents }}
              isRebalanceRequested={isRebalanceRequested}
              onChangeRebalanceRequested={flowState.setIsRebalanceRequested}
              recipient={recipient}
              includeTaxReceipt={includeTaxReceipt}
              receiptEmail={taxInfo?.email}
              onGoToTaxStep={v => {
                flowState.setPledgedAmountInCents(v?.pledgedAmountCents);
                flowState.setStep('tax-receipt');
              }}
              onNoTaxReceipt={() => {
                flowState.setIncludeTaxReceipt(false);
                flowState.setTaxInfo(undefined);
              }}
            />
          </StepModal.Step>
        );
      },
    )
    .with(
      { step: 'tax-receipt', recipient: P.nonNullable, pledgedAmountCents: P.nonNullable },
      ({ recipient, pledgedAmountCents, isRebalanceRequested, taxInfo }) => (
        <StepModal.Step key='tax-receipt' ref={ref} onClose={onClose} header='New Donation / Tax Receipt'>
          <DonationTaxInfo
            initialValues={taxInfo}
            donationDestinationType={recipient.type}
            onSkip={() => {
              flowState.setIncludeTaxReceipt(false);
              flowState.setStep('amount');
            }}
            onSubmit={t => {
              flowState.setIncludeTaxReceipt(true);
              flowState.setTaxInfo(t);
              flowState.setStep('amount');
            }}
          />
        </StepModal.Step>
      ),
    )
    .with(
      {
        step: 'confirmation',
        recipient: P.nonNullable,
        pledgedAmountCents: P.nonNullable,
      },
      ({ recipient, pledgedAmountCents, includeTaxReceipt }) => {
        return (
          <StepModal.Step
            key='confirmation'
            ref={ref}
            onClose={onClose}
            header='Cash Donation'
            progress={{ current: 5, pages: creditDonationPages }}
            onBack={() => (includeTaxReceipt ? flowState.setStep('tax-receipt') : flowState.setStep('amount'))}>
            {secrets ? (
              <Elements
                key={secrets.clientSecret}
                stripe={stripePromise}
                options={{
                  clientSecret: secrets.clientSecret,
                  appearance: {
                    theme: 'flat',
                  },
                }}>
                <CreditDonationStripeElement
                  pledgeId={secrets.id}
                  destination={recipient}
                  pledgedAmountCents={pledgedAmountCents}
                  onClose={onClose}
                />
              </Elements>
            ) : (
              <ViewCreditDonation destination={recipient} pledgedAmountCents={pledgedAmountCents} onClose={onClose}>
                <Loader size='l' />
              </ViewCreditDonation>
            )}
          </StepModal.Step>
        );
      },
    )
    .otherwise(wizard => {
      console.error('Unhandled donation wizard state', wizard);
      return (
        <StepModal.Step key='error' ref={ref} onClose={onClose} header='Error'>
          <Text alignSelf='center'>Something went wrong</Text>
        </StepModal.Step>
      );
    });
  return steps;
};
export const CreditDonationFlow = forwardRef(CreditDonationFlowWithRef);
