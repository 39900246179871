import { Box } from '@chakra-ui/react';
import { skipToken, type MutationStatus } from '@tanstack/react-query';
import clsx from 'clsx';
import Image from 'next/image';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { P, match } from 'ts-pattern';
import { z } from 'zod';

import { GetAllTokens, GetToken } from '@endaoment-frontend/api';
import { useAuthType } from '@endaoment-frontend/authentication';
import { useIsMobile } from '@endaoment-frontend/hooks';
import type { DonationRecipient, EVMToken, OTCToken } from '@endaoment-frontend/types';
import { BigNumberInput, ProceedButton } from '@endaoment-frontend/ui/forms';
import { ArrowIcon, DownCaretIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader, Pill } from '@endaoment-frontend/ui/shared';
import { TokenList } from '@endaoment-frontend/ui/smart';

import styles from '../DonationWizard.module.scss';
import { RecommendButton } from '../common/RecommendButton';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

export const OtcTokenInput = ({
  recipient,
  initialValues,
  switchMethodToWallet,
  onSubmit,
  onRecommend,
  recommendStatus,
}: {
  recipient: DonationRecipient;
  initialValues: { amount: bigint; tokenId?: number };
  switchMethodToWallet: (token: EVMToken) => void;
  onSubmit: (token: EVMToken | OTCToken, amount: bigint) => void;
  onRecommend: (token: EVMToken | OTCToken, amount: bigint) => void;
  recommendStatus: MutationStatus;
}) => {
  // TODO: Convert to use form
  const [tokenAmount, setTokenAmount] = useState<bigint>(initialValues.amount);
  const [tokenId, setTokenId] = useState(initialValues.tokenId);
  const { data: token } = GetToken.useQuery(tokenId ? [tokenId] : skipToken, { enabled: !!tokenId });
  const [showTokenOptions, setShowTokenList] = useState(false);

  const { isMobile } = useIsMobile({ defaultState: true });

  const { isWalletAuth } = useAuthType();
  const isFundCollaborator = useIsFundCollaborator(recipient.type === 'fund' ? recipient.id : undefined);

  // All tokens includes NonEvm/OTC AND all EVM tokens, independent of chain
  const { data: tokens } = GetAllTokens.useQuery([]);

  // Set default token to BTC
  useEffect(() => {
    if (!tokens || tokenId !== undefined) return;

    const btcToken = tokens.find(t => t.symbol.toUpperCase() === 'BTC');
    if (btcToken) setTokenId(btcToken.id);
  }, [tokenId, tokens]);

  const [balanceTouched, setBalanceTouched] = useState(false);
  const balanceParse = z.bigint().gt(0n, { message: 'Amount must be greater than 0' }).safeParse(tokenAmount);

  const canProceed = balanceParse.success && !!token;

  // If user chooses to donate an EVM token, suggest they donate via wallet instead
  // If recipient is a fund, only suggest wallet donation if the token is on the same chain as the fund
  // For orgs, always suggest since orgs can accept any token on all supported chains
  const suggestWalletDonation = !!isWalletAuth && token?.type === 'EvmToken';

  return (
    <>
      <div className={styles['donation-token-input']}>
        <div className={styles['donation-token-input__top']}>
          {/* TODO: Replace with TokenAmountInput */}
          <div className={styles['donation-token-input__input']}>
            <BigNumberInput
              name='tokenAmount'
              value={tokenAmount}
              units={token?.decimals || 0}
              onChange={t => {
                // Check to avoid setting balanceTouched to true if the input is being mounted
                if (t !== tokenAmount) {
                  setTokenAmount(t);
                  setBalanceTouched(true);
                }
              }}
              autoFocus={!isMobile}
              rightElements={
                <>
                  <button
                    type='button'
                    className={clsx(styles['select-token'], showTokenOptions && styles['select-token--active'])}
                    onClick={() => {
                      setShowTokenList(v => !v);
                    }}>
                    {token ? (
                      <>
                        {!!token.logoUrl && <Image src={token.logoUrl} alt='' width={20} height={20} />}
                        {token.symbol}
                      </>
                    ) : (
                      <Loader />
                    )}
                    <DownCaretIcon color='currentColor' />
                  </button>

                  <TokenList
                    type='All'
                    isOpen={showTokenOptions}
                    hideBalances
                    onSelect={token => {
                      setTokenId(token.id);
                      setBalanceTouched(true);
                      setShowTokenList(false);
                    }}
                    onClose={() => setShowTokenList(false)}
                  />
                </>
              }
            />
          </div>
          <div className={clsx(styles['donation-token-input__details'])}>
            <div>
              {match([balanceParse, balanceTouched])
                .returnType<ReactNode>()
                .with([{ success: false, error: { errors: [P.any, ...P.array()] } }, true], ([{ error }]) => (
                  <div className={styles['error-container']}>
                    <span>{error.errors[0].message}</span>
                  </div>
                ))
                .otherwise(() => (
                  <></>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Box className={styles['info']}>
        <hr />
        Please note we enforce a minimum on all donations of assets in this manner as they require manual processing.{' '}
        <b>All sub-$250 donations are re-routed to Endaoment's operating fund.</b>
        {!!suggestWalletDonation && (
          <>
            <br />
            <br />
            <Pill size='tiny' variation='purple' pad>
              You selected a token you can also donate onchain via wallet. Wallet donations do not require manual
              processing and are significantly quicker.
            </Pill>
            <Box mt='1rem'>
              <Button onClick={() => switchMethodToWallet(token)} variation='purple' filled size='small'>
                Switch to Wallet Donation <ArrowIcon color='white' />
              </Button>
            </Box>
          </>
        )}
      </Box>
      {isFundCollaborator ? (
        <RecommendButton
          onRecommend={() => {
            if (!canProceed) return;
            onRecommend(token, tokenAmount);
          }}
          recommendStatus={recommendStatus}
        />
      ) : (
        <ProceedButton
          onClick={() => {
            if (!canProceed) return;
            onSubmit(token, tokenAmount);
          }}
          disabled={!canProceed}
        />
      )}
    </>
  );
};
