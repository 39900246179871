import { Link } from '@chakra-ui/next-js';
import { Button } from '@chakra-ui/react';
import { Fade } from '@chakra-ui/transition';
import clsx from 'clsx';
import { useChainId } from 'wagmi';

import { routes } from '@endaoment-frontend/routes';
import type { DonationRecipient, FundListing, OrgListing } from '@endaoment-frontend/types';
import { ArrowIcon } from '@endaoment-frontend/ui/icons';
import { Loader } from '@endaoment-frontend/ui/shared';
import { MiniFundDetails, MiniOrgDetails } from '@endaoment-frontend/ui/smart';

import styles from './SearchResults.module.scss';

type SearchResultsProps = {
  searchTerm?: string;
  data?: {
    orgs: Array<OrgListing>;
    funds: Array<FundListing>;
  };
  mode: 'fund' | 'org';
  flow: 'donation' | 'grant';
  isPending?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  onSubmit: (recipient: DonationRecipient, chainId: number) => void;
};

export const SearchResults = ({
  searchTerm,
  data,
  mode,
  flow,
  isPending = false,
  hasNextPage,
  fetchNextPage,
  onSubmit,
}: SearchResultsProps) => {
  const currentChainId = useChainId();

  if (isPending) return <Loader size='l' className={styles['loader']} />;
  if (!data) return <>No Results</>;

  return (
    <div className={clsx(styles['result-container'], styles[`result-container--${flow}`])}>
      <Fade in={mode === 'org'} delay={0} unmountOnExit className={styles['result-list']}>
        {data.orgs.length === 0 && <div className={styles['no-results']}>No Results</div>}
        {data.orgs.map(org => (
          <MiniOrgDetails
            key={org.ein ?? org.id}
            className={clsx(styles['search-result'], styles['search-result--org'])}
            org={org}
            extraDetails
            hideTooltips
            padding={false}
            onClick={() => onSubmit({ type: 'org', einOrId: org.ein ?? org.id }, currentChainId)}
          />
        ))}
        <SeeMoreButtons searchTerm={searchTerm} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} />
      </Fade>
      <Fade in={mode === 'fund'} delay={0} unmountOnExit className={styles['result-list']}>
        {data.funds.length === 0 && <div className={styles['no-results']}>No Results</div>}
        {data.funds.map(fund => (
          <MiniFundDetails
            key={fund.id}
            className={clsx(styles['search-result'], styles['search-result--fund'])}
            fund={fund}
            extraDetails
            onClick={() => onSubmit({ type: 'fund', id: fund.id }, fund.chainId)}
            padding={false}
          />
        ))}
        <SeeMoreButtons searchTerm={searchTerm} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} />
      </Fade>
    </div>
  );
};

const SeeMoreButtons = ({
  searchTerm,
  hasNextPage,
  fetchNextPage,
}: {
  searchTerm?: string;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
}) => {
  if (!hasNextPage) return <></>;

  return (
    <div className={styles['button--group']}>
      <Button onClick={fetchNextPage} className={styles['button--more']}>
        See More
      </Button>
      {!!false && (
        <Link href={routes.app.explore({ searchTerm })}>
          <Button className={styles['button--explore']}>
            Go to Explore
            <ArrowIcon width={20} strokeWidth={2} color='#627EEA' />
          </Button>
        </Link>
      )}
    </div>
  );
};
