import { trackEvent } from '@phntms/next-gtm';
import { useQueryStates, type Values } from 'nuqs';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import { useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import { useIsMounted } from '@endaoment-frontend/hooks';
import { fundWizardParsers } from '@endaoment-frontend/routes';
import type { UUID } from '@endaoment-frontend/types';

type OpenWizardOptions =
  | {
      initialMode: 'migrate';
      initialDestinationFundId?: UUID;
    }
  | {
      initialMode?: 'create';
    };

export const useFundWizardState = () => {
  const [queryState, setQueryState] = useQueryStates(fundWizardParsers);
  const isMounted = useIsMounted();

  const state = match(queryState.fundWizardMode)
    .with('migrate', mode => ({
      mode,
      destinationFundId: queryState.destinationFundId ?? undefined,
      fundDetails: {
        name: queryState.newFundName,
        chainId: queryState.newFundChainId,
        referralCode: queryState.referralCode,
        referralSource: queryState.referralSource,
      },
      amount: queryState.migrateAmount,
    }))
    .with('create', mode => ({
      mode,
      fundDetails: {
        name: queryState.newFundName,
        chainId: queryState.newFundChainId,
        referralCode: queryState.referralCode,
        referralSource: queryState.referralSource,
      },
    }))
    .otherwise(() => ({
      mode: undefined,
    }));

  const closeFundWizard = () => {
    setQueryState({
      isFundWizardOpen: null,
      fundWizardMode: null,
      destinationFundId: null,
      newFundName: null,
      newFundChainId: null,
      referralCode: null,
      referralSource: null,
      migrateAmount: null,
    });
  };

  return {
    isFundWizardOpen: queryState.isFundWizardOpen && isMounted,
    state,
    closeFundWizard,
  } as const;
};

export const useOpenFundWizard = () => {
  const [, setQueryState] = useQueryStates(fundWizardParsers);

  const { isSignedIn } = useAuth();
  const { showWallet } = useWalletModal();
  const openFundWizard = (options?: OpenWizardOptions) => {
    if (options) {
      // Migration mode requires sign-in
      if (options.initialMode === 'migrate' && !isSignedIn) {
        showWallet();
        return;
      }

      if (options.initialMode === 'migrate') {
        trackEvent({
          event: 'mw_start_migrate',
        });

        setQueryState({
          fundWizardMode: 'migrate',
          destinationFundId: options.initialDestinationFundId,
        });
      }

      if (options.initialMode === 'create') {
        trackEvent({
          event: 'fw_start_create_fund',
          data: {
            is_migration: false,
          },
        });

        setQueryState({
          fundWizardMode: 'create',
        });
      }
    }
    setQueryState({ isFundWizardOpen: true });
    trackEvent({
      event: 'fw_open_wizard',
    });
  };

  return openFundWizard;
};

export type FundCreateState = Extract<ReturnType<typeof useFundWizardState>['state'], { mode: 'create' }>;
export type FundMigrateState = Extract<ReturnType<typeof useFundWizardState>['state'], { mode: 'migrate' }>;

type QueryStateValues = Values<typeof fundWizardParsers>;
export const useFundWizardSetters = () => {
  const [, setQueryState] = useQueryStates(fundWizardParsers);
  const makeSetterWithUndefined =
    <T extends keyof QueryStateValues>(key: T) =>
    (newVal?: NonNullable<QueryStateValues[T]>) => {
      setQueryState({ [key]: typeof newVal === 'undefined' ? null : newVal });
    };

  return useMemo(() => {
    const setDestinationFundId = makeSetterWithUndefined('destinationFundId');
    const setNewFundName = makeSetterWithUndefined('newFundName');
    const setNewFundChainId = makeSetterWithUndefined('newFundChainId');
    const setReferralCode = makeSetterWithUndefined('referralCode');
    const setReferralSource = makeSetterWithUndefined('referralSource');
    const setMigrateAmount = makeSetterWithUndefined('migrateAmount');

    return {
      setDestinationFundId,
      setNewFundName,
      setNewFundChainId,
      setReferralCode,
      setReferralSource,
      setMigrateAmount,
    } as const;
  }, [setQueryState]);
};
