import { Flex } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { match, P } from 'ts-pattern';

import { GetFund, GetFundPositions, GetPortfolio, GetPortfoliosAvailableToFund } from '@endaoment-frontend/api';
import { getChainNameForChainId } from '@endaoment-frontend/multichain';
import type { UUID } from '@endaoment-frontend/types';
import { Input } from '@endaoment-frontend/ui/forms';
import { ChainIcon, InfoIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card, cardClassNames, Pill, Tooltip } from '@endaoment-frontend/ui/shared';
import { FundAllocationBar, MiniLoadingDetails, MiniPortfolioDetails } from '@endaoment-frontend/ui/smart';
import { filterPortfolios, getAllocatablePortfolios } from '@endaoment-frontend/utils';

import { FundDetailsCardWithBar } from '../common/FundDetailsCardWithBar';
import wizardStyles from '../PortfolioWizard.module.scss';

import styles from './PortfolioStep.module.scss';

export const PortfolioStep = ({ fundId, onSelect }: { fundId: UUID; onSelect: (portfolioId: UUID) => void }) => {
  const { data: fund } = GetFund.useQuery([fundId], { enabled: !!fundId });
  const chainId = fund?.chainId;

  const { data: fundPositions } = GetFundPositions.useQuery([fundId, 'accurate']);
  const filteredPositions = fundPositions?.positions.filter(
    position =>
      // Do not show positions that are on a different chain than the fund (though this should never happen)
      chainId && position.portfolio.chainId === chainId,
  );

  const [showExisting, setShowExisting] = useState(true);
  const [showNew, setShowNew] = useState(!filteredPositions || filteredPositions.length < 3);

  return (
    <>
      {/* TODO: add remove icon */}
      <FundDetailsCardWithBar fundId={fundId} />
      {!!chainId && (
        <Flex className={styles['chain-warning']} alignItems='center' justifyContent='center'>
          Limited to portfolios on <ChainIcon chainId={chainId} />
          {getChainNameForChainId(chainId)}&nbsp;
          <Tooltip
            as='span'
            placement='top'
            content='Allocations are limited to portfolios on the same chain as this fund.'
            aria-label={`Limited to portfolios on ${getChainNameForChainId(chainId)}`}>
            <InfoIcon color='currentColor' width={14} />
          </Tooltip>
        </Flex>
      )}
      {match(filteredPositions)
        // Fund has at least one position
        .with([P.any, ...P.array()], fundPositions => (
          <>
            <div className={styles['step-title']}>
              <h4>
                {'Edit an existing allocation '}
                <Pill size='tiny' className={styles['position-count']}>
                  {fundPositions.length}
                </Pill>
              </h4>
              {!!filteredPositions && filteredPositions.length > 1 && (
                <Button
                  size='small'
                  className={clsx(styles['view-toggle'], showExisting && styles['view-toggle--showing'])}
                  filled
                  float={false}
                  onClick={() => setShowExisting(!showExisting)}>
                  {showExisting ? 'Hide' : 'Show'}
                </Button>
              )}
            </div>
            {!!showExisting && (
              <ul className={styles['existing-list']}>
                {fundPositions.map(position => (
                  <li
                    key={position.portfolio.id}
                    onClick={() => onSelect(position.portfolio.id)}
                    className={clsx(
                      cardClassNames.base,
                      cardClassNames.removePadding,
                      cardClassNames.removeShadow,
                      wizardStyles['extended-card'],
                      wizardStyles['selectable'],
                    )}>
                    <MiniPortfolioDetails portfolio={position.portfolio} />
                    {!!fund && (
                      <FundAllocationBar
                        fund={fund}
                        fundId={fund?.id}
                        portfolioId={position.portfolio.id}
                        showPositionFirst
                        isAccurate
                      />
                    )}
                  </li>
                ))}
              </ul>
            )}
            <span className={styles['separator']}>
              <span>OR</span>
            </span>
          </>
        ))
        // TODO: Add UI for loading state
        .otherwise(() => (
          <></>
        ))}
      <PortfolioSelect
        fundId={fundId}
        chainId={chainId}
        onSelect={onSelect}
        showNew={showNew}
        setShowNew={setShowNew}
        showHideButton={!!fundPositions && fundPositions?.positions.length > 1}
      />
    </>
  );
};

const PortfolioSelect = ({
  fundId,
  chainId,
  onSelect,
  showNew,
  showHideButton,
  setShowNew,
}: {
  fundId: UUID;
  chainId?: number;
  onSelect: (portfolioId: UUID) => void;
  showNew?: boolean;
  showHideButton?: boolean;
  setShowNew?: (showNew: boolean) => void;
}) => {
  const [search, setSearch] = useState('');

  const queryClient = useQueryClient();
  const { data: portfolios, isPending } = GetPortfoliosAvailableToFund.useQuery([fundId]);
  useEffect(() => {
    if (!portfolios) return;
    for (const portfolio of portfolios) {
      GetPortfolio.setData(queryClient, [portfolio.id], portfolio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios]);
  const allocatablePortfolios = getAllocatablePortfolios(chainId, portfolios);
  const filteredPortfolios = filterPortfolios(search, chainId, portfolios);

  return (
    <>
      <div className={styles['step-title']}>
        <h4>Choose a new portfolio to allocate to </h4>
        {!!showHideButton && !!allocatablePortfolios && allocatablePortfolios?.length > 3 && (
          <Button
            size='small'
            className={clsx(styles['view-toggle'], showNew && styles['view-toggle--showing'])}
            filled
            float={false}
            onClick={() => setShowNew?.(!showNew)}>
            {showNew ? 'Hide' : 'Show'}
          </Button>
        )}
      </div>
      {!!(!!showNew || isPending) && (
        <div className={wizardStyles['search-container__border']}>
          <div className={wizardStyles['search-container__outer']}>
            {!!allocatablePortfolios && allocatablePortfolios?.length > 3 && (
              <Input
                value={search}
                className={wizardStyles['search-container__filter']}
                onChange={v => setSearch(v.currentTarget.value)}
                placeholder='Filter Portfolios'
              />
            )}
            <div className={clsx(wizardStyles['search-container'], wizardStyles['search-container--with-filter'])}>
              {match(filteredPortfolios)
                .returnType<Array<ReactElement> | ReactElement>()
                .when(
                  () => allocatablePortfolios?.length === 0,
                  () => <h4>There are no portfolios available for the selected fund</h4>,
                )
                .with([], () => <h4>No results</h4>)
                .with(P.not(P.nullish), list =>
                  list.map(portfolio => (
                    <button
                      key={portfolio.id}
                      onClick={() => onSelect(portfolio.id)}
                      className={clsx(
                        cardClassNames.base,
                        cardClassNames.removePadding,
                        cardClassNames.removeShadow,
                        wizardStyles['selectable'],
                      )}>
                      <MiniPortfolioDetails portfolio={portfolio} />
                    </button>
                  )),
                )
                .otherwise(() => (
                  <Card noPadding noShadow>
                    <MiniLoadingDetails />
                  </Card>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
