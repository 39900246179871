import { Popover, PopoverAnchor, PopoverArrow, PopoverBody, PopoverContent } from '@chakra-ui/popover';
import { Portal } from '@chakra-ui/portal';
import type { PlacementWithLogical } from '@chakra-ui/react';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import type { ComponentProps, ComponentPropsWithRef, ReactNode, RefObject } from 'react';
import { forwardRef, useEffect, useRef } from 'react';

import { useIsMounted } from '@endaoment-frontend/hooks';
import { localStorageAtom } from '@endaoment-frontend/react-utils';
import { CloseIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import styles from '../styles/prompt.module.scss';

type ActionPromptProps = Omit<ComponentProps<typeof Button<'div'>>, 'as'> & {
  promptKey: string;
  promptContent: ReactNode;
  promptClassName?: string;
  containerRef?: RefObject<HTMLElement>;
  triggered?: boolean;
  placement?: PlacementWithLogical;
  hideClose?: boolean;
  children: ReactNode;
};

const actionPromptTriggeredAtomFamily = atomFamily((key: string) =>
  localStorageAtom<boolean>(`action-prompt.${key}`, false),
);

export const ActionPromptWithRef = (
  {
    promptKey,
    promptContent,
    promptClassName,
    containerRef,
    triggered = false,
    placement = 'top',
    hideClose = false,
    children,
    ...props
  }: ActionPromptProps,
  ref: ComponentPropsWithRef<'div'>['ref'],
) => {
  const [wasClosed, setWasClosed] = useAtom(actionPromptTriggeredAtomFamily(promptKey));

  const isMounted = useIsMounted();
  const isTriggered = isMounted && !wasClosed && triggered;

  // Toggle animation class using timer to avoid re-rendering the prompt
  const promptRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const timer = setInterval(() => {
      if (!promptRef.current) return;
      promptRef.current.classList.toggle(styles['animate-in']);
    }, 1250);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <Popover
        isOpen={isTriggered}
        autoFocus={false}
        placement={placement}
        openDelay={500}
        arrowSize={50}
        lazyBehavior='unmount'
        isLazy>
        <PopoverAnchor>
          <Button as='div' role='button' ref={ref} {...props}>
            {children}
          </Button>
        </PopoverAnchor>
        <Portal containerRef={containerRef}>
          <PopoverContent className={clsx(styles['prompt'])} ref={promptRef}>
            <PopoverArrow />
            <PopoverBody className={styles['prompt__body']}>
              {promptContent}
              {!hideClose && (
                <Button onClick={() => setWasClosed(true)} className={styles['prompt__close']} float={false} minimal>
                  <CloseIcon width={32} />
                </Button>
              )}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export const ActionPrompt = forwardRef(ActionPromptWithRef);
