import { RequestHandler, isFetchError } from '@endaoment-frontend/data-fetching';
import type { OrgClaim, PhysicalAddress, PositionsSummary, UUID, UserIdentityInfo } from '@endaoment-frontend/types';
import {
  arraySchemaInvalidsFiltered,
  orgClaimSchema,
  portfolioPositionSummarySchema,
  userIdentityInfoSchema,
} from '@endaoment-frontend/types';
import { comparePortfoliosForSort } from '@endaoment-frontend/utils';

export const GetUserClaims = new RequestHandler(
  'GetUserClaims',
  fetch => async (): Promise<Array<OrgClaim>> => {
    const res = await fetch('/v1/claims/mine');
    return arraySchemaInvalidsFiltered(orgClaimSchema).parse(res);
  },
  { isUserSpecificRequest: true },
);

export const GetUserPositions = new RequestHandler(
  'GetUserPositions',
  fetch =>
    async (portfolioId?: UUID): Promise<PositionsSummary> => {
      const res = await fetch('/v1/portfolios/summary', { params: { type: 'fund' } });
      const data = portfolioPositionSummarySchema.parse(res);
      for (const entity of data.entities) {
        entity.positions = entity.positions.toSorted((a, b) => comparePortfoliosForSort(a.portfolio, b.portfolio));
      }

      if (!portfolioId) {
        return data;
      }

      // We need to filter for specific portfolios on the `/portfolios/[id]` route
      const reorganized: PositionsSummary = { ...data, totalInvested: 0n };
      reorganized.entities = data.entities.map(summary => {
        summary.positions = summary.positions.filter(ep => ep.portfolio.id === portfolioId);
        summary.positions.forEach(ep => {
          reorganized.totalInvested = reorganized.totalInvested + ep.currentMarketValue;
        });

        return summary;
      });

      return reorganized;
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios/summary` }),
  },
);

type GetUserIdentityOptions = {
  fallbackToEmpty?: boolean;
};
export const GetUserIdentity = new RequestHandler(
  'GetUserIdentity',
  fetch =>
    async (options?: GetUserIdentityOptions): Promise<UserIdentityInfo> => {
      const mergedOptions = {
        fallbackToEmpty: true,
        ...options,
      };
      try {
        const res = await fetch('/v1/identity');
        const data = userIdentityInfoSchema.parse(res);
        return {
          ...data,
          address: {
            ...data.address,
            // Force line2 to be a string
            line2: data.address.line2 || '',
          },
        };
      } catch (e) {
        if (isFetchError(e) && e.statusCode === 404) {
          if (!mergedOptions.fallbackToEmpty) throw e;

          return {
            firstName: '',
            lastName: '',
            email: '',
            address: {
              line1: '',
              line2: '',
              city: '',
              state: '',
              zip: '',
              country: 'USA',
            } as PhysicalAddress,
          };
        }
        throw e;
      }
    },
  { isUserSpecificRequest: true, makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/identity` }) },
);

export const UpdateUserIdentity = new RequestHandler(
  'UpdateUserIdentity',
  fetch =>
    async (input: UserIdentityInfo): Promise<UserIdentityInfo> => {
      const res = await fetch('/v1/identity', {
        method: 'PUT',
        body: input,
      });
      return userIdentityInfoSchema.parse(res);
    },
  { isUserSpecificRequest: true },
);
