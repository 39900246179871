import { Link } from '@chakra-ui/next-js';
import { Box, Skeleton } from '@chakra-ui/react';
import clsx from 'clsx';
import { useState } from 'react';
import { P, match } from 'ts-pattern';

import { useAccountDisplayName, useAuth, useAuthType } from '@endaoment-frontend/authentication';
import { NetworkSwitcher } from '@endaoment-frontend/multichain';
import { StatusBadge } from '@endaoment-frontend/ui/shared';
import { formatStringSize } from '@endaoment-frontend/utils';

import styles from './NavBar.module.scss';

type NavLinkType = {
  label: string;
  icon?: JSX.Element;
  href: string;
  onClick?: () => void;
};

export const NavLink = ({
  href,
  icon,
  label,
  current,
  onClick,
  className,
}: NavLinkType & { current?: string; className?: string }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cleanCurrent = current ? current.replace(/\?.*/, '') : '';
  const isActive = match(href)
    .returnType<boolean>()
    .with('/', () => cleanCurrent === '/')
    .with(P.string, href => cleanCurrent.startsWith(href))
    .otherwise(() => false);

  return (
    <Link
      href={href}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      scroll={false}
      className={clsx(styles['nav-link'], className, (isHovered || isActive) && styles['active'])}>
      {icon}
      <span>{label}</span>
    </Link>
  );
};

export const computeIsWalletButtonSkeletonLoaded = (
  { isSignedIn, isLoading, isSignedInOnNdao, isSignedInOnPrivy }: ReturnType<typeof useAuth>,
  accountName: string,
): boolean => {
  if (!isSignedIn && !isLoading) return true;
  if (isLoading && isSignedInOnNdao === false && isSignedInOnPrivy === false) return false;
  return isSignedIn && accountName !== '';
};

// Text to show inside of the connect button
export const WalletAuthText = ({ isWalletButtonSkeletonLoaded }: { isWalletButtonSkeletonLoaded: boolean }) => {
  const { isSignedIn, isLoading } = useAuth();
  const { authType } = useAuthType();
  const { shortAccountName } = useAccountDisplayName();

  const displayText = formatStringSize(shortAccountName, 32);

  const inner = match({ isSignedIn, isLoading, authType })
    .with(
      {
        isSignedIn: true,
        authType: 'wallet',
      },
      () => (
        <>
          <Box ml='-0.5rem'>
            <NetworkSwitcher />
          </Box>
          <span>{displayText}</span>
        </>
      ),
    )
    .with(
      {
        isSignedIn: true,
        authType: P.union('social', undefined),
      },
      () => (
        <>
          <StatusBadge />
          <span>{displayText}</span>
        </>
      ),
    )
    .with({ isLoading: true }, () => <></>)
    .otherwise(() => <>Sign In</>);

  return (
    <Skeleton
      data-testid='wallet-auth-text'
      data-loaded={isWalletButtonSkeletonLoaded}
      isLoaded={isWalletButtonSkeletonLoaded}
      display='flex'
      mx={isWalletButtonSkeletonLoaded ? '0' : '-0.5rem'}
      h='1.75rem'
      w={isWalletButtonSkeletonLoaded ? 'fit-content' : '6rem'}
      borderStartRadius='2rem'
      borderEndRadius='0.25rem'
      gap='0.5rem'
      alignItems='center'
      justifyContent='center'>
      {inner}
    </Skeleton>
  );
};
