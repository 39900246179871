import { z } from 'zod';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import {
  arraySchemaInvalidsFiltered,
  fundListingSchema,
  orgListingSchema,
  type CountryCode,
  type FundListing,
  type FundType,
  type Letter,
  type OrgListing,
  type USStateCode,
} from '@endaoment-frontend/types';

type SearchEndaomentOptions = {
  orgCount?: number;
  orgOffset?: number;
  fundCount?: number;
  fundOffset?: number;
};

const searchResultsSchema = z.object({
  orgs: arraySchemaInvalidsFiltered(orgListingSchema),
  funds: arraySchemaInvalidsFiltered(fundListingSchema),
});
type SearchResults = z.infer<typeof searchResultsSchema>;

export const SearchEndaoment = new RequestHandler(
  'SearchEndaoment',
  fetch =>
    async (searchTerm: string, options: SearchEndaomentOptions): Promise<SearchResults> => {
      const res = await fetch('/v1/search', {
        params: {
          searchTerm,
          ...options,
        },
      });
      return searchResultsSchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/search` }),
  },
);

export type OrgStatusFilter = 'claimed' | 'deployed' | 'unclaimed' | 'undeployed';

type SearchOrgsOptions = {
  codes: Array<Letter>;
  countries: Array<CountryCode>;
  states: Array<USStateCode>;
  status: Array<OrgStatusFilter>;
  count: number;
  offset: number;
};

export const SearchOrgs = new RequestHandler(
  'SearchOrgs',
  fetch =>
    async (searchTerm: string, options?: Partial<SearchOrgsOptions>): Promise<Array<OrgListing>> => {
      const params: Record<string, string> = {};
      if (searchTerm) {
        params.searchTerm = searchTerm;
      }
      if (options?.count) {
        params.count = options.count.toString();
      }
      if (options?.offset) {
        params.offset = options.offset.toString();
      }
      if (options?.codes && options.codes.length > 0) {
        params.nteeMajorCodes = options.codes.join(',');
      }
      if (options?.countries && options.countries.length > 0) {
        params.countries = options.countries.join(',');
      }
      if (options?.states && options.states.length > 0) {
        params.subdivisions = options.states.join(',');
      }
      if (options?.status && options.status.length > 0) {
        params.status = options.status.join(',');
      }

      const res = await fetch('/v1/orgs/search', {
        params,
      });
      return arraySchemaInvalidsFiltered(orgListingSchema).parse(res);
    },
);

type SearchFundsOptions = {
  types: Array<FundType>;
  count?: number;
  offset?: number;
};

export const SearchFunds = new RequestHandler(
  'SearchFunds',
  fetch =>
    async (searchTerm: string, options?: Partial<SearchFundsOptions>): Promise<Array<FundListing>> => {
      const params: Record<string, string> = {};
      if (searchTerm) {
        params.searchTerm = searchTerm;
      }
      if (options?.count) {
        params.count = options.count.toString();
      }
      if (options?.offset) {
        params.offset = options.offset.toString();
      }
      if (options?.types && options.types.length > 0) {
        params.types = options.types.join(',');
      }

      const res = await fetch('/v1/funds/search', {
        params,
      });
      return arraySchemaInvalidsFiltered(fundListingSchema).parse(res);
    },
);
