import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type { Activity } from '@endaoment-frontend/types';
import { activitySchema, arraySchemaInvalidsFiltered } from '@endaoment-frontend/types';

/**
 * Fetch all featured activity
 */
export const GetFeaturedActivity = new RequestHandler(
  'GetFeaturedActivity',
  fetch => async (): Promise<Array<Activity>> => {
    const res = await fetch(`/v1/activity/featured`);
    return arraySchemaInvalidsFiltered(activitySchema).parse(res);
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/activity/featured`,
    }),
  },
);

export const GetUserActivity = new RequestHandler(
  'GetUserActivity',
  fetch => async (): Promise<Array<Activity>> => {
    const res = await fetch(`/v1/activity/mine`);
    return arraySchemaInvalidsFiltered(activitySchema).parse(res);
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/activity/mine`,
    }),
  },
);
