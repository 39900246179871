import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type { RecommendationDetails, RecommendationListing, UUID } from '@endaoment-frontend/types';
import {
  arraySchemaInvalidsFiltered,
  recommendationDetailsSchema,
  recommendationListingSchema,
} from '@endaoment-frontend/types';

const sortRecommendationsByDate = <T extends Pick<RecommendationListing, 'createdAt'>>(
  recommendations: Array<T>,
): Array<T> => {
  return recommendations.toSorted((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
};

export const GetRecommendationsMadeForMe = new RequestHandler(
  'GetRecommendationsMadeForMe',
  fetch => async (): Promise<Array<RecommendationListing>> => {
    const res = await fetch(`/v1/recommendations/for-me`, {
      cache: 'no-cache',
    });
    const recommendations = arraySchemaInvalidsFiltered(recommendationListingSchema).parse(res);
    return sortRecommendationsByDate(recommendations);
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/recommendations/for-me`,
    }),
  },
);

export const GetRecommendationsMadeByMe = new RequestHandler(
  'GetRecommendationsMadeByMe',
  fetch => async (): Promise<Array<RecommendationListing>> => {
    const res = await fetch(`/v1/recommendations/by-me`, {
      cache: 'no-cache',
    });
    const recommendations = arraySchemaInvalidsFiltered(recommendationListingSchema).parse(res);
    return sortRecommendationsByDate(recommendations);
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/recommendations/by-me`,
    }),
  },
);

export const GetRecommendationsForFund = new RequestHandler(
  'GetRecommendationsForFund',
  fetch =>
    async (fundId: UUID): Promise<Array<RecommendationListing>> => {
      const res = await fetch(`/v1/recommendations/fund/${fundId}`, {
        cache: 'no-cache',
      });
      return sortRecommendationsByDate(arraySchemaInvalidsFiltered(recommendationListingSchema).parse(res));
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/recommendations/fund/:fundId`,
    }),
  },
);

export const GetRecommendationById = new RequestHandler(
  'GetRecommendationById',
  fetch =>
    async (id: UUID): Promise<RecommendationDetails> => {
      const res = await fetch(`/v1/recommendations/${id}`);
      return recommendationDetailsSchema.parse(res);
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/recommendations/:id`,
    }),
  },
);

export const DismissRecommendation = new RequestHandler(
  'DismissRecommendation',
  fetch => async (id: UUID) => {
    await fetch(`/v1/recommendations/${id}/dismiss`, {
      method: 'POST',
    });
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/recommendations/:id/dismiss` }),
  },
);
