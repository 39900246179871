import type { Span as SentrySpan } from '@sentry/nextjs';
import { captureException, startSpan } from '@sentry/nextjs';
import type { Config, WriteContractReturnType } from '@wagmi/core';
import { getWalletClient, readContract, waitForTransactionReceipt } from '@wagmi/core';
import { match } from 'ts-pattern';
import { erc20Abi, type WalletClient } from 'viem';

import { entityAbi, orgFundFactoryAbi } from '@endaoment-frontend/abis';
import { GetFund, GetOrg, GetSwapInfo } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import {
  ensureUserChain,
  getContractAddressForChain,
  getNativeTokenForChain,
  getStablecoinForChain,
} from '@endaoment-frontend/multichain';
import {
  type Address,
  type CreateDonationInput,
  type DonationRecipient,
  type EVMToken,
} from '@endaoment-frontend/types';
import { equalAddress, getHexForOrgDeployment } from '@endaoment-frontend/utils';

import type { ReactionHookOptions } from './generateReactionHook';
import { generateReactionHook } from './generateReactionHook';
import { resolveOrgDeployment } from './resolveOrgDeployment';
import { writeContractWithIncreasedGas } from './writeContractWithIncreasedGas';

const getTokenPermission = async (
  wagmiConfig: Config,
  walletClient: WalletClient,
  contractAddress: Address,
  tokenAddress: Address,
  amount: bigint,
  span?: SentrySpan,
) => {
  // Check if user has already given permission
  if (!walletClient.account) throw new Error('Wallet client does not have an account');
  const currentAllowance = await readContract(wagmiConfig, {
    abi: erc20Abi,
    address: tokenAddress,
    functionName: 'allowance',
    args: [walletClient.account.address, contractAddress],
    account: walletClient.account,
  });
  if (currentAllowance >= amount) {
    span?.setAttribute('permission', 'unnecessary');
    span?.setStatus({ code: 1 });
    return true;
  }

  try {
    const approvalTransaction = await writeContractWithIncreasedGas(wagmiConfig, {
      abi: erc20Abi,
      address: tokenAddress,
      functionName: 'approve',
      args: [contractAddress, amount],
      account: walletClient.account,
    });

    const approvalReceipt = await waitForTransactionReceipt(wagmiConfig, { hash: approvalTransaction });

    return approvalReceipt.status === 'success';
  } catch (error) {
    captureException(error);
    span?.setStatus({ code: 2 }).end();
    throw error;
  }
};

type CreateDonationArgs = {
  destination: DonationRecipient;
  token: Pick<EVMToken, 'chainId' | 'contractAddress' | 'id' | 'symbol'>;
  tokenAmount: bigint;
  donationData: CreateDonationInput;
};

// TODO: We currently hardcode the gas limits for the donation transactions due to historical OOG issues - we should revisit this logic
// and use smarter solutions (e.g. simulations, tenderly etc.)
export const createDonationTransaction: ReactionHookOptions<CreateDonationArgs>['createTransaction'] = async ({
  args,
  wagmiConfig,
  span,
}) => {
  const walletClient = await getWalletClient(wagmiConfig);
  const chainId = walletClient.chain.id;
  const chainNativeToken = getNativeTokenForChain(chainId);
  const chainStablecoin = getStablecoinForChain(chainId);
  const offAddress = getContractAddressForChain(chainId, 'orgFundFactory');

  const { destination, token } = args;
  const swapInfo = await GetSwapInfo.fetchFromDefaultClient([args.tokenAmount, args.token, args.destination, chainId]);

  /**
   * Use the entity contract and perform donation (for when the org or fund has already been deployed)
   */
  const createDonationTransactionForDeployedEntity = (entityAddress: Address): Promise<WriteContractReturnType> =>
    match(token.symbol)
      .with(chainNativeToken.symbol, async () => {
        // Native token can be directly sent, no perms needed
        return writeContractWithIncreasedGas(wagmiConfig, {
          abi: entityAbi,
          functionName: 'swapAndDonate',
          address: entityAddress,
          account: walletClient.account,
          args: [swapInfo.swapWrapper, swapInfo.tokenIn, swapInfo.amountIn, swapInfo.callData as Address],
          value: swapInfo.amountIn,
          chainId,
        });
      })
      .with(chainStablecoin.symbol, async () => {
        // User needs to give token approval but we do not need to swap
        await startSpan(
          {
            name: 'Get Token Permission',
          },
          span =>
            getTokenPermission(wagmiConfig, walletClient, entityAddress, swapInfo.tokenIn, swapInfo.amountIn, span),
        );
        return writeContractWithIncreasedGas(wagmiConfig, {
          abi: entityAbi,
          functionName: 'donate',
          address: entityAddress,
          account: walletClient.account,
          args: [swapInfo.amountIn],
          chainId,
        });
      })
      .otherwise(async () => {
        // User needs to give token approval and we need to swap
        await startSpan(
          {
            name: 'Get Token Permission',
          },
          span =>
            getTokenPermission(wagmiConfig, walletClient, entityAddress, swapInfo.tokenIn, swapInfo.amountIn, span),
        );
        return writeContractWithIncreasedGas(wagmiConfig, {
          abi: entityAbi,
          functionName: 'swapAndDonate',
          address: entityAddress,
          account: walletClient.account,
          args: [swapInfo.swapWrapper, swapInfo.tokenIn, swapInfo.amountIn, swapInfo.callData as Address],
          chainId,
        });
      });

  return match(destination)
    .returnType<Promise<WriteContractReturnType>>()
    .with({ type: 'org' }, async destination => {
      const org = await GetOrg.fetchFromDefaultClient([destination.einOrId]);
      // Verify if the org is deployed & registered
      const orgDeployment = await resolveOrgDeployment(org, wagmiConfig);

      // Perform regular entity donation if the org has already been deployed
      if (orgDeployment) return createDonationTransactionForDeployedEntity(orgDeployment.contractAddress);

      // If not, use `deployOrg(Swap)AndDonate` contract write if the org has not been deployed yet
      const encodedDeployHex = getHexForOrgDeployment(org.ein ?? org.id);

      return match(token.symbol)
        .with(chainNativeToken.symbol, async () => {
          // Native token can be directly sent, no perms needed
          return writeContractWithIncreasedGas(wagmiConfig, {
            abi: orgFundFactoryAbi,
            functionName: 'deployOrgSwapAndDonate',
            address: offAddress,
            account: walletClient.account,
            args: [
              encodedDeployHex,
              swapInfo.swapWrapper,
              swapInfo.tokenIn,
              swapInfo.amountIn,
              swapInfo.callData as Address,
            ],
            value: swapInfo.amountIn,
            chainId,
          });
        })
        .with(chainStablecoin.symbol, async () => {
          // User needs to give token approval but we do not need to swap
          // Approval is for the OrgFundFactory contract since that is the contract that will execute the donate operation
          await startSpan(
            {
              name: 'Get Token Permission',
            },
            span =>
              getTokenPermission(wagmiConfig, walletClient, offAddress, swapInfo.tokenIn, swapInfo.amountIn, span),
          );

          return writeContractWithIncreasedGas(wagmiConfig, {
            abi: orgFundFactoryAbi,
            functionName: 'deployOrgAndDonate',
            address: offAddress,
            account: walletClient.account,
            args: [encodedDeployHex, swapInfo.amountIn],
            chainId,
          });
        })
        .otherwise(async () => {
          // User needs to give token approval and we need to swap
          // Approval is for the OrgFundFactory contract since that is the contract that will execute the donate operation
          await startSpan(
            {
              name: 'Get Token Permission',
            },
            span =>
              getTokenPermission(wagmiConfig, walletClient, offAddress, swapInfo.tokenIn, swapInfo.amountIn, span),
          );
          return writeContractWithIncreasedGas(wagmiConfig, {
            abi: orgFundFactoryAbi,
            functionName: 'deployOrgSwapAndDonate',
            address: offAddress,
            account: walletClient.account,
            args: [
              encodedDeployHex,
              swapInfo.swapWrapper,
              swapInfo.tokenIn,
              swapInfo.amountIn,
              swapInfo.callData as Address,
            ],
            chainId,
          });
        });
    })
    .with({ type: 'fund' }, async destination => {
      const fund = await GetFund.fetchFromDefaultClient([destination.id]);
      // Make sure user is on the same chain as the fund
      await ensureUserChain(wagmiConfig, fund.chainId);

      // Use `deployFund(Swap)AndDonate` contract write if the fund has not been deployed yet
      if (typeof fund.v2ContractAddress !== 'string') {
        // Ensure we have the expected salt from fetching a FundDetails
        if (!fund.expectedDeploymentInfo)
          throw new Error('FundDetails does not have expectedDeploymentInfo even though fund is not deployed');

        // Validate that the computed contract address matches the expected contract address
        const computedFundAddress = await readContract(wagmiConfig, {
          abi: orgFundFactoryAbi,
          functionName: 'computeFundAddress',
          address: offAddress,
          args: [fund.expectedDeploymentInfo.expectedManagerAddress, fund.expectedDeploymentInfo.expectedSalt],
          chainId,
        });
        if (!equalAddress(computedFundAddress, fund.expectedDeploymentInfo.expectedComputedAddress)) {
          throw new Error(
            `Fund Deployment failed, computed contract address ${computedFundAddress} does not match expected contract address ${fund.expectedDeploymentInfo.expectedComputedAddress}`,
          );
        }

        return match(token.symbol)
          .with(chainNativeToken.symbol, async () => {
            if (!fund.expectedDeploymentInfo)
              throw new Error('FundDetails does not have expectedDeploymentInfo even though fund is not deployed');

            // Native token can be directly sent, no perms needed
            return writeContractWithIncreasedGas(wagmiConfig, {
              abi: orgFundFactoryAbi,
              functionName: 'deployFundSwapAndDonate',
              address: offAddress,
              account: walletClient.account,
              args: [
                fund.expectedDeploymentInfo.expectedManagerAddress,
                fund.expectedDeploymentInfo.expectedSalt,
                swapInfo.swapWrapper,
                swapInfo.tokenIn,
                swapInfo.amountIn,
                swapInfo.callData as Address,
              ],
              value: swapInfo.amountIn,
              chainId,
            });
          })
          .with(chainStablecoin.symbol, async () => {
            if (!fund.expectedDeploymentInfo)
              throw new Error('FundDetails does not have expectedDeploymentInfo even though fund is not deployed');

            // User needs to give token approval but we do not need to swap
            // Approval is for the OrgFundFactory contract since that is the contract that will execute the donate operation
            await startSpan(
              {
                name: 'Get Token Permission',
              },
              span =>
                getTokenPermission(wagmiConfig, walletClient, offAddress, swapInfo.tokenIn, swapInfo.amountIn, span),
            );
            return writeContractWithIncreasedGas(wagmiConfig, {
              abi: orgFundFactoryAbi,
              functionName: 'deployFundAndDonate',
              address: offAddress,
              account: walletClient.account,
              args: [
                fund.expectedDeploymentInfo.expectedManagerAddress,
                fund.expectedDeploymentInfo.expectedSalt,
                swapInfo.amountIn,
              ],
              chainId,
            });
          })
          .otherwise(async () => {
            if (!fund.expectedDeploymentInfo)
              throw new Error('FundDetails does not have expectedDeploymentInfo even though fund is not deployed');

            // User needs to give token approval and we need to swap
            // Approval is for the OrgFundFactory contract since that is the contract that will execute the donate operation
            await startSpan(
              {
                name: 'Get Token Permission',
              },
              span =>
                getTokenPermission(wagmiConfig, walletClient, offAddress, swapInfo.tokenIn, swapInfo.amountIn, span),
            );
            return writeContractWithIncreasedGas(wagmiConfig, {
              abi: orgFundFactoryAbi,
              functionName: 'deployFundSwapAndDonate',
              address: offAddress,
              account: walletClient.account,
              args: [
                fund.expectedDeploymentInfo.expectedManagerAddress,
                fund.expectedDeploymentInfo.expectedSalt,
                swapInfo.swapWrapper,
                swapInfo.tokenIn,
                swapInfo.amountIn,
                swapInfo.callData as Address,
              ],
              chainId,
            });
          });
      }

      return createDonationTransactionForDeployedEntity(fund.v2ContractAddress);
    })
    .exhaustive();
};

export const useCreateDonation = () => {
  return generateReactionHook<CreateDonationArgs>({
    actionName: 'CREATE_DONATION',
    createTransaction: createDonationTransaction,
    createDescription: () => 'Donating',
    createExtra: ({ args }) => args.donationData,
    confirmations: defaults.confirmations.donation,
  });
};
