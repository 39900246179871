import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import clsx from 'clsx';
import { useState } from 'react';

import { GetFeaturedFunds, GetFeaturedOrgs, GetUserFunds } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { useIsMobile } from '@endaoment-frontend/hooks';
import { useSearch } from '@endaoment-frontend/search';
import type { DonationRecipient } from '@endaoment-frontend/types';
import { CircleIcon, SearchIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';
import { convertUsdcToNumber, getFundTotalValues } from '@endaoment-frontend/utils';

import styles from './DestinationSearch.module.scss';
import { SearchResults } from './SearchResults';

export type SimpleSearchProps = {
  className?: string;
  includeLink?: boolean;
  flow: 'donation' | 'grant';
  defaultIsOrgMode?: boolean;
  onSubmit: (recipient: DonationRecipient, chainId: number) => void;
};

export const DestinationSearch = ({ className, flow, defaultIsOrgMode = true, onSubmit }: SimpleSearchProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOrgMode, setIsOrgMode] = useState(flow === 'grant' ? true : defaultIsOrgMode);
  const { isMobile } = useIsMobile({ defaultState: true });
  const { isSignedIn } = useAuth();

  const featured = {
    orgs: GetFeaturedOrgs.useQuery([]).data ?? [],
    funds: GetFeaturedFunds.useQuery([]).data ?? [],
  };

  const userFunds = GetUserFunds.useQuery([], {
    enabled: isSignedIn,
  });

  featured.funds =
    userFunds.data?.sort((a, b) =>
      convertUsdcToNumber(getFundTotalValues(b).fundBalance - getFundTotalValues(a).fundBalance),
    ) ?? featured.funds;

  const { data, isPending, hasNextPage, fetchNextPage, shouldDisplay } = useSearch(searchTerm);

  return (
    <>
      <InputGroup className={clsx(styles.search, className)}>
        <InputLeftElement>
          {isPending ? (
            <Loader className={styles['search-icon']} />
          ) : (
            <SearchIcon
              className={clsx(styles['search-icon'], styles['search-icon--glass'])}
              width={36}
              color='currentColor'
            />
          )}
        </InputLeftElement>
        <Input
          placeholder={isMobile ? 'Search...' : 'Search all funds and organizations...'}
          onChange={e => setSearchTerm(e.currentTarget.value)}
          value={searchTerm}
          className={styles['search-input']}
          autoFocus={!isMobile}
        />
        {flow === 'donation' && (
          <div
            className={clsx(
              styles['toggle-overlay'],
              isMobile && styles['toggle-overlay--compressed'],
              styles['filter-buttons'],
            )}>
            <Button
              variation='org'
              size='small'
              filled={isOrgMode}
              onClick={() => setIsOrgMode(true)}
              disabled={featured.orgs.length === 0}
              float={false}
              data-testid='org-toggle-button'
              className={styles['toggle-button']}>
              <CircleIcon />
              Orgs&nbsp;
            </Button>
            <Button
              variation='fund'
              size='small'
              filled={!isOrgMode}
              onClick={() => setIsOrgMode(false)}
              disabled={featured.funds.length === 0}
              float={false}
              data-testid='fund-toggle-button'
              className={styles['toggle-button']}>
              <StarIcon />
              Funds&nbsp;
            </Button>
          </div>
        )}
      </InputGroup>
      {shouldDisplay ? (
        <SearchResults
          flow={flow}
          data={data}
          isPending={isPending}
          mode={isOrgMode ? 'org' : 'fund'}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          onSubmit={onSubmit}
          searchTerm={searchTerm}
        />
      ) : (
        <SearchResults
          flow={flow}
          data={featured}
          isPending={false}
          mode={isOrgMode ? 'org' : 'fund'}
          hasNextPage={false}
          fetchNextPage={() => {
            return;
          }}
          onSubmit={onSubmit}
          searchTerm={searchTerm}
        />
      )}
    </>
  );
};
