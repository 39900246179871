import { Text } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { Ref } from 'react';
import { forwardRef, useReducer, useState } from 'react';
import { useAsync } from 'react-use';
import { P, match } from 'ts-pattern';
import { useConfig } from 'wagmi';

import {
  CreateGrantRecommendation,
  GetFund,
  GetOrg,
  GetRecommendationsForFund,
  GetRecommendationsMadeByMe,
} from '@endaoment-frontend/api';
import { useCreateGrant } from '@endaoment-frontend/blockchain-interactions';
import { defaults } from '@endaoment-frontend/config';
import { useIdempotencyKey } from '@endaoment-frontend/hooks';
import { ensureUserChain } from '@endaoment-frontend/multichain';
import { CalculateEntityRebalance, useRebalanceFund } from '@endaoment-frontend/target-allocations';
import type { DonationRecipient, GrantInstructionInput, UUID } from '@endaoment-frontend/types';
import { Loader, StepModal } from '@endaoment-frontend/ui/shared';
import { useShowFireworks } from '@endaoment-frontend/ui/smart';
import { convertUsdcToNumber, isViewableBlockchainStatus } from '@endaoment-frontend/utils';

import type { SubformProps } from '../DonationWizard.types';
import { complexRoundUp } from '../helpers';

import { GrantAmountStep } from './GrantAmount';
import { GrantConfirmationStep } from './GrantConfirmation';
import { GrantInstructionsStep } from './GrantInstructions';
import { GrantOriginatingStep } from './GrantOrigination';
import { ViewGrant } from './ViewGrant';

const grantSteps = ['amount', 'confirmation', 'instructions', 'originating', 'view'] as const;
const grantPages = grantSteps.length + 1;
type GrantStep = (typeof grantSteps)[number];
const transferFeePercent = BigInt(1000 - defaults.fees.transferBps / 10);

const useGrantFlowState = ({ rawState, rawStateSetters }: Pick<SubformProps, 'rawState' | 'rawStateSetters'>) => {
  const [step, setStep] = useReducer((_prev: GrantStep, next: GrantStep) => {
    trackEvent({ event: 'dw_wizard_progress', data: { dw_wizard_step: next, dw_wizard_mode: rawState.mode } });
    return next;
  }, 'originating');
  const [offsetAmount, setOffsetAmount] = useState<bigint>();

  return {
    wizard: {
      step,
      originFundId: rawState.originId,
      recipient: rawState.recipient,
      amount: rawState.amount,
      offsetAmount,
      instructions: rawState.grantInstructions,
      isRebalanceRequested: rawState.isRebalanceRequested,
      recommendationId: rawState.recommendationId,
    },
    setStep,
    setOriginFundId: rawStateSetters.setGrantOriginId,
    setRecipient: rawStateSetters.setRecipient,
    setAmount: rawStateSetters.setAmount,
    setOffsetAmount,
    setInstructions: rawStateSetters.setGrantInstructions,
    setIsRebalanceRequested: rawStateSetters.setIsRebalanceRequested,
  } as const;
};

type FinalGrantFlowState = {
  originFundId: UUID;
  recipient: DonationRecipient;
  amount: bigint;
  offsetAmount?: bigint;
  instructions: GrantInstructionInput;
  recommendationId?: UUID;
};
const mapFlowStateToGrantArgs = async ({
  originFundId,
  amount,
  instructions,
  recipient,
  offsetAmount,
  recommendationId,
}: FinalGrantFlowState): Promise<Parameters<ReturnType<typeof useCreateGrant>['execute']>[0]> => {
  const subprojectId = recipient.type === 'org' ? recipient.subprojectId : undefined;

  return {
    fundId: originFundId,
    destination: recipient,
    grantAmount: offsetAmount ?? amount,
    input: {
      ...instructions,
      subprojectId,
      recommendationId,
    },
  };
};

const GrantFlowWithRef = ({ onClose, onReset, rawState, rawStateSetters }: SubformProps, ref?: Ref<HTMLDivElement>) => {
  const queryClient = useQueryClient();
  const wagmiConfig = useConfig();
  const flowState = useGrantFlowState({
    rawState,
    rawStateSetters,
  });

  const {
    execute: createGrant,
    status: createGrantStatus,
    transactionHash: createGrantHash,
    transactionChainId: creatGrantChainId,
  } = useCreateGrant();
  const { execute: rebalanceFund, status: rebalanceTransactionStatus } = useRebalanceFund();
  // Key used to prevent duplicate recommendations, generate once per flow
  const idempotencyKey = useIdempotencyKey();
  const { mutate: createRecommendation, status: recommendStatus } = useMutation({
    mutationKey: CreateGrantRecommendation.prefixKeys,
    mutationFn: (input: Parameters<typeof CreateGrantRecommendation.execute>[0]) =>
      CreateGrantRecommendation.execute(input),
    onSuccess: (_data, input) => {
      GetRecommendationsForFund.invalidateQuery(queryClient, [input.collaboratingFundId]);
      GetRecommendationsMadeByMe.invalidateQuery(queryClient, []);

      trackEvent({
        event: 'dw_grant_recommendation',
        data: {
          grant_origin_id: input.collaboratingFundId,
          destination_id: input.orgId,
        },
      });

      setTimeout(() => onClose(), 2 * 1000);
    },
  });

  const calculatedOffsetFeeAmount = complexRoundUp(((flowState.wizard.amount ?? 0n) / transferFeePercent) * 1000n, {
    forceRoundUp: true,
  });

  const handleOffsetFees = () => {
    if (!flowState.wizard.offsetAmount && calculatedOffsetFeeAmount) {
      flowState.setOffsetAmount(calculatedOffsetFeeAmount);
    } else flowState.setOffsetAmount(undefined);
  };

  const handleInitiateTransaction = async (finalFlowState: FinalGrantFlowState) => {
    const originFund = await GetFund.fetchFromDefaultClient([finalFlowState.originFundId]);
    const args = await mapFlowStateToGrantArgs(finalFlowState);

    if (
      args.destination.type === 'fund' &&
      originFund.chainId !== (await GetFund.fetchFromDefaultClient([args.destination.id])).chainId
    )
      throw new Error('Cannot donate to a fund on a different chain');

    try {
      await ensureUserChain(wagmiConfig, originFund.chainId);

      createGrant(args);

      // Clear dataLayer before sending ecommerce values
      trackEvent({ data: { ecommerce: null } });
      trackEvent({
        event: 'dw_grant',
        data: {
          grant_origin_id: args.fundId,
          destination_id: args.destination.type === 'fund' ? args.destination.id : args.destination.einOrId,
          ecommerce: {
            currency: 'USD',
            value: convertUsdcToNumber(args.grantAmount, true),
            transaction_id: createGrantHash,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const handleInitiateRebalance = async () => {
    if (
      !flowState.wizard.recipient ||
      flowState.wizard.recipient.type !== 'fund' ||
      !flowState.wizard.isRebalanceRequested
    ) {
      console.error('Attempting to rebalance without the necessary data');
      return;
    }

    // Wait for the donation to be completed before rebalancing the fund
    if (createGrantStatus !== 'success') return;

    GetFund.invalidateQuery(queryClient, [flowState.wizard.recipient.id]);
    const fund = await GetFund.fetchFromDefaultClient([flowState.wizard.recipient.id]);
    await ensureUserChain(wagmiConfig, fund.chainId);

    try {
      rebalanceFund({ fundId: fund.id });

      trackEvent({
        event: 'dw_rebalance',
        data: {
          chainId: fund.chainId,
          destination_type: 'fund',
          destination_id: fund.id,
        },
      });
    } catch (e) {
      if (e instanceof Error && e.message === 'No valid rebalance operations to execute') {
        flowState.setIsRebalanceRequested(false);
      }
      console.error(e);
    }
  };
  const showFireworks = useShowFireworks();
  useAsync(async () => {
    if (!flowState.wizard.recipient) return;
    if (flowState.wizard.recipient.type === 'fund' && flowState.wizard.isRebalanceRequested) {
      if (isViewableBlockchainStatus(rebalanceTransactionStatus)) {
        flowState.setStep('view');
        if (rebalanceTransactionStatus === 'success') {
          showFireworks();
        }
      }
      // When a grant completes, check if a rebalance is necessary
      if (createGrantStatus === 'success') {
        const ops = await CalculateEntityRebalance.fetchFromDefaultClient([
          'fund',
          flowState.wizard.recipient.id,
          false,
        ]);
        if (ops.length > 0) return;

        // If no rebalance operations are necessary, move to the view step
        flowState.setIsRebalanceRequested(false);
        flowState.setStep('view');
        return;
      }
      return;
    }

    if (isViewableBlockchainStatus(createGrantStatus)) {
      flowState.setStep('view');
      if (createGrantStatus === 'success') {
        showFireworks();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGrantStatus, rebalanceTransactionStatus, flowState.wizard.isRebalanceRequested]);

  const steps = match(flowState.wizard)
    .with({ step: 'originating' }, ({ originFundId }) => {
      if (originFundId) {
        flowState.setStep('amount');

        return (
          <StepModal.Step
            key='originating'
            ref={ref}
            onClose={onClose}
            onBack={onReset}
            progress={{ current: 3, pages: grantPages }}
            header='New Grant'>
            <Loader size='l' />
          </StepModal.Step>
        );
      }

      return (
        <StepModal.Step
          key='originating'
          ref={ref}
          onClose={onClose}
          onBack={onReset}
          progress={{ current: 3, pages: grantPages }}
          header='New Grant'>
          <GrantOriginatingStep
            onClose={onClose}
            onSubmit={async fund => {
              try {
                await ensureUserChain(wagmiConfig, fund.chainId);

                flowState.setOriginFundId(fund.id);
                flowState.setStep('amount');
              } catch (e) {
                console.error(e);
                return;
              }
            }}
          />
        </StepModal.Step>
      );
    })
    .with(
      { step: 'amount', originFundId: P.nonNullable, recipient: P.nonNullable },
      ({ originFundId, recipient, amount }) => {
        const handleBack = () => {
          flowState.setOriginFundId(undefined);
          flowState.setStep('originating');
        };

        return (
          <StepModal.Step
            key='amount'
            ref={ref}
            onClose={onClose}
            header='New Grant'
            progress={{ current: 4, pages: grantPages }}
            onBack={handleBack}>
            <GrantAmountStep
              onSubmit={a => {
                flowState.setAmount(a);
                flowState.setStep('instructions');
              }}
              originFundId={originFundId}
              destination={recipient}
              initialValues={{ amount: amount ?? 0n }}
              onRemoveOrigin={handleBack}
              // TODO: `onReset` does not do the proper job of resetting to pick another destination, given it users DonatWizard's `onReset`, which takes to the `type` step
              onRemoveDestination={onReset}
            />
          </StepModal.Step>
        );
      },
    )
    .with({ step: 'instructions', originFundId: P.nonNullable }, ({ instructions, originFundId }) => (
      <StepModal.Step
        key='instructions'
        ref={ref}
        onClose={onClose}
        progress={{ current: 5, pages: grantPages }}
        header='New Grant'
        onBack={() => {
          flowState.setStep('amount');
        }}>
        <GrantInstructionsStep
          initialValues={instructions}
          onSubmit={i => {
            flowState.setInstructions(i);
            flowState.setOffsetAmount(undefined);
            flowState.setStep('confirmation');
          }}
          originFundId={originFundId}
        />
      </StepModal.Step>
    ))
    .with(
      {
        step: 'confirmation',
        originFundId: P.nonNullable,
        recipient: P.nonNullable,
        instructions: P.nonNullable,
      },
      ({ originFundId, recipient, instructions, amount, offsetAmount, isRebalanceRequested }) => (
        <StepModal.Step
          key='confirmation'
          ref={ref}
          onClose={onClose}
          onBack={() => flowState.setStep('instructions')}
          header='New Grant'
          progress={{ current: 6, pages: grantPages }}>
          <GrantConfirmationStep
            originFundId={originFundId}
            destination={recipient}
            grantAmount={offsetAmount ?? amount}
            grantInstructions={instructions}
            feeIsOffset={!!offsetAmount}
            calculatedOffsetFeeAmount={calculatedOffsetFeeAmount}
            transactionStatus={createGrantStatus}
            rebalanceTransactionStatus={rebalanceTransactionStatus}
            onOffsetFees={handleOffsetFees}
            onSubmit={() => handleInitiateTransaction({ originFundId, recipient, amount, offsetAmount, instructions })}
            onStartRebalance={handleInitiateRebalance}
            onRemoveDestination={onReset}
            onRemoveOrigin={() => flowState.setStep('originating')}
            isRebalanceRequested={isRebalanceRequested}
            onChangeRebalanceRequested={flowState.setIsRebalanceRequested}
            onRecommend={async () => {
              if (!originFundId || !recipient || recipient.type === 'fund') return;

              // TODO: See if we can change the API type to accept einOrId
              const org = await GetOrg.fetchFromDefaultClient([recipient.einOrId]);

              createRecommendation({
                collaboratingFundId: originFundId,
                amountUsdc: amount,
                orgId: org.id,
                subprojectId: recipient.subprojectId,
                purpose: instructions.purpose,
                recommender: instructions.recommender,
                shareMyEmail: instructions.shareMyEmail,
                specialInstructions: instructions.specialInstructions,
                uuid: idempotencyKey,
                // TODO: Confirm extraneous fields
                offsetFee: false,
              });
            }}
            recommendStatus={recommendStatus}
          />
        </StepModal.Step>
      ),
    )
    .with(
      { step: 'view', originFundId: P.nonNullable, recipient: P.nonNullable, instructions: P.nonNullable },
      ({ originFundId, recipient, instructions, amount, offsetAmount }) => (
        <StepModal.Step key='view' ref={ref} onClose={onClose} header='Grant'>
          <ViewGrant
            originFundId={originFundId}
            destination={recipient}
            grantAmount={offsetAmount ?? amount}
            grantInstructions={instructions}
            grantStatus={createGrantStatus}
            transactionHash={createGrantHash}
            transactionChainId={creatGrantChainId}
          />
        </StepModal.Step>
      ),
    )
    .otherwise(wizard => {
      console.error('Unhandled grant wizard state', wizard);
      return (
        <StepModal.Step key='error' ref={ref} onClose={onClose} header='Error'>
          <Text alignSelf='center'>Something went wrong</Text>
        </StepModal.Step>
      );
    });
  return steps;
};
export const GrantFlow = forwardRef(GrantFlowWithRef);
