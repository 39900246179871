import { useEffect, useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

type UseEventCallback<T> = (event: CustomEvent<T>) => void;

// This is just a wrapper around CustomEvent, it has horrible typescript support
export class NDAOEventManager<Details = Record<string, unknown>> {
  private name: string;

  constructor(
    name: string,
    private properties?: EventInit,
  ) {
    // Just in case to avoid collisions
    this.name = `ndao:${name}`;
  }

  dispatch(details: Details) {
    return document.dispatchEvent(
      new CustomEvent(this.name, {
        ...this.properties,
        detail: details,
      }),
    );
  }

  addEventListener(callback: (event: CustomEvent<Details>) => void, options?: AddEventListenerOptions) {
    return document.addEventListener(this.name, e => callback(e as CustomEvent<Details>), options);
  }

  removeEventListener(callback: (event: CustomEvent<Details>) => void, options?: EventListenerOptions) {
    return document.removeEventListener(this.name, e => callback(e as CustomEvent<Details>), options);
  }

  useEventListener(callback: UseEventCallback<Details>, options?: AddEventListenerOptions) {
    const fullOptions: AddEventListenerOptions = { passive: true, ...options };
    // Use a ref to prevent the callback from being recreated on every render
    const callbackRef = useRef<UseEventCallback<Details>>(callback);
    useIsomorphicLayoutEffect(() => {
      callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
      this.addEventListener(callbackRef.current, fullOptions);

      return () => this.removeEventListener(callbackRef.current, fullOptions);
    }, Object.values(fullOptions));
  }
}
