import type { User, Wallet } from '@privy-io/react-auth';

import { useAuth } from './useAuth';

/**
 * Wallet client type is privy for social auth
 */
export const isUserWalletSocial = (wallet: Wallet): boolean => wallet.walletClientType === 'privy';

export const extractIsSocialFromUser = (user: User | undefined): boolean | undefined => {
  if (!user || !user.wallet) return undefined;
  return isUserWalletSocial(user.wallet);
};

export const useAuthType = (): {
  authType: 'social' | 'wallet' | undefined;
  isWalletAuth: boolean;
  isSocialAuth: boolean;
} => {
  try {
    const { isSignedIn, user } = useAuth();
    const isSocialAuth = extractIsSocialFromUser(user);

    if (!isSignedIn || typeof isSocialAuth === 'undefined') {
      return {
        authType: undefined,
        isWalletAuth: false,
        isSocialAuth: false,
      };
    }

    return {
      authType: isSocialAuth ? 'social' : 'wallet',
      isWalletAuth: !isSocialAuth,
      isSocialAuth,
    };
  } catch (e) {
    // Allow use of this hook outside of a blockchain connected context, defaulting to an unconnected state
    if (
      e instanceof Error &&
      (e.message.includes('`useWallets` was called outside the PrivyProvider component') ||
        e.message.includes('`useConfig` must be used within `WagmiProvider`'))
    )
      return {
        authType: undefined,
        isWalletAuth: false,
        isSocialAuth: false,
      } as const;
    throw e;
  }
};
