import { Link } from '@chakra-ui/next-js';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Skeleton } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import clsx from 'clsx';
import Image from 'next/image';
import { useMemo, useState } from 'react';
import { P, match } from 'ts-pattern';
import { formatUnits } from 'viem';

import { isAdministrativeAccount, useAuthType } from '@endaoment-frontend/authentication';
import type { StoredTransaction, TransactionActionKey } from '@endaoment-frontend/blockchain-interactions';
import { getChainNameForChainId, getTransactionLink } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type {
  Activity,
  ActivitySubject,
  Address,
  AllocationActivity,
  DonationActivity,
  DonationPledgeActivity,
  TransferActivity,
} from '@endaoment-frontend/types';
import { addressSchema } from '@endaoment-frontend/types';
import {
  AwaitingIcon,
  CardIcon,
  ChainIcon,
  CheckmarkIcon,
  ErrorIcon,
  InTransitIcon,
  InfoIcon,
  MinusIcon,
  PlusIcon,
  PrivateActivityIcon,
  ReceiptIcon,
  RoutingIcon,
  StockIcon,
} from '@endaoment-frontend/ui/icons';
import { Button, Card, EmptyStateBlock, Loader, Pill, Tooltip } from '@endaoment-frontend/ui/shared';
import { capitalize, formatCurrency, formatDate, formatUsdc } from '@endaoment-frontend/utils';

import { ActivityExport } from './ActivityExport';
import { AddressPill, EntityLabelPill, FundPill, PortfolioPill } from './ActivityPills';
import styles from './ActivitySection.module.scss';
import { ReceiptModal } from './ReceiptModal';

const VIEW_MONTH_INCREMENT = 4;
const DONATION_ACTIVITY_TYPES: Array<UserActivity['type']> = [
  'donation',
  'fiat_donation_pledge',
  'migration_donation_pledge',
  'nec_donation_pledge',
  'stock_donation_pledge',
];

export type TransactionAsActivity = Omit<StoredTransaction, 'failCount' | 'type'> & {
  transactionType: TransactionActionKey;
  type: 'transaction';
  occurredAtUtc: string;
  transactor: Address;
  transactionHash: Address;
  automated: false;
};
export type UserActivity = Activity | TransactionAsActivity;

export const batchByMonth = (activities: Array<UserActivity>) => {
  const groupedByMonth = activities
    .sort((a1, a2) => (a1.occurredAtUtc > a2.occurredAtUtc ? -1 : 1))
    .reduce(
      (acc, activity) => {
        const date = new Date(activity.occurredAtUtc);
        const month = date.getMonth();
        const monthLong = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        const key = `${year}-${month}`;

        if (!acc[key]) {
          acc[key] = { date: `${monthLong} ${year}`, activities: [] };
        }

        acc[key].activities.push(activity);

        return acc;
      },
      {} as Record<string, { date: string; activities: Array<UserActivity> }>,
    );

  return Object.entries(groupedByMonth).map(v => v[1]);
};

export const MatchedActivity = ({ activity, subject }: { activity: UserActivity; subject?: ActivitySubject }) =>
  match(activity)
    .with({ type: 'donation' }, activity => <RecentDonation subject={subject} activity={activity} />)
    .with({ type: P.union('grant', 'internal_transfer') }, activity => (
      <RecentTransfer subject={subject} activity={activity} />
    ))
    .with({ type: 'portfolio_trade' }, activity => <RecentAllocation subject={subject} activity={activity} />)
    .with(
      {
        type: P.union(
          'fiat_donation_pledge',
          'migration_donation_pledge',
          'nec_donation_pledge',
          'stock_donation_pledge',
        ),
      },
      activity => <RecentDonationPledge subject={subject} activity={activity} />,
    )
    .with({ type: 'transaction' }, activity => <TransactionItem activity={activity} />)
    .with({ type: 'custom' }, activity => <span>{activity.content}</span>)
    .exhaustive();

type ActivityFilterType = 'all' | 'donations' | 'grants' | 'portfolios';

const ACTIVITY_TYPE_OPTIONS = {
  all: { value: 'all', label: 'All Activity' },
  donations: { value: 'donations', label: 'Donations' },
  grants: { value: 'grants', label: 'Grants' },
  portfolios: { value: 'portfolios', label: 'Portfolio Actions' },
} as const satisfies Record<ActivityFilterType, { value: ActivityFilterType; label: string }>;

const filterActivities = (
  activities: Array<UserActivity>,
  filter: ActivityFilterType,
  subject?: ActivitySubject,
): Array<UserActivity> => {
  if (filter === 'all') return activities;

  return activities.filter(activity =>
    match(filter)
      .with('grants', () => activity.type === 'internal_transfer' || activity.type === 'grant')
      .with('portfolios', () => activity.type === 'portfolio_trade')
      .otherwise(() => DONATION_ACTIVITY_TYPES.includes(activity.type)),
  );
};

export const ActivitySection = ({
  activities = [],
  subject,
  isLoading = false,
  className,
}: {
  activities?: Array<UserActivity>;
  subject?: ActivitySubject;
  isLoading?: boolean;
  className?: string;
}) => {
  const [activityViewMonths, setActivityViewMonths] = useState(VIEW_MONTH_INCREMENT);
  const [receiptActivity, setReceiptActivity] = useState<Activity>();

  const { isSocialAuth } = useAuthType();

  const [activityTypeFilter, setActivityTypeFilter] = useState<ActivityFilterType>('all');
  const [yearFilter, setYearFilter] = useState<number>();
  const availableActivityTypeOptions: Array<{ value: ActivityFilterType; label: string }> =
    subject === 'org'
      ? [ACTIVITY_TYPE_OPTIONS.all, ACTIVITY_TYPE_OPTIONS.donations, ACTIVITY_TYPE_OPTIONS.grants]
      : [
          ACTIVITY_TYPE_OPTIONS.all,
          ACTIVITY_TYPE_OPTIONS.donations,
          ACTIVITY_TYPE_OPTIONS.grants,
          ACTIVITY_TYPE_OPTIONS.portfolios,
        ];

  const activityYears = activities
    .reduce<Array<number>>((acc, activity) => {
      const date = new Date(activity.occurredAtUtc);
      const year = date.getFullYear();
      if (!acc.includes(year)) acc.push(year);
      return acc;
    }, [])
    .sort((y1, y2) => y2 - y1);

  const timeFilterOptions = [
    { value: undefined, label: 'All Dates' },
    ...activityYears.map(year => ({ value: year, label: year.toString() })),
  ];

  const filteredByType = filterActivities(activities, activityTypeFilter, subject);

  const filteredActivitiesByYear = useMemo(() => {
    if (!yearFilter) return filteredByType;

    return filteredByType.filter(activity => {
      const date = new Date(activity.occurredAtUtc);
      return date.getFullYear() === yearFilter;
    });
  }, [yearFilter, filteredByType]);

  const { activitiesByMonth, totalActivityMonths } = useMemo(() => {
    const batched = batchByMonth(filteredActivitiesByYear);
    const viewableSlice = batched.slice(0, Math.min(activityViewMonths, filteredActivitiesByYear.length));
    return {
      activitiesByMonth: viewableSlice,
      totalActivityMonths: batched.length,
    };
  }, [filteredActivitiesByYear, activityViewMonths]);

  return (
    <>
      <ReceiptModal activity={receiptActivity} onClose={() => setReceiptActivity(undefined)} />
      <Card className={clsx(styles['container'], className)} mobileFullWidth>
        <div className={styles['title']}>
          <div>
            <h2>Activity</h2>
            <ActivityExport activities={filteredActivitiesByYear} />
          </div>

          <div>
            {timeFilterOptions.length > 2 && (
              <Select
                key='Time Filter'
                options={timeFilterOptions}
                onChange={o => setYearFilter(o?.value)}
                defaultValue={timeFilterOptions[0]}
                isDisabled={isLoading}
                isSearchable={false}
              />
            )}

            <Select
              key='Activity Type Filter'
              options={availableActivityTypeOptions}
              onChange={newValue => {
                newValue && setActivityTypeFilter(newValue?.value);
              }}
              defaultValue={{
                value: activityTypeFilter,
                label: availableActivityTypeOptions.find(option => option.value === activityTypeFilter)?.label,
              }}
              isDisabled={isLoading}
              isSearchable={false}
            />
          </div>
        </div>
        {match({ activitiesByMonth, isLoading })
          .with({ isLoading: true }, () => (
            <div className={styles['month-block']}>
              <h6>
                <Skeleton data-testid='skeleton-1' minHeight={5} w={12} />
              </h6>
              <Box as='ul' listStyleType='none'>
                <li>
                  <Skeleton
                    data-testid='skeleton-2'
                    minHeight={8}
                    mt='1rem'
                    mb='0.75rem'
                    w='100%'
                    borderRadius='0.5rem'
                  />
                  <Skeleton data-testid='skeleton-3' minHeight={8} w='100%' borderRadius='0.5rem' />
                </li>
              </Box>
            </div>
          ))
          .with({ activitiesByMonth: P.select(P.not(P.union(P.nullish, []))) }, activitiesByMonth => (
            <>
              <Accordion allowMultiple defaultIndex={[0]}>
                {activitiesByMonth.map(byMonth => (
                  <AccordionItem
                    key={byMonth.date}
                    className={styles['month-block']}
                    border='none'
                    data-testid={`activity month - ${byMonth.date}`}>
                    {({ isExpanded }) => (
                      <>
                        <h6 className={clsx(isExpanded && styles['expanded'])}>
                          <AccordionButton
                            as={Flex}
                            p={0}
                            justifyContent='space-between'
                            alignItems='center'
                            data-testid='accordion-button'>
                            <Flex alignItems='center' gap='0.5rem'>
                              {`${byMonth.date} `}
                              <Pill size='tiny' variation='purple'>
                                {byMonth.activities.length}
                              </Pill>
                            </Flex>
                            {isExpanded ? (
                              <MinusIcon width='1.25rem' height='1.25rem' strokeWidth={3} />
                            ) : (
                              <PlusIcon width='1.25rem' height='1.25rem' strokeWidth={3} />
                            )}
                          </AccordionButton>
                        </h6>
                        <AccordionPanel as='ul' p={0} listStyleType='none'>
                          {byMonth.activities.map((activity, index) => (
                            <li
                              key={activity.occurredAtUtc + activity.type + index}
                              className={styles['activity-item']}
                              data-testid='activity-list-item'>
                              <ActivityStatus activity={activity} subject={subject} />
                              <MatchedActivity activity={activity} subject={subject} />
                              <div>
                                {'automated' in activity && !!activity.automated && (
                                  <Tooltip
                                    content='This activity was automatically generated by Endaoment'
                                    as='span'
                                    className={styles['automated-tooltip']}>
                                    Automated
                                    <InfoIcon color='currentColor' width='14px' />
                                  </Tooltip>
                                )}
                                {(subject === 'fund' || subject === 'org') &&
                                  isActivityPledge(activity) &&
                                  (activity.outcome === 'AwaitingAssets' || activity.outcome === 'Pending') && (
                                    <Tooltip content='This pledge is only viewable by you until the assets are received.'>
                                      <PrivateActivityIcon />
                                    </Tooltip>
                                  )}
                                {(subject === 'user' ||
                                  (subject !== 'featured' &&
                                    isActivityPledge(activity) &&
                                    (activity.outcome === 'AwaitingAssets' || activity.outcome === 'Pending'))) &&
                                  activity.type !== 'transaction' &&
                                  activity.type !== 'portfolio_trade' && (
                                    <div
                                      onClick={() => setReceiptActivity(activity)}
                                      className={styles['receipt-button']}
                                      role='button'
                                      title='Receipt'>
                                      <Tooltip content='View receipt'>
                                        <ReceiptIcon />
                                      </Tooltip>
                                    </div>
                                  )}
                                {'chainId' in activity &&
                                  !!activity.chainId &&
                                  !!activity.transactionHash &&
                                  !isSocialAuth && (
                                    <a
                                      target='_blank'
                                      href={getTransactionLink(activity.transactionHash, activity.chainId)}
                                      rel='noreferrer'
                                      className={styles['chain-link']}>
                                      <Tooltip
                                        content={`This activity occurred on ${getChainNameForChainId(
                                          activity.chainId,
                                        )}. Click to view in explorer.`}
                                        placement='left'>
                                        <ChainIcon chainId={activity.chainId} filled light />
                                      </Tooltip>
                                    </a>
                                  )}
                                <Pill
                                  className={styles['date-pill']}
                                  size='tiny'
                                  title={formatDate(activity.occurredAtUtc, { dateStyle: 'long' })}>
                                  {formatDate(activity.occurredAtUtc, { dateStyle: 'short' })}
                                </Pill>
                              </div>
                            </li>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
              <Flex alignItems='center' gap='0' justifyContent='center'>
                {activityViewMonths < totalActivityMonths && (
                  <Button
                    className={styles['see-more']}
                    filled
                    variation='purple'
                    size='small'
                    float={false}
                    onClick={() => setActivityViewMonths(prev => prev + VIEW_MONTH_INCREMENT)}>
                    See More
                  </Button>
                )}
                {activityViewMonths > VIEW_MONTH_INCREMENT && (
                  <Button
                    className={styles['see-more']}
                    variation='purple'
                    size='small'
                    float={false}
                    onClick={() => setActivityViewMonths(prev => prev - VIEW_MONTH_INCREMENT)}>
                    See Less
                  </Button>
                )}
              </Flex>
            </>
          ))
          .otherwise(() => (
            <EmptyStateBlock
              variation='activity'
              title={match(activityTypeFilter)
                .with('portfolios', () => {
                  return 'No recent portfolio activity';
                })
                .with('grants', () => {
                  return 'No recent grant activity';
                })
                .with('donations', () => {
                  return 'No recent donation activity';
                })
                .otherwise(() => {
                  return 'No recent activity';
                })}
              description='Recent activity will show up here.'
              spread>
              <Button as={Link} href={routes.app.explore()} variation='org' filled size='medium'>
                Explore
              </Button>
            </EmptyStateBlock>
          ))}
      </Card>
    </>
  );
};

export const isActivityPledge = (activity: UserActivity): activity is DonationPledgeActivity =>
  activity.type === 'fiat_donation_pledge' ||
  activity.type === 'migration_donation_pledge' ||
  activity.type === 'nec_donation_pledge' ||
  activity.type === 'stock_donation_pledge';

const ActivityStatus = ({ activity, subject }: { activity: UserActivity; subject?: ActivitySubject }) => {
  return match({ activity, subject })
    .returnType<JSX.Element>()
    .with({ activity: { type: 'portfolio_trade', outcome: 'InTransit' } }, () => (
      <Tooltip content='This transaction is in transit, it might require 24-48 hours for processing.' as='em'>
        <InTransitIcon className={clsx(styles['activity-status'], styles['activity-status--transit'])} />
      </Tooltip>
    ))
    .with({ subject: 'user', activity: { type: 'transaction', status: 'pending' } }, () => (
      <Loader size='s' className={clsx(styles['activity-status'], styles['loading'])} />
    ))
    .with({ subject: 'user', activity: { type: 'transaction' } }, () => (
      <ErrorIcon className={clsx(styles['activity-status'], styles['error'])} />
    ))
    .with({ subject: 'user', activity: P.when(isActivityPledge) }, ({ activity }) =>
      match(activity.outcome)
        .returnType<JSX.Element>()
        .with('Failure', () => (
          <ErrorIcon width={18} height={18} className={clsx(styles['activity-status'], styles['error'])} />
        ))
        .with('Pending', () => <Loader size='s' className={clsx(styles['activity-status'], styles['loading'])} />)
        .with('Success', () => (
          <CheckmarkIcon
            className={clsx(styles['activity-status'], styles['success'])}
            strokeWidth={3}
            width={32}
            height={32}
          />
        ))
        .with('AwaitingAssets', () => (
          <Tooltip
            content='We are waiting to receive your assets, view receipt for more info'
            as='b'
            className={clsx(styles['activity-status'], styles['awaiting'])}>
            <AwaitingIcon width={32} />
          </Tooltip>
        ))
        .with('OnRamping', () => (
          <Tooltip
            content='We are in the process of putting funds onchain, view receipt for more info'
            as='b'
            className={clsx(styles['activity-status'], styles['routing'])}>
            <RoutingIcon />
          </Tooltip>
        ))
        .exhaustive(),
    )
    .with({ subject: 'user' }, () => (
      <CheckmarkIcon
        className={clsx(styles['activity-status'], styles['success'])}
        strokeWidth={3}
        width={32}
        height={32}
      />
    ))
    .with({ activity: P.when(isActivityPledge) }, ({ activity }) =>
      match(activity.outcome)
        .returnType<JSX.Element>()
        .with('OnRamping', () => (
          <Tooltip
            content='We are in the process of putting funds onchain'
            as='b'
            className={clsx(styles['activity-status'], styles['routing'])}>
            <RoutingIcon />
          </Tooltip>
        ))
        .with('AwaitingAssets', () => (
          <b className={clsx(styles['activity-status'], styles['awaiting'])}>
            <AwaitingIcon width={32} />
          </b>
        ))
        .with('Pending', () => <Loader size='s' className={clsx(styles['activity-status'], styles['loading'])} />)
        .otherwise(() => <></>),
    )
    .otherwise(() => <></>);
};

const TransactionItem = ({ activity }: { activity: TransactionAsActivity }) => (
  <span
    className={clsx(
      styles['donation-activity'],
      styles['donation-activity--transaction'],
      styles['activity-item__description'],
    )}>
    {activity.description}...
  </span>
);

export const RecentDonation = ({ activity, subject }: { activity: DonationActivity; subject?: ActivitySubject }) => {
  const { token, transactor, to, chainId, amount, usdcAmount } = activity;

  return (
    <span className={clsx(styles['donation-activity'], styles['activity-item__description'])}>
      {subject !== 'user' ? (
        <>
          <AddressPill transactor={transactor} chainId={chainId} />
          <em>donated</em>
        </>
      ) : (
        <em>Donated</em>
      )}
      {!!token && (
        <span>
          {`${formatUnits(amount, token.decimals)} ${token.symbol}`}
          <Image src={token.logoUrl} width={16} height={16} alt='' className={styles['logo-icon']} />
        </span>
      )}
      <b className={styles['money-pill']}>{formatCurrency(formatUsdc(usdcAmount))}</b>
      {(subject === 'featured' || subject === 'user') && (
        <>
          <span>to</span>
          <EntityLabelPill entityLabel={to} />
        </>
      )}
    </span>
  );
};

export const RecentDonationPledge = ({
  activity,
  subject,
}: {
  activity: DonationPledgeActivity;
  subject?: ActivitySubject;
}) => {
  const { transactor, to, chainId, type } = activity;
  const shouldShowTransactor: boolean = !!transactor && !isAdministrativeAccount(transactor, 'accountant') && !!chainId;

  const conjugation = match(activity.outcome)
    .returnType<string>()
    .with('Success', () => 'ed')
    .with('Failure', () => 'e')
    .otherwise(() => 'ing');
  const actionVerb = match({ activity, subject })
    .with({ activity: { type: 'migration_donation_pledge' } }, () => `migrat${conjugation}`)
    .with({ subject: P.not('user') }, () => `receiv${conjugation}`)
    .otherwise(() => `donat${conjugation}`);
  const action = activity.outcome === 'Failure' ? `failed to ${actionVerb}` : `${actionVerb}`;

  return (
    <span className={clsx(styles['donation-activity'], styles['activity-item__description'])}>
      {match({ activity, subject, shouldShowTransactor })
        .with(
          {
            subject: P.not('user'),
            shouldShowTransactor: true,
            activity: {
              transactor: P.not(P.nullish),
              chainId: P.not(P.nullish),
            },
          },
          ({ activity }) => (
            <>
              <AddressPill transactor={activity.transactor} chainId={activity.chainId} />
              <em>{action}</em>
            </>
          ),
        )
        .otherwise(() => (
          <em>{capitalize(action)}</em>
        ))}
      {type === 'nec_donation_pledge' && (
        <span>
          {`${formatUnits(activity.amount, activity.token.decimals)} ${activity.token.symbol}`}
          <Image src={activity.token.logoUrl} width={16} height={16} alt='' className={styles['logo-icon']} />
        </span>
      )}

      {/* TODO: Different activities are getting different logic for usdcAmount/net amounts/fee amounts - these should follow a standard - coordinate w/ BE  */}
      {!!activity.usdcAmount && (
        <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
      )}

      {match(type)
        .with('fiat_donation_pledge', () => (
          <>
            {'via '}
            <b className={clsx(styles['donation-type'], styles['donation-type--cash'])}>
              <CardIcon />
              Cash Donation
            </b>
          </>
        ))
        .with('stock_donation_pledge', () => (
          <>
            {'via '}
            <b className={clsx(styles['donation-type'], styles['donation-type--stock'])}>
              <StockIcon />
              Brokerage
            </b>
          </>
        ))
        .otherwise(() => (
          <></>
        ))}
      {(subject === 'featured' || subject === 'user') && (
        <>
          <span>to</span>
          <EntityLabelPill entityLabel={to} />
        </>
      )}
    </span>
  );
};

export const RecentTransfer = ({ activity, subject }: { activity: TransferActivity; subject?: ActivitySubject }) => {
  // If we are not rendering Org activity and the sender isn't a private fund
  const showSender = subject !== 'org' && !addressSchema.safeParse(activity.from.name).success;
  const grantOrTransferText = activity.type === 'grant' ? 'grant' : 'transfer';

  if (showSender) {
    return (
      <span className={clsx(styles['grant-activity'], styles['activity-item__description'])}>
        <EntityLabelPill entityLabel={activity.from} />
        <em>{`${grantOrTransferText}ed`}</em>
        <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
        <span>to</span>
        <EntityLabelPill entityLabel={activity.to} />
      </span>
    );
  }

  // If we are hiding the sender and we are showing org activity
  if (subject === 'org') {
    return (
      <span className={clsx(styles['grant-activity'], styles['activity-item__description'])}>
        <em>Received</em>
        <span>a</span>
        <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
        <em>{grantOrTransferText}</em>
        from
        <EntityLabelPill entityLabel={activity.from} />
      </span>
    );
  }

  // If we are simply hiding the sender
  return (
    <span className={clsx(styles['grant-activity'], styles['activity-item__description'])}>
      <EntityLabelPill entityLabel={activity.to} />
      <em>received</em>
      <span>a</span>
      <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
      <em>{grantOrTransferText}</em>
      from a
      <FundPill name='Private Fund' />
    </span>
  );
};

export const RecentAllocation = ({
  activity,
  subject,
}: {
  activity: AllocationActivity;
  subject?: ActivitySubject;
}) => {
  const isBuy = activity.tradeType === 'Buy';
  const action = isBuy ? `allocat${activity.outcome === 'InTransit' ? 'ing' : 'ed'}` : 'sold';
  return (
    <span className={clsx(styles['allocation-activity'], styles['activity-item__description'])}>
      {subject !== 'fund' ? (
        <>
          <EntityLabelPill entityLabel={activity.fund} />
          <em>{action}</em>
        </>
      ) : (
        <em>{action.charAt(0).toUpperCase() + action.slice(1)}</em>
      )}
      <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
      &nbsp;{isBuy ? 'to' : 'from'}
      <PortfolioPill portfolio={activity.portfolio} />
    </span>
  );
};
