import { captureException } from '@sentry/nextjs';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { Config, ReadContractParameters } from '@wagmi/core';
import { getBalance, multicall } from '@wagmi/core';
import { erc20Abi } from 'viem';
import { useChainId, useConfig } from 'wagmi';

import { getNativeTokenForChain } from '@endaoment-frontend/multichain';
import type { Address, EVMToken, TokenBalance } from '@endaoment-frontend/types';

type TokenBalanceOptions = Partial<Omit<UseQueryOptions<Array<TokenBalance>>, 'initialData' | 'queryFn' | 'queryKey'>>;

const fetchTokenBalances = async (wagmiConfig: Config, walletAddress?: Address, tokens?: Array<EVMToken>) => {
  if (!walletAddress) throw new Error('Wallet address not provided');
  if (!tokens || tokens.length === 0) return [];

  // We can cheat fetching the chainId from the first token since they're all on the same chain
  const chainId = tokens[0].chainId;
  const nativeToken = getNativeTokenForChain(chainId);

  // Create a list without the Native token since its balance isn't contract based
  const nonNativeTokens = tokens.filter(t => t.contractAddress !== nativeToken.contractAddress);

  // Construct contract calls for Non-Native tokens
  const contracts = tokens.reduce((accum: Array<ReadContractParameters<typeof erc20Abi, 'balanceOf'>>, t) => {
    // Create a list without the Native token since its balance isn't contract based
    if (t.id === nativeToken.id) return accum;
    accum.push({
      address: t.contractAddress,
      abi: erc20Abi,
      functionName: 'balanceOf',
      args: [walletAddress],
    });
    return accum;
  }, []);
  const multicallResponse = contracts.length > 0 ? await multicall(wagmiConfig, { contracts }) : [];
  const tokenBalances = multicallResponse.map<TokenBalance>((res, index) => {
    if (res.status === 'failure') {
      // Send reversion errors to Sentry
      captureException(res.error, {
        extra: { token: nonNativeTokens[index] },
      });
      return { token: nonNativeTokens[index], balance: 0n };
    }

    try {
      return {
        token: nonNativeTokens[index],
        balance: BigInt(res.result),
      };
    } catch (e) {
      // Send failed BigInt conversions to Sentry
      captureException(e, { extra: { token: nonNativeTokens[index], balance: res } });

      // Balances default to 0 so that we can still display the token
      return { token: nonNativeTokens[index], balance: 0n };
    }
  });

  // Add the Native token by getting user's balance directly, if it is in the token list
  if (tokens.find(t => t.id === nativeToken.id)) {
    const nativeTokenBalance = (await getBalance(wagmiConfig, { address: walletAddress })).value || 0n;
    tokenBalances.unshift({
      token: nativeToken,
      balance: nativeTokenBalance,
    });
  }

  return tokenBalances;
};

export const useTokenBalances = (tokens: Array<EVMToken>, walletAddress?: Address, options?: TokenBalanceOptions) => {
  const currentChainId = useChainId();
  const wagmiConfig = useConfig();

  // While we allow informing any tokens, we need to filter them by the current chain, given the upcoming chain-specific queries
  const tokensOnChain = tokens.filter(t => t.chainId === currentChainId);

  return useQuery<Array<TokenBalance>>({
    queryKey: [
      'GetTokenBalances',
      walletAddress,
      tokensOnChain
        .map(t => t.contractAddress)
        .toSorted()
        .join(','),
    ],
    queryFn: () => fetchTokenBalances(wagmiConfig, walletAddress, tokensOnChain),
    placeholderData: v => v,
    ...options,
    enabled: !!walletAddress && (typeof options?.enabled === 'boolean' ? options.enabled : true),
  });
};
