import type { PrivyEvents, User } from '@privy-io/react-auth';
import { getAccessToken } from '@privy-io/react-auth';
import type { Config } from '@wagmi/core';
import { signMessage } from '@wagmi/core';

import { SignIn, SignOut, WhoAmI } from '@endaoment-frontend/api';
import {
  GetPrivyWalletAddressForUser,
  isAdministrativeAccount,
  isUserWalletSocial,
} from '@endaoment-frontend/authentication';
import type { LoginDTO } from '@endaoment-frontend/types';
import { addressSchema } from '@endaoment-frontend/types';
import { equalAddress, makeValidLoginSignature } from '@endaoment-frontend/utils';

type MakeAuthCycleHandlerParams = {
  wagmiConfig: Config;
  signOut: () => Promise<void>;
};
type AuthCycleHandler = (...args: Parameters<NonNullable<PrivyEvents['login']['onComplete']>>) => Promise<void>;
type LoginMethod = NonNullable<Parameters<AuthCycleHandler>[3]>;

const getEmailForSocialLoginMethod = (user: User, loginMethod: LoginMethod): string | undefined => {
  if (!user.wallet) throw new Error('User has no wallet');
  if (loginMethod === 'siwe') throw new Error(`${loginMethod} is not a social method`);
  if (!isUserWalletSocial(user.wallet)) throw new Error('User is not a social wallet');

  const loginMethodToEmail = {
    email: user.email?.address,
    sms: user.email?.address ?? undefined,
    google: user.google?.email,
    apple: user.apple?.email,
    discord: user.discord?.email ?? undefined,
    github: user.github?.email ?? undefined,
    linkedin: user.linkedin?.email ?? undefined,
    spotify: user.spotify?.email ?? undefined,

    // Methods without any email possible
    farcaster: undefined,
    passkey: undefined,
    twitter: undefined,
    tiktok: undefined,
    instagram: undefined,

    // This should never happen
    siwe: undefined,
  } satisfies Record<LoginMethod, string | undefined>;

  const email = loginMethodToEmail[loginMethod];
  if (!email) return undefined;
  return email;
};

export const makeAuthCycleHandler =
  ({ wagmiConfig, signOut }: MakeAuthCycleHandlerParams): AuthCycleHandler =>
  async (user, _isNewUser, wasAlreadyAuthenticated, loginMethod) => {
    if (!user.wallet || !user.wallet.address) {
      console.error('Logged a user in without a wallet');
      return;
    }

    // This can be either a Smart Account or an EOA depending on the authentication method
    const walletAddressToUse = await GetPrivyWalletAddressForUser.executeAndSave([user]);
    if (!walletAddressToUse) throw new Error('No wallet address found');

    // Check if we can early exit if the user is already signed in
    const ndaoToken = await WhoAmI.executeAndSave([]);
    if (wasAlreadyAuthenticated) {
      console.info('User was already authenticated');
      // Early exit when Privy and NDAO are in sync
      if (!!ndaoToken && equalAddress(walletAddressToUse, ndaoToken.wallet)) return;

      console.error('User was already authenticated on Privy but NDAO authentication failed');
      await signOut();
      throw new Error('User was already authenticated on Privy but NDAO authentication failed');
    }

    if (ndaoToken) {
      await SignOut.execute();
    }

    // Get token to send to the BE
    const privyToken = await getAccessToken();
    if (!privyToken) throw new Error('No privy token found');

    try {
      const isSocialLogin = loginMethod !== 'siwe';
      if (isSocialLogin) {
        if (!loginMethod) throw new Error('No login method found');

        // The BE needs to be informed the embedded address of the user separately
        const embeddedAddress = addressSchema.parse(user.wallet.address);
        // The wallet address being used to sign for social users is the smart account address
        const zerodevWalletAddress = walletAddressToUse;

        console.info(
          `Attempting social login for wallet ${embeddedAddress} with smart account ${zerodevWalletAddress}`,
        );
        const isSuccess = await SignIn.execute({
          type: 'social',
          activeWalletAddress: embeddedAddress,
          smartAccountAddress: zerodevWalletAddress,
          privyToken,
          email: getEmailForSocialLoginMethod(user, loginMethod),
        });
        if (!isSuccess) throw new Error('Failed to sign in with social login');
        await WhoAmI.executeAndSave([]);
        return;
      }

      if (isAdministrativeAccount(walletAddressToUse)) {
        // Admins must sign to prove their identity
        const siweMessage = makeValidLoginSignature(walletAddressToUse);
        console.warn(`Attempting to login as admin wallet (${walletAddressToUse})`);
        const signature = await signMessage(wagmiConfig, {
          account: walletAddressToUse,
          message: siweMessage.message,
        });

        const loginDto: LoginDTO = {
          type: 'admin',
          privyToken,
          activeWalletAddress: walletAddressToUse,
          signature,
          signatureDateUtc: siweMessage.date,
        };
        const isSuccess = await SignIn.execute(loginDto);
        if (!isSuccess) throw new Error('Failed to sign in as admin');
        await WhoAmI.executeAndSave([]);
        console.info('Signed in as admin successfully', loginDto);
        return;
      }

      // Default case of wallet login
      console.info(`Attempting wallet login for wallet ${walletAddressToUse}`);
      const isSuccess = await SignIn.execute({
        type: 'wallet',
        activeWalletAddress: walletAddressToUse,
        privyToken,
      });
      if (!isSuccess) throw new Error('Failed to sign in with wallet login');
      await WhoAmI.executeAndSave([]);
      return;
    } catch (e) {
      // We want to forcefully sign out the user if there is an error
      signOut();
      console.error(e);
      throw e;
    }
  };
