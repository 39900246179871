import { useInfiniteQuery } from '@tanstack/react-query';
import { useDebounce } from 'use-debounce';

import { SearchEndaoment } from '@endaoment-frontend/api';
import { DEBOUNCE_MS, TIME_ONE_HOUR_IN_SECONDS, TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';

export type UseSearchOptions = {
  pageSize: number;
  enabled: boolean;
  includeOrgs: boolean;
  includeFunds: boolean;
};

const defaultOptions: UseSearchOptions = {
  pageSize: 10,
  enabled: true,
  includeOrgs: true,
  includeFunds: true,
};

export const useSearch = (searchTerm: string, userOptions?: Partial<UseSearchOptions>) => {
  const [delayedTerm] = useDebounce(searchTerm, DEBOUNCE_MS, { maxWait: 5000 });
  const options = { ...defaultOptions, ...userOptions };

  const shouldDisplay = options.enabled && searchTerm !== '';
  const result = useInfiniteQuery({
    queryKey: [...SearchEndaoment.prefixKeys, delayedTerm, options.includeOrgs, options.includeFunds],
    queryFn: ({ pageParam }) =>
      SearchEndaoment.execute(delayedTerm, {
        fundCount: options.includeFunds ? options.pageSize : 0,
        fundOffset: pageParam,
        orgCount: options.includeOrgs ? options.pageSize : 0,
        orgOffset: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (last, all) =>
      last.orgs.length === options.pageSize || last.funds.length === options.pageSize
        ? all.length * options.pageSize
        : undefined,
    staleTime: 10 * TIME_ONE_MINUTE_IN_SECONDS * 1000, // Considered stale after 10 minutes
    gcTime: TIME_ONE_HOUR_IN_SECONDS * 1000, // Cache for an hour
    retry: 2,
    networkMode: 'offlineFirst',
    enabled: shouldDisplay,
  });

  const data =
    shouldDisplay && result.data
      ? {
          orgs: result.data.pages.map(page => page.orgs).flat(),
          funds: result.data.pages.map(page => page.funds).flat(),
        }
      : {
          orgs: [],
          funds: [],
        };
  const isPending = shouldDisplay && !!searchTerm && (delayedTerm !== searchTerm || result.isPending);

  return {
    ...result,
    data,
    isPending,
    shouldDisplay,
  };
};
