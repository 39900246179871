import { injected } from '@wagmi/core';
import type { QrModalOptions } from '@walletconnect/ethereum-provider/dist/types/types';
import type { CreateConnectorFn } from 'wagmi';
import { coinbaseWallet, safe, walletConnect } from 'wagmi/connectors';

import { WALLET_CONNECT_PROJECT_ID } from '@endaoment-frontend/constants';

type ConnectorOptions = {
  appName: string;
  appDescription: string;
  appUrl: string;
  appLogo: string;
};

export const getConnectors = ({
  appName,
  appDescription,
  appUrl,
  appLogo,
}: ConnectorOptions): Array<CreateConnectorFn> => {
  return [
    injected({
      shimDisconnect: true,
    }),
    safe({
      allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
      shimDisconnect: true,
    }),
    coinbaseWallet({
      appName: appName,
      appLogoUrl: appLogo,
      headlessMode: true,
    }),
    walletConnect({
      projectId: WALLET_CONNECT_PROJECT_ID,
      qrModalOptions: {
        themeVariables: {
          '--w3m-z-index': '2000',
          '--wcm-z-index': '2000',
        } as QrModalOptions['themeVariables'],
      },
      metadata: {
        name: appName,
        description: appDescription,
        url: appUrl,
        icons: [appLogo],
      },
    }),
  ];
};
