import { Link } from '@chakra-ui/next-js';
import { Flex } from '@chakra-ui/react';
import clsx from 'clsx';

import { routes } from '@endaoment-frontend/routes';
import type { FundListing } from '@endaoment-frontend/types';
import { ArrowIcon, QuestionIcon } from '@endaoment-frontend/ui/icons';
import { Button, cardClassNames, Pill, Tooltip } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatNumber, formatUsdc } from '@endaoment-frontend/utils';

import { computeDeviationFromTarget, formatPercentageOfFundRebalancable } from './computeValues';
import styles from './TargetAllocationSection.module.scss';

export const OtherBalanceCard = ({ balance }: { balance: bigint }) => {
  return (
    <div className={clsx(cardClassNames.base, styles['other-allocations'])}>
      <h6>
        <Tooltip content='The amount of money in portfolios not within the target allocation.' placement='top'>
          Other Positions <QuestionIcon width={15} height={16} color='currentColor' />
        </Tooltip>
      </h6>
      <hr />
      <div>
        <span>{formatCurrency(formatUsdc(balance), { compact: true })}</span>
        <Button
          size='small'
          variation='portfolio'
          as={Link}
          href={routes.app.portfolios({ view: 'positions' })}
          filled
          float={false}
          className={styles['other-positions-button']}>
          See Positions
          <ArrowIcon width={16} />
        </Button>
      </div>
    </div>
  );
};

export const GrantableBalanceCard = ({
  fund,
  grantablePercentage,
}: {
  fund: Pick<FundListing, 'investedUsdc' | 'usdcBalance'>;
  grantablePercentage?: number;
}) => {
  const { deviationPercentFromTarget, deviationAmountFromTarget, isWithinTarget } = computeDeviationFromTarget(
    fund.usdcBalance,
    fund,
    { percentage: grantablePercentage ?? 0 },
  );

  const indicatorClass = isWithinTarget ? styles['within-target'] : styles['off-target'];

  return (
    <div className={clsx(cardClassNames.base, styles['grantable-allocation'])}>
      <h6>
        <Tooltip content='The amount of grantable money that is allocated within the target mix.' placement='top'>
          Grantable Balance <QuestionIcon width={15} height={16} color='currentColor' />
        </Tooltip>
      </h6>
      <hr />
      <div>
        <span>
          <b>{formatCurrency(formatUsdc(fund.usdcBalance), { compact: true })}</b>
        </span>
        <span>
          {!!grantablePercentage && (
            <Tooltip
              content={
                isWithinTarget ? (
                  'Grantable balance within target range.'
                ) : (
                  <div>
                    Difference from target:{' '}
                    <b className={indicatorClass}>
                      {deviationPercentFromTarget >= 0 ? '-' : '+'}
                      {formatCurrency(formatUsdc(deviationAmountFromTarget), { compact: true }).replace('-', '')}
                    </b>{' '}
                    (
                    <b className={indicatorClass}>
                      {deviationPercentFromTarget >= 0 ? '-' : '+'}
                      {formatNumber(Math.abs(deviationPercentFromTarget) * 100, {
                        digits: 3,
                        fractionDigits: 2,
                        stripZeros: true,
                      })}
                      %
                    </b>
                    )
                  </div>
                )
              }>
              <Flex alignItems='center' flexDir='row'>
                <Flex alignItems={['stretch', 'center']} flexDir={['column', 'row']}>
                  <Pill size='tiny' className={styles['current-pill']}>
                    Current: {formatPercentageOfFundRebalancable(fund.usdcBalance, fund)}
                  </Pill>
                  <Pill size='tiny' className={styles['target-pill']}>
                    Target: {formatNumber(grantablePercentage * 100, { digits: 3, fractionDigits: 1 })}%
                  </Pill>
                </Flex>
                <div className={clsx(indicatorClass, styles['status'])} />
              </Flex>
            </Tooltip>
          )}
        </span>
      </div>
    </div>
  );
};
