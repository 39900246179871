import { Flex } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useAsync } from 'react-use';
import { match } from 'ts-pattern';

import { GetFund, GetFundPositions, GetPortfoliosAvailableToFund } from '@endaoment-frontend/api';
import { useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import { routes } from '@endaoment-frontend/routes';
import type { EntityPositionSummary, FundDetails, UUID } from '@endaoment-frontend/types';
import { Input } from '@endaoment-frontend/ui/forms';
import { CloseIcon, QuestionIcon, TargetAllocationIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card, DashedBorder, Loader, Pill, StepModal, Tooltip } from '@endaoment-frontend/ui/shared';
import {
  FundAllocationBar,
  MiniFundDetails,
  MiniLoadingDetails,
  MiniPortfolioDetails,
} from '@endaoment-frontend/ui/smart';
import { filterPortfolios } from '@endaoment-frontend/utils';

import { GrantableBalanceCard } from './GrantableBalanceCard';
import { PercentIncrementerInput } from './PercentIncrementerInput';
import styles from './TargetAllocationSection.module.scss';
import { TargetAllocationItem } from './TargetAllocationSectionContent';
import { constrainNumberToIncrement, sortAllocations } from './computeValues';
import { CalculateEntityRebalance, GetTargetAllocations, UpdateTargetAllocations } from './requests';
import type { TargetAllocation } from './types';
import { useTargetAllocationModal } from './useTargetAllocationModal';

export const TargetAllocationModal = () => {
  const { isSignedIn } = useAuth();
  const { showWallet, isWalletModalOpen } = useWalletModal();

  const { isTargetAllocationModalOpen, closeTargetAllocationModal, targetAllocationFundId } =
    useTargetAllocationModal();
  const shouldShowWallet = !isSignedIn && !isWalletModalOpen;
  const isOpen = isTargetAllocationModalOpen && !!targetAllocationFundId && isSignedIn && !isWalletModalOpen;

  const handleClose = () => {
    closeTargetAllocationModal();
  };

  const hasAlreadyReroutedToWallet = useRef(false);
  useAsync(async () => {
    // If the user has already been rerouted to the wallet, close the modal
    if (isTargetAllocationModalOpen && shouldShowWallet && hasAlreadyReroutedToWallet.current) {
      handleClose();
      hasAlreadyReroutedToWallet.current = false;
      return;
    }
    // Guard against rendering if the user is not signed in
    if (isTargetAllocationModalOpen && shouldShowWallet) {
      showWallet();
      hasAlreadyReroutedToWallet.current = true;
      return;
    }
  }, [isTargetAllocationModalOpen, shouldShowWallet]);

  return (
    <StepModal isOpen={isOpen} onClose={handleClose} extraLarge>
      <StepModal.Step onClose={handleClose} header='Target Allocation'>
        {targetAllocationFundId ? (
          <TargetAllocationModalContent onClose={handleClose} targetAllocationFundId={targetAllocationFundId} />
        ) : (
          <Loader size='l' />
        )}
      </StepModal.Step>
    </StepModal>
  );
};

type ConfiguredAllocationsState = {
  configuredAllocations: Array<TargetAllocation>;
  isChangedAllocation: boolean;
};
const configureAllocationsReducer = (
  state: ConfiguredAllocationsState,
  action:
    | { type: 'add'; allocation: TargetAllocation }
    | { type: 'fill'; allocations: Array<TargetAllocation> }
    | { type: 'mark'; isChanged: boolean }
    | { type: 'remove'; portfolioId: UUID }
    | { type: 'update'; portfolioId: UUID; percentage: number },
): ConfiguredAllocationsState =>
  match(action)
    .returnType<ConfiguredAllocationsState>()
    .with({ type: 'fill' }, ({ allocations }) => ({
      configuredAllocations: sortAllocations(allocations),
      isChangedAllocation: false,
    }))
    .with({ type: 'add' }, ({ allocation }) => {
      if (state.configuredAllocations.length >= 5) return state;
      return {
        configuredAllocations: sortAllocations([...state.configuredAllocations, allocation]),
        isChangedAllocation: true,
      };
    })
    .with({ type: 'remove' }, ({ portfolioId }) => ({
      configuredAllocations: state.configuredAllocations.filter(a => a.portfolioId !== portfolioId),
      isChangedAllocation: true,
    }))
    .with({ type: 'update' }, ({ portfolioId, percentage }) => {
      const constrainedPercentage = Math.min(Math.max(constrainNumberToIncrement(percentage, 0.05), 0), 1);
      const newConfiguration = state.configuredAllocations.map(a => {
        if (a.portfolioId === portfolioId) {
          return { ...a, percentage: constrainedPercentage };
        }
        return a;
      });

      return {
        configuredAllocations: sortAllocations(newConfiguration),
        isChangedAllocation: true,
      };
    })
    .with({ type: 'mark' }, ({ isChanged }) => ({
      configuredAllocations: state.configuredAllocations,
      isChangedAllocation: isChanged,
    }))
    .exhaustive();

const TargetAllocationModalContent = ({
  targetAllocationFundId,
  onClose,
}: {
  targetAllocationFundId: UUID;
  onClose: () => void;
}) => {
  const [{ configuredAllocations, isChangedAllocation }, configureAllocations] = useReducer(
    configureAllocationsReducer,
    { configuredAllocations: [], isChangedAllocation: false },
  );
  const queryClient = useQueryClient();
  const { data: fund } = GetFund.useQuery([targetAllocationFundId]);
  const { data: positionSummary } = GetFundPositions.useQuery([targetAllocationFundId, 'accurate']);
  const { data: currentTargetAllocations = [], isSuccess } = GetTargetAllocations.useQuery([
    'fund',
    targetAllocationFundId,
  ]);

  const [showSaveSuccess, setShowSaveSuccess] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (!isSuccess) return;

    configureAllocations({ type: 'fill', allocations: currentTargetAllocations });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (!fund || !isSuccess) {
    return <Loader size='l' />;
  }

  const handleSave = async (fundId: UUID, newAllocations: Array<TargetAllocation>, addAssets: boolean) => {
    newAllocations = newAllocations.filter(a => a.percentage > 0);
    const res = await UpdateTargetAllocations.execute('fund', fundId, newAllocations);
    configureAllocations({ type: 'mark', isChanged: false });
    GetTargetAllocations.setData(queryClient, ['fund', fundId], res);
    CalculateEntityRebalance.invalidateQuery(queryClient, ['fund', fundId, true]);
    CalculateEntityRebalance.invalidateQuery(queryClient, ['fund', fundId, false]);

    setShowSaveSuccess(true);

    if (addAssets) {
      router.push(
        routes.app.fund({
          id: fundId,
          wizardParams: { isDonationWizardOpen: true, dwRecipient: { id: fundId, type: 'fund' } },
        }),
      );
    }
    setTimeout(() => {
      setShowSaveSuccess(false);

      if (!addAssets) {
        onClose();
      }
    }, 1000);
  };

  const isPercentageValid = configuredAllocations.reduce((acc, a) => acc + a.percentage, 0) <= 1;

  return (
    <>
      {!positionSummary ||
        (positionSummary.positions.length === 0 && (
          <div className={styles['allocations__instructions']}>
            <TargetAllocationIcon width={48} height={48} color='currentColor' />
            <div>
              <h5>About Target Allocations</h5>
              Once set up you will be able to{' '}
              <Pill size='tiny' variation='violet'>
                Rebalance
              </Pill>{' '}
              this fund's positions and grantable balance to the target percentages from your fund page. Please note the{' '}
              <Pill size='tiny' variation='violet'>
                Rebalance
              </Pill>{' '}
              will only be available if{' '}
              <Tooltip
                content='Minimum 5% deviation required. Additionally, $100 difference for onchain portfolios and $500 difference for traditional portfolios.'
                className={styles['tooltip']}>
                minimum differences <QuestionIcon color='currentColor' width={16} height={16} />
              </Tooltip>{' '}
              are met.
            </div>
          </div>
        ))}
      <div className={styles['allocations__label-line']}>Fund</div>
      <Card className={styles['fund-card']}>
        <MiniFundDetails fund={fund} padding={false} />
        <FundAllocationBar fund={fund} fundId={fund.id} isAccurate />
      </Card>
      <div className={styles['allocations__label-line']}>Target Allocation Settings</div>
      {!!positionSummary && (
        <TargetAllocationBreakdown
          configuredAllocations={configuredAllocations}
          onChangeAllocationPercent={(portfolioId, percent) =>
            configureAllocations({ type: 'update', portfolioId, percentage: percent })
          }
          onRemoveAllocation={portfolioId => configureAllocations({ type: 'remove', portfolioId })}
          fund={fund}
          positionSummary={positionSummary}>
          <div className={styles['allocations__settings-controls']}>
            <Button
              size='small'
              onClick={() => configureAllocations({ type: 'fill', allocations: currentTargetAllocations })}
              variation='fund'
              disabled={!isChangedAllocation}
              filled
              float={false}>
              Reset
            </Button>
            <Tooltip content={!isPercentageValid && 'Percentages must add up to 100%'} placement='top'>
              <Button
                size='small'
                disabled={!isPercentageValid || !isChangedAllocation}
                filled
                variation='portfolio'
                float={false}
                onClick={() => handleSave(targetAllocationFundId, configuredAllocations, false)}>
                {showSaveSuccess ? 'Saved!' : 'Save'}
              </Button>
            </Tooltip>
            {fund.usdcBalance === 0n && (
              <Tooltip content='Save target allocation and go to add assets to fund.' placement='top'>
                <Button
                  size='small'
                  disabled={!isPercentageValid || !isChangedAllocation}
                  filled
                  variation='portfolio'
                  float={false}
                  onClick={() => handleSave(targetAllocationFundId, configuredAllocations, true)}>
                  {showSaveSuccess ? 'Saved!' : 'Save and Donate Assets'}
                </Button>
              </Tooltip>
            )}
          </div>
        </TargetAllocationBreakdown>
      )}
      {configuredAllocations.length < 5 ? (
        <AddTargetPortfolioSelector
          fundId={targetAllocationFundId}
          fundChainId={fund.chainId}
          onAddPortfolio={portfolioId =>
            configureAllocations({
              type: 'add',
              allocation: { portfolioId, entityId: targetAllocationFundId, entityType: 'fund', percentage: 0.05 },
            })
          }
          configuredAllocations={configuredAllocations}
        />
      ) : (
        <p className={styles['']}>
          There is a maximum of 5 portfolios to a target allocation. Please remove a portfolio to add another!
        </p>
      )}
    </>
  );
};

const TargetAllocationBreakdown = ({
  configuredAllocations,
  onChangeAllocationPercent,
  onRemoveAllocation,
  positionSummary,
  fund,
  children,
}: {
  configuredAllocations: Array<TargetAllocation>;
  onChangeAllocationPercent: (portfolioId: UUID, percent: number) => void;
  onRemoveAllocation: (portfolioId: UUID) => void;
  positionSummary: EntityPositionSummary;
  fund: Pick<FundDetails, 'investedUsdc' | 'usdcBalance'>;
  children?: ReactNode;
}) => {
  const computedGrantablePercent = Math.round(configuredAllocations.reduce((acc, v) => acc - v.percentage, 1) * 100);
  return (
    <div className={styles['allocations__settings']}>
      <DashedBorder />
      <h5>Target Allocation Breakdown</h5>
      {configuredAllocations.length === 0 && (
        <h6>Your allocation breakdown will appear here, select portfolios below.</h6>
      )}
      <div className={styles['allocations__settings-list']}>
        {configuredAllocations.map((a, index) => {
          const currentPosition = positionSummary.positions.find(p => p.portfolio.id === a.portfolioId);
          return (
            <div
              className={clsx(styles['allocation__listing-line'])}
              key={a.portfolioId}
              data-testid={`target-allocation-${a.portfolioId}`}
              data-index={index}>
              <TargetAllocationItem
                allocation={a}
                fund={fund}
                currentPosition={currentPosition}
                hidePercent
                index={index}
              />
              <Flex gap='0.75rem' alignItems='center' justifyContent='flex-end'>
                <PercentIncrementerInput
                  value={a.percentage}
                  onChange={v => onChangeAllocationPercent(a.portfolioId, v)}
                />
                <Button
                  data-testid='remove-portfolio-button'
                  className={styles['remove-button']}
                  float={false}
                  onClick={() => onRemoveAllocation(a.portfolioId)}>
                  <CloseIcon color='currentColor' width={24} />
                </Button>
              </Flex>
            </div>
          );
        })}
        {configuredAllocations.length > 0 && (
          <div
            className={clsx(
              styles['allocation__listing-line'],
              styles['allocation__listing-line--grantable'],
              styles['allocation__listing--grantable'],
            )}>
            <GrantableBalanceCard fund={fund} />
            <span>{computedGrantablePercent > 0 ? computedGrantablePercent : 0}%</span>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

const AddTargetPortfolioSelector = ({
  fundId,
  fundChainId,
  configuredAllocations,
  onAddPortfolio,
}: {
  fundId: UUID;
  fundChainId: number;
  configuredAllocations: Array<TargetAllocation>;
  onAddPortfolio: (portfolioId: UUID) => void;
}) => {
  const [isShown, setIsShown] = useState(true);
  const [search, setSearch] = useState('');

  const { data: portfolios, isPending } = GetPortfoliosAvailableToFund.useQuery([fundId]);
  const configuredPortfolioIds = configuredAllocations.map(a => a.portfolioId);
  // Note that we are showing portfolios that exceed their cap
  const filteredPortfolios = filterPortfolios(search, fundChainId, portfolios, true)
    // Filter out portfolios that are already configured
    .filter(p => !configuredPortfolioIds.includes(p.id));

  return (
    <div className={styles['select-section']}>
      <div className={styles['select-section__header']}>
        <h5>Select up to five portfolios</h5>
        <Button size='small' onClick={() => setIsShown(v => !v)} variation='purple' faded float={false}>
          {isShown ? 'Hide' : 'Show'}
        </Button>
      </div>
      {match({ isPending, isShown })
        .with({ isPending: true, isShown: true }, () => <MiniLoadingDetails />)
        .with({ isPending: false, isShown: true }, () => (
          <>
            <Input
              type='text'
              placeholder='Filter Portfolios'
              value={search}
              onChange={e => setSearch(e.currentTarget.value)}
            />
            <div className={styles['select-section__list']}>
              {filteredPortfolios.map(p => (
                <Card noPadding key={p.id} noShadow>
                  <MiniPortfolioDetails
                    portfolio={p}
                    onClick={() => {
                      onAddPortfolio(p.id);
                      setSearch('');
                    }}
                  />
                </Card>
              ))}
            </div>
          </>
        ))
        .otherwise(() => (
          <></>
        ))}
    </div>
  );
};
