import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Button, useDisclosure } from '@chakra-ui/react';
import clsx from 'clsx';
import type { Chain } from 'viem';
import { useChainId, useSwitchChain } from 'wagmi';

import { ChainIcon, DownCaretIcon } from '@endaoment-frontend/ui/icons';

import { getChainNameForChainId } from '../getDefaults';

import styles from './NetworkSwitcher.module.scss';

const HEIGHT = 28;

export const NetworkSwitcher = ({ hideStatus, className }: { hideStatus?: boolean; className?: string }) => {
  const { chains, switchChain } = useSwitchChain();

  const currentChainId = useChainId();

  return (
    <NetworkSelector
      currentChain={{ id: currentChainId }}
      chains={[...chains]}
      onClick={chainId => switchChain({ chainId })}
      showStatus={!hideStatus}
      className={className}
    />
  );
};

type MinimalChain = Pick<Chain, 'id'>;

export const NetworkSelector = ({
  currentChain,
  chains,
  onClick,
  showLabel = false,
  showStatus = false,
  className,
}: {
  currentChain: MinimalChain;
  chains: Array<MinimalChain>;
  onClick: (chainId: number) => void;
  showLabel?: boolean;
  showStatus?: boolean;
  className?: string;
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  // Ensure current chain is first in the list
  const displayChains = [
    <ChainItem chainId={currentChain.id} key={currentChain.id} isCurrent showStatus={showStatus} onClick={onClick} />,
    ...chains
      .filter(chain => chain.id !== currentChain.id)
      .map(chain => <ChainItem chainId={chain.id} key={chain.id} showStatus={showStatus} onClick={onClick} />),
  ];

  let chainName: string;
  try {
    chainName = getChainNameForChainId(currentChain.id);
  } catch {
    chainName = 'Unknown';
  }

  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      closeOnSelect
      closeOnBlur
      isLazy
      matchWidth={showStatus}
      lazyBehavior='unmount'
      gutter={-HEIGHT}>
      <MenuButton
        as={Button}
        height={`${HEIGHT}px`}
        onClick={e => {
          e.stopPropagation();
          onToggle();
        }}
        className={clsx(
          className,
          styles['chain-button'],
          styles[`chain-button--${currentChain.id}`],
          styles[`chain-button--light`],
          !showStatus && styles[`chain-button--no-status`],
          showLabel && styles[`chain-button--has-label`],
        )}>
        {!!showStatus && <StatusIcon visible light />}
        <ChainIcon chainId={currentChain.id} />
        {!!showLabel && <span className={styles['chain-button-label']}>{chainName}</span>}
        <DownCaretIcon className={styles['chain-button-caret']} />
      </MenuButton>
      <MenuList onClick={e => e.stopPropagation()} className={styles['chain-list']}>
        {displayChains}
      </MenuList>
    </Menu>
  );
};

const ChainItem = ({
  chainId,
  onClick,
  isCurrent = false,
  showStatus = false,
}: {
  chainId: number;
  onClick: (chainId: number) => void;
  isCurrent?: boolean;
  showStatus?: boolean;
}) => {
  return (
    <MenuItem className={clsx(styles['chain-item'], styles[`chain-item--${chainId}`])} onClick={() => onClick(chainId)}>
      {!!showStatus && <StatusIcon visible={isCurrent} />}
      <ChainIcon chainId={chainId} />
      {getChainNameForChainId(chainId)}
    </MenuItem>
  );
};

const StatusIcon = ({ visible, light = false }: { visible: boolean; light?: boolean }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='8'
    height='8'
    viewBox='0 0 8 8'
    fill='none'
    className={styles['status']}>
    {!!visible && <circle cx='4' cy='4' r='4' fill='#4DDBA8' stroke={light ? '#20B57F' : undefined} />}
  </svg>
);
