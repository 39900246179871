import { Text } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { Ref } from 'react';
import { forwardRef, useReducer, useState } from 'react';
import { P, match } from 'ts-pattern';
import { parseUnits } from 'viem';

import {
  CreateStockDonationPledge,
  CreateStockRecommendation,
  GetFundActivity,
  GetOrg,
  GetOrgActivity,
  GetRecommendationsForFund,
  GetRecommendationsMadeByMe,
  GetStockTickerPrice,
  GetUserActivity,
} from '@endaoment-frontend/api';
import { featureFlags } from '@endaoment-frontend/config';
import { STABLECOIN_DECIMALS } from '@endaoment-frontend/constants';
import { getFetchErrorMessage, isFetchError } from '@endaoment-frontend/data-fetching';
import { useIdempotencyKey } from '@endaoment-frontend/hooks';
import { TargetAllocationRebalanceCheckbox } from '@endaoment-frontend/target-allocations';
import type { BrokerageInfoFormData, DonationRecipient, StockLot, StockTicker, UUID } from '@endaoment-frontend/types';
import { Button, Loader, Pill, StepModal } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel, useShowFireworks } from '@endaoment-frontend/ui/smart';
import { formatCurrency, formatDate } from '@endaoment-frontend/utils';

import wizardStyles from '../DonationWizard.module.scss';
import type { SubformProps } from '../DonationWizard.types';
import { RequestLiquidationCheckbox } from '../common/RequestLiquidationCheckbox';

import type { TgbTaxInfoFormData } from './BrokerageDonationTaxInfo';
import { BrokerageDonationTaxInfo } from './BrokerageDonationTaxInfo';
import { BrokerageIcon, BrokerageInfo } from './BrokerageInfo';
import stockStyles from './StockDonation.module.scss';
import { StockDonationDetails } from './StockDonationDetails';
import { StockDonationSignature } from './StockDonationSignature';
import { ViewBrokerageDonationWithPrice, calculateStockFee } from './ViewBrokerageDonation';

const brokerageDonationSteps = ['amount', 'tax-receipt', 'brokerage', 'signature', 'confirmation', 'view'] as const;
const brokerageDonationPages = brokerageDonationSteps.length + 1;
type BrokerageDonationStep = (typeof brokerageDonationSteps)[number];

type BrokerageFlowState = {
  recipient: DonationRecipient;
  ticker: StockTicker;
  shares: number;
  lots: Array<StockLot>;
  taxInfo: TgbTaxInfoFormData;
  brokerageInfo: BrokerageInfoFormData;
  signature: string;
  isRebalanceRequested: boolean;
  recommendationId?: UUID;
  requestScheduledLiquidation: boolean;
};

const useBrokerageDonationFlowState = ({
  rawState,
  rawStateSetters,
}: Pick<SubformProps, 'rawState' | 'rawStateSetters'>) => {
  const [step, setStep] = useReducer((_prev: BrokerageDonationStep, next: BrokerageDonationStep) => {
    trackEvent({ event: 'dw_wizard_progress', data: { dw_wizard_step: next, dw_wizard_mode: rawState.mode } });
    return next;
  }, 'amount');
  const [taxInfo, setTaxInfo] = useState<TgbTaxInfoFormData>();
  const [signature, setSignature] = useState<string>();

  const wizard = {
    step,
    recipient: rawState.recipient,
    ticker: rawState.ticker,
    shares: rawState.shares,
    lots: rawState.lots,
    taxInfo,
    brokerageInfo: rawState.brokerageInfo,
    signature,
    isRebalanceRequested: rawState.isRebalanceRequested,
    recommendationId: rawState.recommendationId,
    requestScheduledLiquidation: rawState.requestScheduledLiquidation,
  } satisfies Partial<BrokerageFlowState> & { step: BrokerageDonationStep };

  return {
    wizard,
    setStep,
    setTicker: rawStateSetters.setBrokerageTicker,
    setShares: rawStateSetters.setBrokerageShares,
    setLots: rawStateSetters.setBrokerageLots,
    setTaxInfo,
    setBrokerageInfo: rawStateSetters.setBrokerageInfo,
    setSignature,
    setIsRebalanceRequested: rawStateSetters.setIsRebalanceRequested,
    setRequestScheduledLiquidation: rawStateSetters.setRequestScheduledLiquidation,
  } as const;
};

const BrokerageDonationFlowWithRef = (
  { onClose, onReset, rawState, rawStateSetters }: SubformProps,
  ref?: Ref<HTMLDivElement>,
) => {
  const queryClient = useQueryClient();
  // Key used to prevent duplicate pledges, generate once per flow
  const idempotencyKey = useIdempotencyKey();
  const showFireworks = useShowFireworks();

  const {
    mutateAsync: handleConfirm,
    data: pledgeId,
    status: confirmStatus,
    error: confirmError,
  } = useMutation({
    mutationKey: [CreateStockDonationPledge.key, idempotencyKey],
    mutationFn: async ({
      recipient,
      ticker,
      shares,
      lots,
      taxInfo,
      brokerageInfo,
      signature,
      isRebalanceRequested,
      recommendationId,
      requestScheduledLiquidation,
    }: BrokerageFlowState) => {
      const { firstName, lastName, email, phone, address, shareMyEmail, updateProfile } = taxInfo;

      return CreateStockDonationPledge.execute({
        idempotencyKey,
        recipient,
        ticker: ticker.ticker,
        shares,
        lots,
        signature,
        brokerData: {
          brokerName: brokerageInfo.brokerage.name,
          customBrokerName: brokerageInfo.brokerage.name === 'other' ? brokerageInfo.brokerage.label : undefined,
          brokerageAccountNumber: brokerageInfo.brokerageAccountNumber,
          brokerContactName: brokerageInfo.brokerageContactName,
          brokerEmail: brokerageInfo.brokerageEmail,
          brokerPhone: brokerageInfo.brokeragePhone,
        },
        donorData: {
          addressLine1: address.line1,
          addressLine2: address.line2 ?? undefined,
          city: address.city,
          state: address.state && address.state !== '' ? address.state : 'N/A',
          zipcode: address.zip && address.zip !== '' ? address.zip : 'N/A',
          country: address.country,
          firstName: firstName,
          lastName: lastName,
          receiptEmail: email,
          phoneNumber: phone,
        },
        shareMyEmail: shareMyEmail,
        updateIdentity: updateProfile,
        isRebalanceRequested,
        recommendationId,
        requestScheduledLiquidation,
      });
    },
    retry: (n, e) => {
      // Retry logic needs to be special due to this call needing the 409 code to trigger a retry
      if (
        isFetchError(e) &&
        e.statusCode &&
        // We do not want to retry on 4xx errors
        e.statusCode >= 400 &&
        e.statusCode < 500 &&
        // The exception is 408, which is a timeout
        e.statusCode !== 408 &&
        // Additional case of 409, which is only for this call
        e.statusCode !== 409
      )
        return false;
      // Max 20 retries
      return n < 20;
    },
    // Retry every 3 seconds
    retryDelay: 3000,
    onSuccess: async (_data, { shares, ticker, recipient }) => {
      GetUserActivity.invalidateQuery(queryClient, []);
      flowState.setStep('view');
      showFireworks();

      const currentTickerPrice = await GetStockTickerPrice.fetchFromDefaultClient([ticker.ticker]);
      const donationValue = currentTickerPrice ? currentTickerPrice * shares : 0;

      // Tracking for GTM events within onSuccess to ensure pledgeId is available
      // Clear dataLayer before sending ecommerce values
      trackEvent({ data: { ecommerce: null } });
      trackEvent({
        event: 'dw_donation_pledge',
        data: {
          destination_id: recipient.type === 'fund' ? recipient.id : recipient.einOrId,
          amount: shares,
          stock_ticker: ticker.ticker,
          pledge_type: 'stock',
          destination_type: recipient.type,
          ecommerce: {
            currency: 'USD',
            value: donationValue,
            transaction_id: pledgeId,
          },
        },
      });

      if (recipient.type === 'org') {
        const org = await GetOrg.fetchFromDefaultClient([recipient.einOrId]);
        GetOrgActivity.invalidateQuery(queryClient, [org.id]);
      }

      if (recipient.type === 'fund') {
        GetFundActivity.invalidateQuery(queryClient, [recipient.id]);
      }
    },
  });
  const { mutate: createRecommendation, status: recommendStatus } = useMutation({
    mutationKey: CreateStockRecommendation.prefixKeys,
    mutationFn: ({
      recipient,
      ticker,
      shares,
      brokerageInfo,
      isRebalanceRequested,
    }: Omit<BrokerageFlowState, 'requestScheduledLiquidation' | 'signature' | 'taxInfo'>) => {
      if (recipient.type !== 'fund') throw new Error('Recipient must be a fund to create a recommendation');
      if (!brokerageInfo.brokerage) throw new Error('Brokerage must be selected');
      return CreateStockRecommendation.execute({
        recipient,
        shares,
        ticker: ticker.ticker,
        isRebalanceRequested,
        brokerData: {
          brokerName: brokerageInfo.brokerage.name,
          customBrokerName: brokerageInfo.brokerage.name === 'other' ? brokerageInfo.brokerage.label : undefined,
          brokerageAccountNumber: brokerageInfo.brokerageAccountNumber,
          brokerContactName: brokerageInfo.brokerageContactName,
          brokerEmail: brokerageInfo.brokerageEmail,
          brokerPhone: brokerageInfo.brokeragePhone,
        },
        uuid: idempotencyKey,
      });
    },
    onSuccess: (_data, input) => {
      if (!input.ticker || !input.recipient || input.recipient.type !== 'fund')
        throw new Error('Impossibly created a recommendation with incorrect values.');

      GetRecommendationsForFund.invalidateQuery(queryClient, [input.recipient.id]);
      GetRecommendationsMadeByMe.invalidateQuery(queryClient, []);

      trackEvent({
        event: 'dw_donation_pledge_recommendation',
        data: {
          destination_id: input.recipient.id,
          amount: input.shares,
          stock_ticker: input.ticker.ticker,
          pledge_type: 'stock',
          destination_type: input.recipient.type,
        },
      });

      setTimeout(() => onClose(), 2 * 1000);
    },
  });

  const flowState = useBrokerageDonationFlowState({
    rawState,
    rawStateSetters,
  });

  const steps = match(flowState.wizard)
    .with({ step: 'amount' }, ({ ticker, shares, lots, recipient }) => {
      return (
        <StepModal.Step
          key='amount'
          ref={ref}
          onClose={onClose}
          onBack={onReset}
          header='Stock Donation'
          progress={{ current: 3, pages: brokerageDonationPages }}>
          <EntityCardWithLabel label='Donating to' entity={recipient} onRemove={onReset} />
          <hr />
          <h2 className={wizardStyles['step-header']}>What stock will you be donating?</h2>
          <StockDonationDetails
            ticker={ticker}
            shares={shares}
            lots={lots}
            onSubmit={({ shares, ticker, lots }) => {
              flowState.setShares(shares);
              flowState.setTicker(ticker);
              flowState.setLots(lots);
              flowState.setStep('brokerage');
            }}
          />
        </StepModal.Step>
      );
    })
    .with(
      {
        step: 'brokerage',
        ticker: P.nonNullable,
        shares: P.number,
        lots: P.nonNullable,
        recipient: P.nonNullable,
      },
      ({ brokerageInfo, recipient, shares, ticker, lots, isRebalanceRequested }) => (
        <StepModal.Step
          key='brokerage'
          ref={ref}
          onClose={onClose}
          onBack={() => flowState.setStep('amount')}
          header='Stock Donation / Brokerage'
          progress={{ current: 4, pages: brokerageDonationPages }}>
          <h2 className={wizardStyles['step-header']}>Which brokerage will you be using?</h2>
          <BrokerageInfo
            recipient={recipient}
            initialValues={brokerageInfo}
            onSubmit={bi => {
              flowState.setBrokerageInfo(bi);
              flowState.setStep('tax-receipt');
            }}
            onRecommend={bi => {
              if (!recipient) return;
              flowState.setBrokerageInfo(bi);

              createRecommendation({
                brokerageInfo: bi,
                recipient,
                shares,
                ticker,
                lots,
                isRebalanceRequested,
              });
            }}
            recommendStatus={recommendStatus}
          />
        </StepModal.Step>
      ),
    )
    .with(
      {
        step: 'tax-receipt',
        brokerageInfo: P.nonNullable,
        ticker: P.nonNullable,
        shares: P.number,
        lots: P.nonNullable,
        recipient: P.nonNullable,
      },
      ({ taxInfo, recipient }) => (
        <StepModal.Step
          key='tax-receipt'
          ref={ref}
          onClose={onClose}
          onBack={() => flowState.setStep('brokerage')}
          header='Stock Donation / Tax Receipt'
          progress={{ current: 5, pages: brokerageDonationPages }}>
          <h2 className={wizardStyles['step-header']}>Who owns this brokerage account?</h2>
          <BrokerageDonationTaxInfo
            initialValues={taxInfo}
            donationDestinationType={recipient.type}
            onSubmit={t => {
              flowState.setTaxInfo(t);
              flowState.setStep('signature');
            }}
          />
        </StepModal.Step>
      ),
    )
    .with(
      {
        step: 'signature',
        taxInfo: P.nonNullable,
        brokerageInfo: P.nonNullable,
        ticker: P.nonNullable,
        shares: P.number,
        lots: P.nonNullable,
      },
      ({ signature }) => (
        <StepModal.Step
          key='signature'
          ref={ref}
          onClose={onClose}
          onBack={() => flowState.setStep('tax-receipt')}
          header='Stock Donation / Signature'
          progress={{ current: 6, pages: brokerageDonationPages }}>
          <StockDonationSignature
            initialSignature={signature}
            onSubmit={sig => {
              flowState.setSignature(sig);
              flowState.setStep('confirmation');
            }}
          />
        </StepModal.Step>
      ),
    )
    .with(
      {
        step: 'confirmation',
        taxInfo: P.nonNullable,
        brokerageInfo: P.nonNullable,
        ticker: P.nonNullable,
        shares: P.number,
        lots: P.nonNullable,
        signature: P.nonNullable,
        recipient: P.nonNullable,
      },
      ({
        recipient,
        brokerageInfo,
        ticker,
        shares,
        lots,
        isRebalanceRequested,
        taxInfo,
        signature,
        recommendationId,
        requestScheduledLiquidation,
      }) => (
        <StepModal.Step
          key='confirmation'
          ref={ref}
          onClose={onClose}
          header='Stock Donation'
          onBack={() => flowState.setStep('signature')}
          progress={{ current: 7, pages: brokerageDonationPages }}>
          <EntityCardWithLabel label='Donating to' entity={recipient} onRemove={onReset} />
          <BrokerageDonationConfirmation
            brokerageInfo={brokerageInfo}
            ticker={ticker}
            shares={shares}
            lots={lots}
            onConfirm={() =>
              handleConfirm({
                recipient,
                shares,
                ticker,
                lots,
                signature,
                brokerageInfo,
                taxInfo,
                isRebalanceRequested,
                recommendationId,
                requestScheduledLiquidation,
              })
            }
            confirmStatus={confirmStatus}
            confirmError={confirmError}
            isRebalanceRequested={isRebalanceRequested}
            onChangeRebalanceRequested={flowState.setIsRebalanceRequested}
            requestScheduledLiquidation={requestScheduledLiquidation}
            onChangeRequestScheduledLiquidation={flowState.setRequestScheduledLiquidation}
            recipient={recipient}
          />
        </StepModal.Step>
      ),
    )
    .with(
      {
        step: 'view',
        taxInfo: P.nonNullable,
        brokerageInfo: P.nonNullable,
        ticker: P.nonNullable,
        shares: P.number,
        lots: P.nonNullable,
        signature: P.nonNullable,
        recipient: P.nonNullable,
      },
      ({ brokerageInfo, ticker, shares, lots, recipient }) => (
        <StepModal.Step key='view' ref={ref} onClose={onClose} header='Stock Donation'>
          <ViewBrokerageDonationWithPrice
            pledgeId={pledgeId}
            recipient={recipient}
            brokerageInfo={brokerageInfo}
            ticker={ticker}
            shares={shares}
            lots={lots}
            status={confirmStatus}
            onClose={() => onClose(pledgeId)}
          />
        </StepModal.Step>
      ),
    )
    .otherwise(wizard => {
      console.error('Unhandled donation wizard state', wizard);
      return (
        <StepModal.Step key='error' ref={ref} onClose={onClose} header='Error'>
          <Text alignSelf='center'>Something went wrong</Text>
        </StepModal.Step>
      );
    });

  return steps;
};
export const BrokerageDonationFlow = forwardRef(BrokerageDonationFlowWithRef);

const BrokerageDonationConfirmation = ({
  brokerageInfo,
  shares,
  ticker,
  lots,
  onConfirm,
  confirmStatus,
  confirmError,
  isRebalanceRequested,
  onChangeRebalanceRequested,
  requestScheduledLiquidation,
  onChangeRequestScheduledLiquidation,
  recipient,
}: {
  brokerageInfo: BrokerageInfoFormData;
  shares: number;
  ticker: StockTicker;
  lots: Array<StockLot>;
  onConfirm: () => void;
  confirmStatus?: MutationStatus;
  confirmError?: unknown;
  isRebalanceRequested: boolean;
  onChangeRebalanceRequested: (newVal: boolean) => void;
  requestScheduledLiquidation: boolean;
  onChangeRequestScheduledLiquidation: (newVal: boolean) => void;
  recipient: DonationRecipient;
}) => {
  const { data: currentTickerPrice } = GetStockTickerPrice.useQuery([ticker?.ticker], {
    enabled: !!ticker,
  });
  const donationValue = currentTickerPrice ? currentTickerPrice * shares : 0;

  return (
    <>
      <div className={wizardStyles['donation-info']}>
        <div>
          <h4>Brokerage</h4>
          <h4 className={stockStyles['broker-info']}>
            <BrokerageIcon brokerageName={brokerageInfo.brokerage.name} />
            {brokerageInfo.brokerage.label}
          </h4>
        </div>
        <div>
          <h4>Ticker</h4>
          <h5>
            {ticker.name} ({ticker.ticker})
          </h5>
        </div>
        <div>
          <h4>Shares</h4>
          <h5>{shares}</h5>
        </div>
        {lots.length > 0 && (
          <div>
            <h4>Lots</h4>
            <ul>
              {lots.map((lot, i) => (
                <li key={i}>
                  Lot #{i + 1}: {lot.numberOfShares} shares at {formatCurrency(lot.purchasePrice)} on{' '}
                  {formatDate(lot.purchaseDate, { dateStyle: 'medium', dateInUtc: true })}
                </li>
              ))}
            </ul>
          </div>
        )}
        <hr />
        <div>
          <h4>Estimated Value</h4>
          <h5>{donationValue > 0 ? formatCurrency(donationValue) : ''}</h5>
        </div>
        <div>
          <h6>Estimated Fee</h6>
          <h6>{donationValue > 0 ? formatCurrency(calculateStockFee(donationValue)) : 'Unable to Compute'}</h6>
        </div>
        {!!featureFlags.scheduledLiquidation && (
          <RequestLiquidationCheckbox
            requestScheduledLiquidation={requestScheduledLiquidation}
            onChangeRequestScheduledLiquidation={onChangeRequestScheduledLiquidation}
          />
        )}
        <TargetAllocationRebalanceCheckbox
          isRebalanceRequested={isRebalanceRequested}
          onChange={onChangeRebalanceRequested}
          recipient={recipient}
          additionalBalance={parseUnits(`${donationValue}`, STABLECOIN_DECIMALS)}
        />
        <hr />
        {match(confirmStatus)
          .with('error', () => (
            <Pill variation='red' className={wizardStyles.disclaimer}>
              {getFetchErrorMessage(confirmError) ?? 'Something went wrong. Please try again.'}
            </Pill>
          ))
          .otherwise(() => (
            <Pill variation='blue' className={wizardStyles.disclaimer}>
              Once initiated we will begin the process to transfer the assets from your brokerage to the destination.
              You do not have to take any action on your end.
            </Pill>
          ))}
      </div>
      <div className={wizardStyles['send-transaction']}>
        {match(confirmStatus)
          .with('pending', () => <Loader size='s' />)
          .with('idle', () => (
            <Button variation='purple' type='submit' filled onClick={onConfirm} size='medium' float={false}>
              Donate
            </Button>
          ))
          .with('error', () => (
            <Button variation='purple' type='submit' filled onClick={onConfirm} size='medium' float={false}>
              Try Again
            </Button>
          ))
          .otherwise(() => (
            <></>
          ))}
      </div>
    </>
  );
};
