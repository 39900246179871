import { Text } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import { skipToken, useMutation, useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { useReducer } from 'react';
import { useMount } from 'react-use';
import { P, match } from 'ts-pattern';

import {
  CreateDafMigrationPledge,
  GetFund,
  GetUserActivity,
  GetUserFunds,
  GetUserIdentity,
  WhoAmI,
} from '@endaoment-frontend/api';
import { useDeployAndCreateFund } from '@endaoment-frontend/blockchain-interactions';
import { useIdempotencyKey } from '@endaoment-frontend/hooks';
import { Loader, StepModal } from '@endaoment-frontend/ui/shared';

import { CreateFundAdvisor } from '../common/CreateFundAdvisor';
import { CreateFundDetails } from '../common/CreateFundDetails';
import { useFundWizardSetters, type FundMigrateState } from '../useFundWizardState';

import { MigrateFundAmount } from './MigrateFundAmount';
import { MigrateFundDestination } from './MigrateFundDestination';
import { MigrateFundInstructions } from './MigrateFundInstructions';
import { MigrateFundView } from './MigrateFundView';

type FundMigrateStep = 'amount' | 'create-advisor' | 'create-details' | 'destination' | 'instructions' | 'view';

const useMigrateFundFlowState = (state: FundMigrateState) => {
  const [step, setStep] = useReducer((_prev: FundMigrateStep, next: FundMigrateStep) => {
    trackEvent({ event: 'mw_wizard_progress', data: { mw_wizard_step: next } });
    return next;
  }, 'destination');

  const wizardSetters = useFundWizardSetters();

  const { data: selectedFund } = GetFund.useQuery(state.destinationFundId ? [state.destinationFundId] : skipToken, {
    enabled: !!state.destinationFundId,
  });
  const { data: funds } = GetUserFunds.useQuery([]);

  return {
    wizard: {
      step,
      funds,
      selectedFund,
      fundDetails: state.fundDetails,
      destinationFundId: state.destinationFundId,
      amount: state.amount,
    } as const,
    setStep,
    ...wizardSetters,
  } as const;
};

export const MigrateFundFlow = ({ onClose, state }: { onClose: () => void; state: FundMigrateState }) => {
  const queryClient = useQueryClient();

  const flowState = useMigrateFundFlowState(state);

  // Key used to prevent duplicate pledges, generate once per flow
  const idempotencyKey = useIdempotencyKey();

  // If an initial destination fund is provided, skip the destination step
  useMount(() => {
    if (state.destinationFundId) {
      flowState.setStep('amount');
    }
  });

  const {
    createFund,
    createdFundId,
    status: creationStatus,
    reset: resetFundCreate,
  } = useDeployAndCreateFund({
    onSuccess: () => {
      GetUserFunds.invalidateQuery(queryClient, []);
      WhoAmI.invalidateQuery(queryClient);
      GetUserIdentity.executeAndSave([]);
      flowState.setNewFundName(undefined);
      flowState.setNewFundChainId(undefined);
      flowState.setReferralSource(undefined);
      flowState.setDestinationFundId(createdFundId);
      flowState.setStep('amount');
    },
  });

  const {
    mutate: handleMigrate,
    status: migrationStatus,
    data: migrationPledgeId,
  } = useMutation({
    mutationKey: CreateDafMigrationPledge.prefixKeys,
    mutationFn: async () => {
      const { amount, selectedFund, destinationFundId } = flowState.wizard;

      if (!selectedFund || !destinationFundId || !amount) {
        console.error('Invalid state - missing `destinationFund` or `amount`');
        return;
      }

      return CreateDafMigrationPledge.execute({
        idempotencyKey,
        amount,
        receivingFundId: destinationFundId,
      });
    },
    onSuccess: () => {
      GetUserActivity.invalidateQuery(queryClient, []);
      flowState.setStep('view');

      // Clear dataLayer before sending ecommerce values, moved within onSuccess to ensure pledgeId is available
      trackEvent({ data: { ecommerce: null } });
      trackEvent({
        event: 'mw_migrate',
        data: {
          destination_id: flowState.wizard.destinationFundId,
          ecommerce: {
            currency: 'USD',
            value: flowState.wizard.amount,
            transaction_id: migrationPledgeId,
          },
        },
      });
    },
    retryDelay: 5000,
  });

  const handleReset = () => {
    flowState.setDestinationFundId(undefined);
    flowState.setNewFundName(undefined);
    flowState.setNewFundChainId(undefined);
    flowState.setReferralSource(undefined);
    resetFundCreate();
    flowState.setStep('destination');
  };

  const stepsIncludeCreate = flowState.wizard.step === 'create-details' || !!createdFundId;
  const stepTotal = stepsIncludeCreate ? 5 : 3;

  const steps = match({
    ...flowState.wizard,
    migrationPledgeId,
  })
    .with({ step: 'destination' }, () => (
      <StepModal.Step key='destination' header='DAF Migration' onClose={onClose} progress={{ current: 1, pages: 3 }}>
        <MigrateFundDestination
          funds={flowState.wizard.funds}
          onCreate={() => {
            flowState.setStep('create-details');
            trackEvent({ event: 'mw_start_migrate' });
          }}
          onSelect={fund => {
            flowState.setDestinationFundId(fund.id);
            flowState.setStep('amount');
          }}
        />
      </StepModal.Step>
    ))
    .with({ step: 'create-details' }, () => (
      <StepModal.Step
        key='create-details'
        header='New Fund Details'
        onBack={() => flowState.setStep('destination')}
        onClose={onClose}
        progress={{ current: 2, pages: 5 }}>
        <CreateFundDetails
          initialValues={flowState.wizard.fundDetails}
          onFundChainIdChange={chainId => flowState.setNewFundChainId(chainId)}
          onSubmit={details => {
            flowState.setNewFundName(details.name);
            flowState.setNewFundChainId(details.chainId);
            flowState.setReferralSource(details.referralSource);
            flowState.setStep('create-advisor');
          }}
          onFundNameChange={flowState.setNewFundName}
        />
      </StepModal.Step>
    ))
    .with({ step: 'create-advisor', fundDetails: P.nonNullable }, ({ fundDetails }) => (
      <StepModal.Step
        key='create-advisor'
        header='DAF Migration'
        onBack={() => flowState.setStep('create-details')}
        onClose={onClose}
        progress={{ current: 3, pages: 5 }}>
        <CreateFundAdvisor
          createButtonText='Create Fund & Proceed'
          isCreating={creationStatus === 'pending'}
          onSubmit={advisor => {
            createFund({ fundDetails, advisor });
          }}
        />
      </StepModal.Step>
    ))
    .with({ step: 'amount' }, ({ selectedFund }) => (
      <StepModal.Step
        key='amount'
        header='DAF Migration'
        onBack={() => flowState.setStep('destination')}
        onClose={onClose}
        progress={{ current: stepsIncludeCreate ? 4 : 2, pages: stepTotal }}>
        {!selectedFund ? (
          <Loader size='l' />
        ) : (
          <MigrateFundAmount
            initialValue={flowState.wizard.amount}
            fund={selectedFund}
            onSubmit={amount => {
              flowState.setMigrateAmount(amount);
              flowState.setStep('instructions');
            }}
            onRemove={handleReset}
          />
        )}
      </StepModal.Step>
    ))
    .with({ step: 'instructions', selectedFund: P.nonNullable, amount: P.nonNullable }, ({ selectedFund, amount }) => (
      <StepModal.Step
        key='instructions'
        header='Migration Instruction'
        onBack={migrationStatus === 'pending' ? undefined : () => flowState.setStep('amount')}
        onClose={onClose}
        progress={{ current: stepsIncludeCreate ? 5 : 3, pages: stepTotal }}>
        <MigrateFundInstructions
          fund={selectedFund}
          amount={amount}
          processing={migrationStatus === 'pending'}
          onInitiate={handleMigrate}
        />
      </StepModal.Step>
    ))
    .with(
      {
        step: 'view',
        selectedFund: P.nonNullable,
        amount: P.nonNullable,
        migrationPledgeId: P.nonNullable,
      },
      ({ selectedFund, amount, migrationPledgeId }) => (
        <StepModal.Step key='view' header='Migration Summary' onClose={onClose}>
          <MigrateFundView fund={selectedFund} amount={amount} fundMigrationId={migrationPledgeId} onClose={onClose} />
        </StepModal.Step>
      ),
    )
    .otherwise(wizard => {
      console.error('Unhandled fund wizard state', wizard);
      return (
        <StepModal.Step key='error' onClose={onClose} header='Error'>
          <Text alignSelf='center'>Something went wrong</Text>
        </StepModal.Step>
      );
    });

  return <AnimatePresence mode='wait'>{steps}</AnimatePresence>;
};
