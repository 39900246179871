import { getKernelAddressFromECDSA } from '@zerodev/ecdsa-validator';
import memoize from 'micro-memoize';
import { ENTRYPOINT_ADDRESS_V07 } from 'permissionless';
import { createPublicClient } from 'viem';

import { config } from '@endaoment-frontend/config';
import { createHttpTransport, getChainForChainId } from '@endaoment-frontend/multichain';
import type { Address } from '@endaoment-frontend/types';

export const getSmartAccountAddressForEOA = memoize((eoaAddress: Address): Promise<Address> => {
  const socialChain = getChainForChainId(config.socialLoginChainId);
  const tempClient = createPublicClient({
    chain: socialChain,
    transport: createHttpTransport(socialChain.rpcUrls.default.http[0]),
  });

  return getKernelAddressFromECDSA({
    eoaAddress: eoaAddress,
    entryPointAddress: ENTRYPOINT_ADDRESS_V07,
    // Always use the 0 index for the smart account, it refers to which potential smart account to use
    index: 0n,
    publicClient: tempClient,
  });
});
