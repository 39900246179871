import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import { type ReactNode } from 'react';
import { P, match } from 'ts-pattern';
import { useConfig } from 'wagmi';

import { GetFund, GetFundPositions } from '@endaoment-frontend/api';
import { ensureUserChain } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type { EntityPositionSummary, FundListing, TransactionStatus, UUID } from '@endaoment-frontend/types';
import { ErrorIcon, LoadingIcon, TargetAllocationIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader, Tooltip } from '@endaoment-frontend/ui/shared';

import styles from './TargetAllocationSection.module.scss';
import { CalculateEntityRebalance } from './requests';
import type { TargetAllocationRebalanceOperation } from './types';
import { useRebalanceFund } from './useRebalanceFund';

type MinimalPositionSummary = {
  positions: Array<
    Pick<
      EntityPositionSummary['positions'][number],
      'currentMarketValue' | 'inTransitBuyUsdcAmount' | 'inTransitSellUsdcAmount'
    >
  >;
};

/**
 * Determine the ability to rebalance a fund based on the current state of the fund and its positions.
 *
 * Possible states:
 * - balanced
 * - balancing transaction in progress
 * - rebalance available
 * - rebalance in progress
 * - rebalance failed
 * - empty fund
 * - fund has portfolio trades in transit
 */
export const computeRebalancability = (
  fund: Pick<FundListing, 'investedUsdc' | 'usdcBalance'> | undefined,
  positionSummary: MinimalPositionSummary | undefined,
  operations: Array<Pick<TargetAllocationRebalanceOperation, 'readyForExecution'>> | undefined,
  rebalanceTransactionStatus: TransactionStatus,
) => {
  const canRebalanceFromOps = !!operations && operations.length > 0 && operations.some(op => op.readyForExecution);
  const isEmptyFund = !fund || fund.usdcBalance === 0n;
  const isFundWithPositions =
    (!!fund && fund.investedUsdc > 0n) || (!!positionSummary && positionSummary.positions.length > 0);

  const isBalancing =
    !!operations && (rebalanceTransactionStatus === 'pending' || rebalanceTransactionStatus === 'waiting');
  const isRebalanceTransactionError =
    !!operations && (rebalanceTransactionStatus === 'error' || rebalanceTransactionStatus === 'rejected');
  const isRebalanceTransactionSuccessful = !!operations && rebalanceTransactionStatus === 'success';
  const isBalanced = !canRebalanceFromOps && !isBalancing && !isEmptyFund;
  const hasTradesInTransit =
    !!fund &&
    !!positionSummary &&
    !!positionSummary.positions.some(p => p.inTransitBuyUsdcAmount > 0 || p.inTransitSellUsdcAmount > 0);
  const isRebalanceable =
    canRebalanceFromOps && !isBalancing && !hasTradesInTransit && (!isEmptyFund || isFundWithPositions);

  return {
    isRebalanceable,
    isEmptyFund,
    isFundWithPositions,
    isBalancing,
    isRebalanceTransactionError,
    isRebalanceTransactionSuccessful,
    isBalanced,
    hasTradesInTransit,
  };
};

export const RebalanceButton = ({
  fundId,
  hasUnbalanceableDeviatedPositions = false,
  children,
}: {
  fundId: UUID;
  hasUnbalanceableDeviatedPositions?: boolean;
  children?: ReactNode;
}) => {
  const wagmiConfig = useConfig();
  const { execute, status } = useRebalanceFund();
  const { data: rebalanceOps } = CalculateEntityRebalance.useQuery(['fund', fundId, false]);
  const { data: fund } = GetFund.useQuery([fundId]);
  const { data: fundPositions } = GetFundPositions.useQuery([fundId, 'accurate']);

  const fundRebalancability = computeRebalancability(fund, fundPositions, rebalanceOps, status);

  if (!fund) {
    return (
      <div className={styles['empty-fund-warning']}>
        <Loader size='l' />
      </div>
    );
  }
  if (
    !fundRebalancability.isRebalanceable &&
    fundRebalancability.isEmptyFund &&
    !fundRebalancability.isFundWithPositions
  ) {
    return (
      <div className={styles['empty-fund-warning']}>
        <h6>This fund is empty!</h6>
        <p>Add assets to distribute to your target allocation.</p>
        <Button
          as={Link}
          href={routes.app.fund({
            id: fundId,
            wizardParams: { isDonationWizardOpen: true, dwRecipient: { id: fundId, type: 'fund' } },
            useFullUrl: false,
          })}
          shallow
          filled
          variation='fund'
          size='medium'
          className={styles['donate-button']}>
          Donate Assets to Fund
        </Button>
      </div>
    );
  }

  const handleClick = async () => {
    if (!fundRebalancability.isRebalanceable) return;
    await ensureUserChain(wagmiConfig, fund.chainId);
    execute({
      fundId,
    });
  };
  const tooltipText = match({
    ...fundRebalancability,
    hasUnbalanceableDeviatedPositions,
  })
    .returnType<string>()
    .with({ isBalancing: true }, () => `Rebalancing in progress`)
    .with({ isRebalanceTransactionSuccessful: true }, () => `Rebalance successful!`)
    .with(
      { hasTradesInTransit: true },
      () => `This fund has portfolio trades in transit, it cannot be rebalanced until the trades resolve.`,
    )
    .with(
      { hasUnbalanceableDeviatedPositions: true, isBalanced: true },
      () =>
        `The positions within this fund are not within the qualified target allocation range for a collective rebalance.`,
    )
    .with({ isBalanced: true }, () => `All positions are within target. No rebalance needed!`)
    .with({ isEmptyFund: true, isFundWithPositions: false }, () => `This fund is empty`)
    .otherwise(() => 'Execute portfolio trades to rebalance portfolio allocations to target.');
  const buttonContent = match({
    ...fundRebalancability,
    children,
    status,
    hasUnbalanceableDeviatedPositions,
  })
    .with({ children: P.not(P.nullish) }, () => children)
    .with({ isRebalanceTransactionError: true }, () => (
      <>
        <ErrorIcon color='white' />
        Something went wrong, try again!
      </>
    ))
    .with({ status: 'waiting', isBalancing: true }, () => (
      <>
        <LoadingIcon color='currentColor' spinning />
        Waiting for signature
      </>
    ))
    .with({ isBalancing: true }, () => (
      <>
        <LoadingIcon color='currentColor' spinning />
        Balancing...
      </>
    ))
    .with({ hasUnbalanceableDeviatedPositions: true, isBalanced: true }, () => (
      <>
        <TargetAllocationIcon color='currentColor' />
        No Rebalance Available
      </>
    ))
    .with(P.union({ isBalanced: true }, { isRebalanceTransactionSuccessful: true }), () => (
      <>
        <TargetAllocationIcon color='currentColor' />
        Balanced
      </>
    ))
    .otherwise(() => (
      <>
        <TargetAllocationIcon color='currentColor' />
        Balance Positions
      </>
    ));

  return (
    <Tooltip content={tooltipText} placement='top'>
      <Button
        onClick={handleClick}
        filled
        variation='allocation'
        className={clsx(
          styles['allocation-button'],
          fundRebalancability.isBalanced && styles['allocation-button--balanced'],
        )}
        float={fundRebalancability.isRebalanceable}
        disabled={!fundRebalancability.isRebalanceable}
        data-testid='rebalance-button'>
        {buttonContent}
      </Button>
    </Tooltip>
  );
};
