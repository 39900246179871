import { GridItem, Skeleton } from '@chakra-ui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { GetImpactFundDistributionRoundSummary } from '@endaoment-frontend/api';
import { TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';
import type { FundDistributionRoundSummary, UUID } from '@endaoment-frontend/types';
import { Pill, cardClassNames } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatUsdc, getImpactRoundStatus, getTimestampParts } from '@endaoment-frontend/utils';

import styles from './DistributionCard.module.scss';

type DistributionCardWithSummaryProps = {
  roundSummary?: FundDistributionRoundSummary;
  isExpanded?: boolean;
  className?: string;
};

const DistributionCardWithSummary = ({
  roundSummary,
  isExpanded = false,
  className,
}: DistributionCardWithSummaryProps) => {
  const roundStartTimestampUtc = roundSummary?.roundStartTimestampUtc;
  const roundEndTimestampUtc = roundSummary?.roundEndTimestampUtc;
  const now = Date.now();
  const usedTime =
    roundStartTimestampUtc && roundEndTimestampUtc
      ? roundStartTimestampUtc > now
        ? roundStartTimestampUtc
        : roundEndTimestampUtc
      : now;
  const [timeTo, setTimeTo] = useState(() => getTimestampParts(usedTime));

  useInterval(() => {
    setTimeTo(getTimestampParts(usedTime));
  }, TIME_ONE_MINUTE_IN_SECONDS * 1000);
  useEffect(() => {
    setTimeTo(getTimestampParts(usedTime));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundStartTimestampUtc, roundEndTimestampUtc]);

  const [isHovered, setIsHovered] = useState(false);

  const distributionText = (
    <p className={styles['distribution-text']}>
      <b>{formatCurrency(formatUsdc(roundSummary?.totalDistributionUsdc), { compact: true, fraction: 1 })}</b>
      <AnimatePresence>
        {!!(isExpanded || isHovered) && (
          <motion.span
            initial='initial'
            animate='hovered'
            exit='initial'
            layout
            variants={{
              hovered: {
                width: 'auto',
                opacity: 1,
              },
              initial: {
                width: 0,
                opacity: 0,
              },
            }}
            transition={{ ease: 'anticipate' }}>
            across&nbsp;
            <b>
              {(roundSummary?.recipientCount || 0).toLocaleString()}&nbsp;
              {roundSummary?.recipientCount === 1 ? 'Org' : 'Orgs'}
            </b>
          </motion.span>
        )}
      </AnimatePresence>
    </p>
  );

  return (
    <motion.div
      className={clsx(cardClassNames.base, styles['container'], className)}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}>
      <div className={styles['title']}>
        <Skeleton isLoaded={!!roundSummary}>
          <h4>Next Distribution</h4>
        </Skeleton>
        {roundSummary ? (
          <RoundStatus roundSummary={roundSummary} full={false} />
        ) : (
          <Skeleton h='1.25rem' w='3rem' borderRadius='1rem' />
        )}
      </div>
      <div className={clsx(styles['always-visible'], isExpanded && styles['always-visible__expanded'])}>
        <Skeleton isLoaded={!!roundSummary}>{distributionText}</Skeleton>
        <Skeleton isLoaded={!!roundSummary}>
          <p
            title={`${timeTo.days} days ${timeTo.hours} hrs ${timeTo.minutes} min`}
            className={clsx(styles['time-text'], styles['time-text--always-visible'])}>
            <b>{timeTo.days}</b>&nbsp;days&nbsp;
            <b>{timeTo.hours}</b>&nbsp;hrs&nbsp;
            <b>{timeTo.minutes}</b>&nbsp;min
          </p>
        </Skeleton>
      </div>
    </motion.div>
  );
};

export const RoundStatus = ({
  roundSummary,
  full = true,
  className,
}: {
  roundSummary?: Pick<
    FundDistributionRoundSummary,
    'distributedTimestampUtc' | 'roundEndTimestampUtc' | 'roundStartTimestampUtc'
  >;
  full?: boolean;
  className?: string;
}) => {
  if (!roundSummary) return <></>;

  const status = getImpactRoundStatus(roundSummary).toUpperCase();

  return (
    <GridItem area='status' className={clsx(styles['status'], className)}>
      {!!full && <p>Round Status</p>}
      <Pill className={clsx(styles['status-pill'], styles[`status-pill--${status.split(' ')[0].toLowerCase()}`])}>
        {status}
      </Pill>
    </GridItem>
  );
};

type DistributionCardProps = { fundId: UUID; isExpanded?: boolean; className?: string };

export const DistributionCard = ({ fundId, isExpanded, className }: DistributionCardProps) => {
  const { data: roundSummary } = GetImpactFundDistributionRoundSummary.useQuery([fundId, 'current']);

  return <DistributionCardWithSummary roundSummary={roundSummary} isExpanded={isExpanded} className={className} />;
};
