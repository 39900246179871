import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import { match } from 'ts-pattern';

import { GetDonationsCount, GetFundsCount, GetTotalDonations } from '@endaoment-frontend/api';
import { config } from '@endaoment-frontend/config';
import { useIsMobile } from '@endaoment-frontend/hooks';
import { routes } from '@endaoment-frontend/routes';
import type { UUID } from '@endaoment-frontend/types';
import { uuidSchema } from '@endaoment-frontend/types';
import { UniversalWordmarkIcon } from '@endaoment-frontend/ui/icons';
import { Card } from '@endaoment-frontend/ui/shared';
import { convertUsdcToNumber, formatCurrency, formatNumber } from '@endaoment-frontend/utils';

import { DistributionCard } from '../../components/DistributionCard';

import styles from './FooterStats.module.scss';

const heroFormatter = (value: number) => {
  return formatNumber(value, { compact: false, digits: 5, stripZeros: true });
};

export const FooterStats = () => {
  const { data: donationsCount = 0 } = GetDonationsCount.useQuery([]);
  const { data: fundsCount = 0 } = GetFundsCount.useQuery([]);

  const totalDonated = convertUsdcToNumber(GetTotalDonations.useQuery([]).data);

  return (
    <section className={styles['container']}>
      <h4>Community-Wide Statistics</h4>
      <div>
        <Card className={styles['stats']}>
          <div className={styles['stats-numbers']}>
            <h3>
              {formatCurrency(totalDonated, { compact: true, lowercase: true })}
              <sub>Total Impact</sub>
            </h3>
            <h3>
              {heroFormatter(donationsCount)}
              <sub>Donations</sub>
            </h3>
            <h3>
              1.8m
              <sub>Eligible Orgs</sub>
            </h3>
            <h3>
              {heroFormatter(fundsCount)}
              <sub>Open Funds</sub>
            </h3>
          </div>
        </Card>
        <FancyImpactPoolButton />
      </div>
    </section>
  );
};

// UIP ID per environment
const universalImpactPoolId = match(config.environmentName)
  .returnType<UUID>()
  .with('dev', () => uuidSchema.parse('0f113b5b-ac96-420f-b780-7ee1bc9d069d'))
  .with('staging', () => uuidSchema.parse('3c492c8d-9fcc-4565-9d97-dacf813b0294'))
  .otherwise(() => uuidSchema.parse('656ddb74-e8dd-4429-a7d6-f731f367e9e6'));

const FancyImpactPoolButton = () => {
  const { isMobile } = useIsMobile();

  if (!universalImpactPoolId) return <></>;

  return (
    <div className={clsx(styles['fancy-buttons'], styles['fancy-button--impact'])}>
      <Link href={routes.app.fund({ id: universalImpactPoolId })} className={styles['fancy-button']}>
        <div className={styles['fancy-button__title']}>
          <div>
            <h3>
              <UniversalWordmarkIcon />
              <div>Impact Pool</div>
            </h3>
            <p>Community impact, evolved</p>
          </div>
        </div>
        <DistributionCard
          fundId={universalImpactPoolId}
          isExpanded={!isMobile}
          className={styles['fancy-button__stats']}
        />
      </Link>
    </div>
  );
};
