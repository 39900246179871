import type { As } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import { match } from 'ts-pattern';

import { GetOrg } from '@endaoment-frontend/api';
import {
  type EIN,
  type EINDash,
  type Org,
  type OrgListing,
  type PolymorphicProps,
  type Subproject,
  type UUID,
} from '@endaoment-frontend/types';
import { OrgLogo } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatEin, formatPhysicalAddress, formatUsdc } from '@endaoment-frontend/utils';

import { MiniLoadingDetails, componentMatch } from './MiniEntityDetails';
import styles from './MiniEntityDetails.module.scss';

export type MiniOrgDetailsProps<Tag extends As> = PolymorphicProps<
  'div',
  Tag,
  {
    org: Pick<
      Org | OrgListing | (Subproject & { claimed?: undefined }),
      'address' | 'claimed' | 'ein' | 'id' | 'logo' | 'name'
    > & { lifetimeContributionsUsdc?: OrgListing['lifetimeContributionsUsdc'] };
    padding?: boolean;
    extraDetails?: boolean;
    hideTooltips?: boolean;
    numberConfig?: {
      mode: 'balance' | 'contributions' | 'distribution';
      /** Should be a Currency string */
      display?: string;
    };
  }
>;

export const MiniOrgDetails = <Tag extends As>({
  org,
  padding = true,
  className,
  extraDetails = false,
  hideTooltips = false,
  numberConfig,
  ...props
}: MiniOrgDetailsProps<Tag>) => {
  const { name, logo, address, lifetimeContributionsUsdc } = org;
  const fullNumberConfig =
    !numberConfig || numberConfig.mode === 'contributions'
      ? {
          mode: 'contributions' as const,
          display: formatCurrency(formatUsdc(lifetimeContributionsUsdc), { compact: true, lowercase: true }),
        }
      : {
          mode: numberConfig.mode,
          display: numberConfig.display,
        };

  return (
    <Box
      className={clsx(
        styles['entity-details'],
        styles['entity-details--org'],
        !padding && styles['entity-details--nopad'],
        className,
      )}
      as={componentMatch(props)}
      {...props}>
      <OrgLogo src={logo} orgName={name} />
      <h6>
        <div>{name}</div>
        <span>
          {!extraDetails && fullNumberConfig.mode !== 'contributions' ? (
            fullNumberConfig.display
          ) : (
            <>
              <span>{formatPhysicalAddress(address, true)}</span>
              <b> &bull; </b>
              <span>{org.ein ? formatEin(org.ein) : 'International'}</span>
            </>
          )}
        </span>
      </h6>
      {!!extraDetails && (
        <div className={styles['entity-details__extra']}>
          {lifetimeContributionsUsdc !== 0n && (
            <div className={clsx(styles['number-display'], styles[`number-display--${fullNumberConfig.mode}`])}>
              <b>{fullNumberConfig['display']}</b>
              {match(fullNumberConfig.mode)
                .with('contributions', () => 'Donated')
                .with('balance', () => 'Received')
                .with('distribution', () => 'Distribution')
                .exhaustive()}
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export const MiniOrgDetailsWithQuery = <Tag extends As>({
  einOrId,
  ...props
}: Omit<MiniOrgDetailsProps<Tag>, 'org'> & { einOrId: EIN | EINDash | UUID }) => {
  const { data: org } = GetOrg.useQuery([einOrId]);

  if (!org) return <MiniLoadingDetails />;

  return <MiniOrgDetails org={org} {...props} />;
};
