import { Box } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useMount } from 'react-use';
import { match, P } from 'ts-pattern';
import { isAddress } from 'viem';
import { useChainId } from 'wagmi';

import { WhoAmI } from '@endaoment-frontend/api';
import { useAccountDisplayName, useAuth, useAuthType } from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import { FUND_AGREEMENT_URL, TERMS_AND_CONDITIONS_URL } from '@endaoment-frontend/constants';
import { FormNetworkSelector } from '@endaoment-frontend/multichain';
import type { CreateFundDetailsInput } from '@endaoment-frontend/types';
import { createFundDetailsInputSchema } from '@endaoment-frontend/types';
import { FormInput, ProceedButton, ReferralFormInput, validateWithZod } from '@endaoment-frontend/ui/forms';
import { LogoIcon } from '@endaoment-frontend/ui/icons';
import { Card } from '@endaoment-frontend/ui/shared';

import styles from './CreateFund.module.scss';

export const CreateFundDetails = ({
  initialValues,
  onSubmit,
  onFundChainIdChange,
  onFundNameChange,
}: {
  initialValues: CreateFundDetailsInput;
  onSubmit: (details: CreateFundDetailsInput) => void;
  onFundChainIdChange: (chainId: number) => void;
  onFundNameChange: (name: string) => void;
}) => {
  const { isSocialAuth, isWalletAuth } = useAuthType();
  const { isSignedIn } = useAuth();
  const { accountName, shortAccountName } = useAccountDisplayName();
  const currentChainId = useChainId();
  const chainId = isSocialAuth ? config.socialLoginChainId : currentChainId;

  const authInfo = WhoAmI.useQuery([], { enabled: isSignedIn });

  useMount(() => {
    // Validate chainId
    if (initialValues.chainId === -1) onFundChainIdChange(chainId);
  });

  useMount(() => {
    // Create an initial fund name if it doesn't exist
    if (initialValues.name !== '') return;

    const userStr = match(accountName)
      .with(P.nullish, () => 'My')
      .when(isAddress, () => `${shortAccountName}'s`)
      .otherwise(() => `${accountName}'s`);
    onFundNameChange(`${userStr} Donor Advised Fund`);
  });

  const shouldShowReferralCode = !!initialValues.referralCode;
  const shouldShowReferralSource = !shouldShowReferralCode && !authInfo.data?.hasInformedReferralSource;

  return (
    <div>
      <h4 className={styles['step-header']}>What's the name of this new fund?</h4>
      <Formik
        validate={validateWithZod(createFundDetailsInputSchema)}
        validateOnBlur
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}>
        <Form className={styles['create-fund']}>
          {!!shouldShowReferralCode && (
            <Card className={styles['referral-code-notice']}>
              <h6>You have been referred to make a new DAF!</h6>
              <p>
                It's free to setup, and there's no minimum.
                <br />
                Enter the name of your new fund below to get started!
              </p>
              <LogoIcon className={styles['referral-code-notice-logo']} />
            </Card>
          )}
          <FormInput name='name' label='Fund Name' placeholder="New Fund's Name" />
          {!!shouldShowReferralSource && <ReferralFormInput referralFieldName='referralSource' />}
          {/* TODO: rather than hide this with styles, make implicit to form */}
          {!!isWalletAuth && (
            <div className={styles['chain-selector']}>
              <span>Select a network to use for this fund:</span>
              <FormNetworkSelector name='chainId' />
            </div>
          )}
          <ProceedButton type='submit' className={styles.proceed} />
          <Box className={styles['terms']}>
            By continuing you agree to our&nbsp;
            <a href={TERMS_AND_CONDITIONS_URL} target='_blank' rel='noopener noreferrer'>
              terms and conditions
            </a>
            &nbsp;and&nbsp;
            <a href={FUND_AGREEMENT_URL} target='_blank' rel='noopener noreferrer'>
              donor-advised fund agreement
            </a>
            .
          </Box>
        </Form>
      </Formik>
    </div>
  );
};
