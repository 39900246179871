import { skipToken, useQuery } from '@tanstack/react-query';
import type { Config, WaitForTransactionReceiptReturnType } from '@wagmi/core';
import { getBlock, waitForTransactionReceipt } from '@wagmi/core';
import { useConfig } from 'wagmi';

import type { Address } from '@endaoment-frontend/types';

const getTransactionWithTimestamp = async (
  wagmiConfig: Config,
  hash: Address,
  chainId: number,
  confirmations: number,
): Promise<{
  transactionReceipt: WaitForTransactionReceiptReturnType;
  timestamp: number;
}> => {
  const transactionReceipt = await waitForTransactionReceipt(wagmiConfig, {
    hash,
    chainId,
    confirmations,
  });

  const block = await getBlock(wagmiConfig, {
    blockHash: transactionReceipt.blockHash,
    includeTransactions: false,
  });
  const timestamp = Number(block.timestamp);

  return { transactionReceipt, timestamp } as const;
};

export const useTransactionWithTimestamp = (
  hash: Address | undefined,
  chainId: number | undefined,
  confirmations: number,
) => {
  const wagmiConfig = useConfig();
  const { data = { timestamp: undefined, transactionReceipt: undefined }, error } = useQuery({
    queryKey: ['GetTransactionWithTimestamp', wagmiConfig.getClient().uid, hash, chainId, confirmations],
    queryFn: hash && chainId ? () => getTransactionWithTimestamp(wagmiConfig, hash, chainId, confirmations) : skipToken,
    enabled: !!hash && !!chainId,
    retry: 3,
  });

  return { timestamp: data.timestamp, transactionReceipt: data.transactionReceipt, error } as const;
};
